import { HelpOutlineOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState } from "react";
import { trackEvent } from "src/pages/OnboardingScreen/amplitude";
import SliderExplanationScreen, {
  SliderExplanationStepProp,
} from "src/pages/SliderExplanationScreen";

type ButtonWithDialogExplanationProps = {
  title: string;
  steps: SliderExplanationStepProp[];
  hideIcon?: boolean;
  trackingEventIdentifier?: string;
};

export default function ButtonWithDialogExplanation({
  title,
  steps,
  hideIcon,
  trackingEventIdentifier,
}: ButtonWithDialogExplanationProps) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);

    if (trackingEventIdentifier) {
      trackEvent("Clicked on Button with Explanation Dialog", {
        buttonTitle: title,
        trackingEventIdentifier,
      });
    }
  };

  return (
    <>
      <Button
        startIcon={!hideIcon && <HelpOutlineOutlined fontSize="small" />}
        variant="text"
        color="primary"
        onClick={handleClick}
      >
        {title}
      </Button>
      {open && (
        <SliderExplanationScreen
          steps={steps}
          finishButtonProps={{ onFinish: () => setOpen(false) }}
        />
      )}
    </>
  );
}
