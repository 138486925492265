import { differenceInMonths, differenceInYears } from "date-fns";
import HousingSituation from "src/utils/HousingSituation";
import TransportationSituation from "src/utils/TransportationSituation";
import { BackgroundFormData } from "../BackgroundSurveyScreen/BackgroundSurveyView";

// Define Disqualifier type
export type Disqualifier = Extract<
  keyof BackgroundFormData,
  | "hasProficientEnglish"
  | "hasDriverLicense"
  | "driverLicenseReinstatementCost"
  | "numberDUIPast7Years"
  | "numberDUIRecordLifetime"
  | "hasSexOffense"
  | "isTakingMedication"
  | "hasPendingCases"
  | "hasMoreThan4FelonyConvictions7Years"
  | "hasMajorTrafficViolationOverPast3Years"
  | "transportation"
  | "postReleaseTransportation"
>;

export type TriagedApplicationData = {
  disqualifiers: Disqualifier[];
  qualifiers: (keyof BackgroundFormData)[];
  needsAttention: (keyof BackgroundFormData)[];
};

export default function triageApplicationData(
  background: BackgroundFormData
): TriagedApplicationData {
  const disqualifiers: Disqualifier[] = [];
  const qualifiers: (keyof BackgroundFormData)[] = [];
  const needsAttention: (keyof BackgroundFormData)[] = [];

  if (background.hasProficientEnglish) {
    qualifiers.push("hasProficientEnglish");
  } else {
    disqualifiers.push("hasProficientEnglish");
  }

  if (background.hasDriverLicense) {
    qualifiers.push("hasDriverLicense");
  } else if (background.hasEverHadDriverLicense) {
    needsAttention.push("hasDriverLicense");
  } else {
    disqualifiers.push("hasDriverLicense");
  }

  if (background.driverLicenseReinstatementCost !== undefined) {
    if (Number(background.driverLicenseReinstatementCost) === 0)
      qualifiers.push("driverLicenseReinstatementCost");
    else if (Number(background.driverLicenseReinstatementCost) <= 300)
      needsAttention.push("driverLicenseReinstatementCost");
    else disqualifiers.push("driverLicenseReinstatementCost");
  }

  if (background.driverLicenseInactiveReason) {
    needsAttention.push("driverLicenseInactiveReason");
  }

  if (background.hasToTakeMandatoryCourseToReinstateLicense) {
    needsAttention.push("hasToTakeMandatoryCourseToReinstateLicense");
  }

  if (background.isCurrentOnChildSupport) {
    qualifiers.push("isCurrentOnChildSupport");
  } else if (background.isCurrentOnChildSupport === false) {
    needsAttention.push("isCurrentOnChildSupport");
  }

  if (Number(background.numberDUIPast7Years) > 0) {
    disqualifiers.push("numberDUIPast7Years");
  } else {
    qualifiers.push("numberDUIPast7Years");
  }

  if (Number(background.numberDUIRecordLifetime) >= 2) {
    disqualifiers.push("numberDUIRecordLifetime");
  } else if (Number(background.numberDUIRecordLifetime) === 1) {
    needsAttention.push("numberDUIRecordLifetime");
  } else {
    qualifiers.push("numberDUIRecordLifetime");
  }

  if (background.numberPointsDriverRecord > 0) {
    needsAttention.push("numberPointsDriverRecord");
  } else {
    qualifiers.push("numberPointsDriverRecord");
  }

  if (background.hasSexOffense === true) {
    disqualifiers.push("hasSexOffense");
  } else {
    qualifiers.push("hasSexOffense");
  }

  if (background.canPassVisionTest === true) {
    qualifiers.push("canPassVisionTest");
  } else {
    needsAttention.push("canPassVisionTest");
  }

  if (background.hasHistoryChronicalIllness === true) {
    needsAttention.push("hasHistoryChronicalIllness");
  } else {
    qualifiers.push("hasHistoryChronicalIllness");
  }

  if (background.isTakingMedication) {
    disqualifiers.push("isTakingMedication");
  } else {
    qualifiers.push("isTakingMedication");
  }

  if (background.hasPendingCases) {
    disqualifiers.push("hasPendingCases");
  } else {
    qualifiers.push("hasPendingCases");
  }

  if (!background.isIncarcerated) {
    qualifiers.push("releaseDate");
  } else if (background.releaseDate) {
    const monthsToRelease = differenceInMonths(
      background.releaseDate,
      new Date()
    );
    if (monthsToRelease > 6) {
      needsAttention.push("releaseDate");
    } else {
      qualifiers.push("releaseDate");
    }
  } else if (background.nextParoleHearingDate) {
    const monthsToParoleHearing = differenceInMonths(
      background.nextParoleHearingDate,
      new Date()
    );
    if (monthsToParoleHearing > 6) {
      needsAttention.push("nextParoleHearingDate");
    } else {
      qualifiers.push("nextParoleHearingDate");
    }
  } else {
    needsAttention.push("releaseDate");
  }

  if (!background.hasViolentOffense) {
    qualifiers.push("hasViolentOffense");
  }

  if (background.isFirstTimeOffender) {
    qualifiers.push("isFirstTimeOffender");
  }

  if (background.hasMoreThan4FelonyConvictions7Years) {
    disqualifiers.push("hasMoreThan4FelonyConvictions7Years");
  }

  if (background.hasMajorTrafficViolationOverPast3Years === false) {
    qualifiers.push("hasMajorTrafficViolationOverPast3Years");
  } else if (background.hasMajorTrafficViolationOverPast3Years) {
    disqualifiers.push("hasMajorTrafficViolationOverPast3Years");
  }

  if (background.lastReleased) {
    const yearsSinceRelease = differenceInYears(
      new Date(),
      background.lastReleased
    );
    if (yearsSinceRelease > 3) {
      qualifiers.push("lastReleased");
    }
  }

  if (background.postReleaseHousing) {
    if (
      HousingSituation[background.postReleaseHousing] ===
        HousingSituation.OwnHouse ||
      HousingSituation[background.postReleaseHousing] ===
        HousingSituation.RentApt
    ) {
      qualifiers.push("postReleaseHousing");
    } else {
      needsAttention.push("postReleaseHousing");
    }
  }

  if (background.housing) {
    if (
      HousingSituation[background.housing] === HousingSituation.OwnHouse ||
      HousingSituation[background.housing] === HousingSituation.RentApt
    ) {
      qualifiers.push("housing");
    } else {
      needsAttention.push("housing");
    }
  }

  if (background.transportation) {
    if (
      TransportationSituation[background.transportation] ===
        TransportationSituation.OwnVehicle ||
      TransportationSituation[background.transportation] ===
        TransportationSituation.OwnVehicleNotReady
    ) {
      qualifiers.push("transportation");
    } else if (
      TransportationSituation[background.transportation] ===
      TransportationSituation.NoTransportation
    ) {
      disqualifiers.push("transportation");
    } else {
      needsAttention.push("transportation");
    }
  }

  if (background.postReleaseTransportation) {
    if (
      TransportationSituation[background.postReleaseTransportation] ===
        TransportationSituation.OwnVehicle ||
      TransportationSituation[background.postReleaseTransportation] ===
        TransportationSituation.OwnVehicleNotReady
    ) {
      qualifiers.push("postReleaseTransportation");
    } else if (
      TransportationSituation[background.postReleaseTransportation] ===
      TransportationSituation.NoTransportation
    ) {
      disqualifiers.push("postReleaseTransportation");
    } else {
      needsAttention.push("postReleaseTransportation");
    }
  }

  return { disqualifiers, qualifiers, needsAttention };
}
