import { Box, Button, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { t } from "i18next";
import { useState } from "react";
import { useForm } from "react-hook-form";

// Custom components
import SubmitButton from "src/components/Button/SubmitButton";
import ImageInput from "src/components/ImageInput";
import DateInput from "src/components/Input/DateInput";
import NumberInput from "src/components/Input/NumberInput";

// Assets
import { Header32 } from "src/components/Typography";
import { useCurrentUser } from "src/SessionBoundary";
import Selfie from "./Selfie.svg";

type Props = {
  handleSubmission: (data: AttendanceFormData) => void;
};

export type AttendanceFormData = {
  photo?: File; // optional
  date: Date;
  duration: number;
};

function RangeAttendanceForm({ handleSubmission }: Props) {
  const user = useCurrentUser();
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<AttendanceFormData>({
    defaultValues: {
      date: new Date(),
      photo: undefined,
    },
  });

  // For wizard-like steps
  const [step, setStep] = useState(0);

  // Track if the user explicitly skipped the photo
  const [skippedPhoto, setSkippedPhoto] = useState(false);

  // Keep an eye on form fields so we can display them
  const selectedDate = watch("date");
  const formattedDate = selectedDate ? format(selectedDate, "EEE	, MMM d") : "";

  const onNext = () => setStep((prev) => prev + 1);
  const onBack = () => setStep((prev) => prev - 1);

  // Control whether the submit button is disabled for Step 2
  const photo = watch("photo");
  const duration = watch("duration");
  // If user hasn't chosen a photo and hasn't skipped, disable
  const photoButtonDisabled = !photo && !skippedPhoto;

  const handleSkipPhoto = () => {
    setSkippedPhoto(true);
  };

  const onFormSubmit = (data: AttendanceFormData) => {
    // If user skipped the photo, ensure `photo` is undefined
    handleSubmission(data);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      {/* Top Header */}

      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={4}
        sx={{ textAlign: "center", mt: 2 }}
      >
        {/* Step 0: Pick the date */}
        {step === 0 && (
          <>
            <Header32>
              {t("{{firstName}}, when did you train?", {
                firstName: user.firstName,
              })}
            </Header32>
            <DateInput
              control={control}
              name="date"
              label={t("Date of Attendance")}
              disableFuture
            />
            <Button variant="contained" onClick={onNext} fullWidth>
              {t("Next")}
            </Button>
          </>
        )}

        {/* Step 1: Ask for duration, referencing the date */}
        {step === 1 && (
          <>
            <Header32>
              {t("Awesome! How many hours did you train on {{date}}?", {
                date: formattedDate,
              })}
            </Header32>
            <NumberInput
              control={control}
              name="duration"
              label={t("Duration (in hours)")}
              rules={{
                required: {
                  value: true,
                  message: "Field is required",
                },
                min: {
                  value: 0,
                  message: t("Duration must be at least 0"),
                },
                validate: (value) =>
                  value > 0 || t("Duration must be greater than 0"),
              }}
            />
            <Box display="flex" width="100%" justifyContent="space-between">
              <Button size="large" variant="outlined" onClick={onBack}>
                {t("Back")}
              </Button>

              <Button
                size="large"
                variant="contained"
                onClick={onNext}
                disabled={!duration || duration <= 0}
              >
                {t("Next")}
              </Button>
            </Box>
          </>
        )}

        {/* Step 2: Optional photo upload */}
        {step === 2 && (
          <>
            {skippedPhoto ? (
              <Header32>{t("No worries, just try it next time 🙏!")}</Header32>
            ) : photo ? (
              <Typography variant="h6">{t("Looking good 😎!")}</Typography>
            ) : (
              <Typography variant="h6">
                {t("We'd love to see your progress!")}
              </Typography>
            )}

            {/* Selfie Input */}
            {!skippedPhoto && (
              <ImageInput
                label={t("Selfie at the school")}
                name="photo"
                control={control}
                placeholderImage={Selfie}
                placeholderImageWidth={176}
              />
            )}

            {/* Skip button (if user didn’t take a pic) */}
            {!skippedPhoto && !photo && (
              <Button variant="text" onClick={handleSkipPhoto}>
                {t("I didn't take a picture today (😢)")}
              </Button>
            )}

            {/* Action buttons */}
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={onBack}>
                {t("Back")}
              </Button>

              {/* If user has no photo and hasn't skipped, disable primary */}
              <SubmitButton
                size="large"
                disabled={isSubmitting || photoButtonDisabled}
                loading={isSubmitting}
              >
                {t("Submit")}
              </SubmitButton>
            </Stack>
          </>
        )}
      </Stack>
    </form>
  );
}

export default RangeAttendanceForm;
