import { Box, Typography } from "@mui/material";
import { FirebaseError } from "firebase/app";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as Logo } from "src/assets/WhiteFullLogo.svg";
import { ScreenTitle } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import { useAuth } from "src/contexts/AuthContext";
import fetchCohort from "src/firebase/fetchCohort";
import fetchOrganization from "src/firebase/fetchOrganization";
import fetchPartnerByInvitationCode from "src/firebase/fetchPartnerByInvitationCode";
import { SIGNUP_INFORMATION_SEED } from "src/seed";
import { Organization } from "src/types";
import { Cohort } from "src/types/Cohort";
import { Partner } from "src/types/Partner";
import formatNumberToE164 from "src/utils/formatNumberToE164";
import { getUTCDateOnly } from "src/utils/getUTCDateOnly";
import useErrorHandler from "src/utils/useErrorHandler";
import { trackEvent } from "../OnboardingScreen/amplitude";
import SliderExplanationScreen, {
  SliderExplanationStepProp,
} from "../SliderExplanationScreen";
import CredentialSignupForm, {
  CredentialsStepData,
} from "./CredentialSignupForm";
import LocationSignupForm, { LocationStepData } from "./LocationSignupForm";
import OrganizationRegisterWelcomeSection from "./OrganizationRegisterWelcomeSection";
import PartnerRegisterWelcomeSection from "./PartnerRegisterWelcomeSection";
import ProfileSignupForm, { ProfileStepData } from "./ProfileSignupForm";
import RegisterLayout from "./RegisterLayout";

export type SignupFormData = CredentialsStepData &
  ProfileStepData &
  LocationStepData;

type FormStep = "credentials" | "profile" | "location";

function getSteps(user: { firstName: string }): SliderExplanationStepProp[] {
  return [
    {
      type: "image",
      imageUrl:
        "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/product/GabeConstance.png",
      title: t("Welcome {{firstName}}", { firstName: user.firstName }),
      body: (
        <Trans
          t={t}
          defaults="Emerge Career is a <bold>govt-funded job training program</bold> for people impacted by the justice system. "
          components={{ bold: <b /> }}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      ),
    },
    {
      type: "image",
      imageUrl:
        "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/product/ManTruckOrange.png",
      title: "What we do?",
      titleProps: { fontWeight: "bold" },
      body: (
        <Trans
          t={t}
          defaults="We help people looking for a <bold>second chance</bold> become CDL truck drivers. Best part is? Our program is <bold>100% free</bold."
          components={{ bold: <b /> }}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      ),
    },
    {
      type: "image",
      imageUrl:
        "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/product/ZoBlue.png",
      title: "Who we serve?",
      titleProps: { fontWeight: "bold" },
      body: (
        <Trans
          t={t}
          defaults="Currently, our program is for people who have experienced arrest, probation, parole, or incarceration. <newline/><newline/>Doesn’t sound like you? You can still apply and we'll let you know as soon as we launch more programs!"
          components={{ bold: <b />, newline: <br /> }}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      ),
    },
    {
      type: "image",
      imageUrl:
        "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/product/AlexanderYellow.png",
      title: "What's next?",
      titleProps: { fontWeight: "bold" },
      body: "We’ll ask a few questions to learn about your situation and see if our CDL program is a good fit for you.",
    },
    {
      type: "image",
      imageUrl:
        "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/product/RobertoGreen.png",
      title: "Keep it Real",
      titleProps: { fontWeight: "bold" },
      body: "Honesty matters. We run background checks to verify your eligibility, so being up front helps us help you.",
    },
  ];
}

export default function SignupScreen() {
  const { t } = useTranslation();
  const [step, setStep] = useState<FormStep>("credentials");
  const { registerStudent } = useAuth();
  const handleError = useErrorHandler();
  const [searchParams] = useSearchParams();
  const cohortId = searchParams.get("cohortId");
  const organizationId = searchParams.get("organizationId");
  const invitationCode = searchParams.get("invitationCode");
  const [finished, setFinished] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<SignupFormData>(
    process.env.NODE_ENV === "development"
      ? SIGNUP_INFORMATION_SEED
      : {
          email: "",
          password: "",
          passwordConfirmation: "",
          firstName: "",
          lastName: "",
          dateOfBirth: undefined,
          termsOfService: false,
          state: undefined,
          city: "",
          phone: "",
          referralSource: undefined,
          doesNotHaveEmail: false,
        }
  );
  const [cohort, setCohort] = useState<Cohort>();
  const [organization, setOrganization] = useState<Organization>();
  const [partnerReferrer, setPartnerReferrer] = useState<Partner>();
  const { clients } = useAppContext();

  useEffect(() => {
    if (organizationId) {
      fetchOrganization(organizationId, clients)
        .then(setOrganization)
        .catch(handleError);

      if (cohortId) {
        fetchCohort(organizationId, cohortId, clients)
          .then(setCohort)
          .catch(handleError);
      }
    }

    if (invitationCode) {
      fetchPartnerByInvitationCode(invitationCode, clients)
        .then(setPartnerReferrer)
        .catch(handleError);
    }
  }, [cohortId, organizationId, clients, handleError, invitationCode]);

  const onSubmit = async ({
    email,
    password,
    passwordConfirmation,
    dateOfBirth,
    phone,
    ...input
  }: SignupFormData) => {
    if (!dateOfBirth) return;
    if (!input.state) throw new Error("Missing state");
    if (!input.referralSource) throw new Error("Missing referreral source");

    try {
      const success = await registerStudent({
        ...input,
        email: email.trim().toLowerCase(),
        // TODO: remove this fallback once we validate in production the e164 format
        phone: formatNumberToE164(phone) || phone,
        password: password.trim(),
        dateOfBirth: getUTCDateOnly(dateOfBirth),
        state: input.state!,
        cohortId: cohort?.uid,
        organizationId: organization?.uid,
        referredBy: partnerReferrer,
      });
      if (success) setFinished(true);
    } catch (err) {
      if (err instanceof FirebaseError) {
        if (err.code === "auth/email-already-in-use") {
          handleError(
            new Error(
              t(
                "An account with this email already exists. Please use the login page to sign in."
              )
            )
          );
        } else {
          handleError(err);
        }
      } else {
        handleError(err);
      }
    }
  };

  if (!finished) {
    return (
      <RegisterLayout
        corner={<Logo width={300} />}
        sidebar={
          organization ? (
            <OrganizationRegisterWelcomeSection organization={organization} />
          ) : partnerReferrer ? (
            <PartnerRegisterWelcomeSection partner={partnerReferrer} />
          ) : undefined
        }
      >
        <ScreenTitle>{t("Ready to Emerge?")}</ScreenTitle>
        {step === "credentials" && (
          <>
            <Box marginTop={2} marginBottom={6}>
              <Typography variant="body1">
                {t(
                  "We assist you with obtaining scholarships for high-paying vocations. Congrats on taking the first step towards the next step in your life's journey."
                )}
              </Typography>
            </Box>
            <CredentialSignupForm
              defaultValues={formData}
              onSubmit={(data) => {
                setFormData({ ...formData, ...data });
                trackEvent("Signup Screen - Click on Next", {
                  step: "credentials",
                });
                setStep("profile");
              }}
            />
          </>
        )}
        {step === "profile" && (
          <>
            <Box marginTop={2} marginBottom={6}>
              <Typography variant="body1">
                <Trans
                  t={t}
                  defaults="Got it, <bold>{{first_name}}</bold>. Now let's get a bit more info to finish creating your account. Please double check this information matches your legal ID, as this is what will be used by security officials to verify your identity."
                  values={{ first_name: formData.firstName }}
                  components={{ bold: <b /> }}
                />
              </Typography>
            </Box>
            <ProfileSignupForm
              defaultValues={formData}
              onBack={() => setStep("credentials")}
              onSubmit={async (data) => {
                setFormData({ ...formData, ...data });
                trackEvent("Signup Screen - Click on Next", {
                  step: "profile",
                });
                setStep("location");
              }}
            />
          </>
        )}
        {step === "location" && (
          <>
            <Box marginTop={2} marginBottom={6}>
              <Typography variant="body1">
                <Trans
                  t={t}
                  defaults="<bold>{{firstName}}</bold>, now we just need to know your location to identify the right programs for you."
                  values={{ firstName: formData.firstName }}
                  components={{ bold: <b /> }}
                />
              </Typography>
            </Box>
            <LocationSignupForm
              defaultValues={formData}
              onBack={() => setStep("profile")}
              onSubmit={async (data) => {
                setFormData({ ...formData, ...data });
                trackEvent("Signup Screen - Click on Next", {
                  step: "location",
                });
                await onSubmit({ ...formData, ...data });
              }}
            />
          </>
        )}
      </RegisterLayout>
    );
  }
  const steps = getSteps({ firstName: formData.firstName });

  return (
    <SliderExplanationScreen
      finishButtonProps={{
        onFinish: () => navigate("/onboarding/survey"),
      }}
      steps={steps}
    />
  );
}
