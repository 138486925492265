import { format, isValid, parse, startOfDay } from "date-fns";

export default function displayLocalDate(dateStr: string): string {
  const parsedDate = parse(dateStr, "yyyy-MM-dd", new Date());
  if (!isValid(parsedDate)) {
    return "Invalid Date";
  }
  const localDate = startOfDay(parsedDate);
  return format(localDate, "MMM d, yyyy");
}
