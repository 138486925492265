import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Partner } from "src/types/Partner";
import isPartnerGuard from "src/utils/isPartnerGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchPartners({
  db,
}: Clients): Promise<Partner[]> {
  const snapshot = await getDocs(collection(db, "partners"));
  const documents = snapshot.docs.map(cleanFirestoreDoc).filter(isPartnerGuard);
  return documents;
}
