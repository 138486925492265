// src/hooks/useSchool.ts
import { useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchSchool from "src/firebase/fetchSchool";
import isSchoolStudyMaterialGuard from "src/types/isSchoolStudyMaterialGuard";
import { School, SchoolStudyMaterial } from "src/types/School";
import { UserAccount } from "src/types/User";
import useErrorHandler from "src/utils/useErrorHandler";

const useSchool = (user: UserAccount) => {
  const [school, setSchool] = useState<School | undefined>(undefined);
  const [studyMaterials, setStudyMaterials] = useState<SchoolStudyMaterial[]>(
    []
  );
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    const fetchSchoolData = async () => {
      if (!user.schoolId) return;
      try {
        const schoolData = await fetchSchool(user.schoolId, clients);
        setSchool(schoolData);
        setStudyMaterials(
          schoolData.studyMaterials
            ? schoolData.studyMaterials.filter(isSchoolStudyMaterialGuard)
            : []
        );
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchSchoolData();
  }, [user, clients, errorHandler]);

  return { school, studyMaterials };
};

export default useSchool;
