import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CardSkeleton } from "src/components/Skeleton/closet";
import { Header20 } from "src/components/Typography";
import ProgressIndicator from "src/pages/OnboardingScreen/ProgressIndicator";
import { UserAccount } from "src/types/User";
import useUserUploadedDocuments from "src/utils/useUserUploadedDocuments";

type Props = { user: UserAccount };
export default function UploadDocumentsCard({ user }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    loading: loadingDocuments,
    hasSubmittedAllDocuments,
    totalDocumentsSubmitted,
    totalDocumentsRequired,
  } = useUserUploadedDocuments(user);

  if (loadingDocuments) return <CardSkeleton />;
  if (hasSubmittedAllDocuments) return <div />;

  return (
    <Card>
      <CardActionArea onClick={() => navigate("/documents")}>
        <CardContent>
          <Stack spacing={1}>
            <Box>
              <Chip variant="outlined" label={t("Task")} color="info" />
            </Box>
            <ProgressIndicator
              progress={totalDocumentsSubmitted}
              total={totalDocumentsRequired}
              label={t("Documents Uploaded")}
            />
            <Header20>{t("Upload Your Documents")}</Header20>
            <Typography>
              {t(
                "As we work with government scholarships, we need your personal documents to confirm your enrollment."
              )}
            </Typography>
          </Stack>
        </CardContent>
        <CardActions>
          <Button variant="contained">{t("Start")}</Button>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
