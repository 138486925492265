import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useSchool from "src/hooks/useSchool";
import { useCurrentUser } from "src/SessionBoundary";
import PodcastPlayer from "../components/PodcastPlayer"; // Assuming you have a PodcastPlayer component
import AppSkeleton from "./AppSkeleton";
import { trackEvent } from "./OnboardingScreen/amplitude";

const PodcastScreen: React.FC = () => {
  const { id: studyMaterialId } = useParams<{ id: string }>();
  const user = useCurrentUser();
  const { studyMaterials, school } = useSchool(user);

  const studyMaterial = studyMaterials.find(
    (material) => material.id === studyMaterialId
  );

  if (!studyMaterialId)
    throw new Error(
      "You are trying to access a podcast page without specifying the ID!"
    );

  useEffect(() => {
    if (!studyMaterial || !school) return;
    // Logic to show the slider explanation steps when the page loads
    // This could be a modal or a dedicated component
    trackEvent("Study Material Screen - Viewed", {
      study_material_name: studyMaterial.name,
      study_material_id: studyMaterial.id,
      school: school.name,
    });
  }, [studyMaterial, school]);

  const handleFinish = (params: {
    rating?: number;
    action: "go_home" | "restart";
  }) => {
    // Type narrowing
    if (!studyMaterial || !school) return;

    trackEvent("Study Material Screen - Finished", {
      study_material_name: studyMaterial.name,
      study_material_id: studyMaterial.id,
      school: school.name,
      rating: params.rating,
      action: params.action,
    });
  };

  if (!studyMaterial) return <AppSkeleton />;

  return (
    <Box>
      <PodcastPlayer
        title={studyMaterial.name}
        transcriptUrl={studyMaterial.transcriptUrl}
        audioUrl={studyMaterial.audioUrl}
        onFinish={handleFinish}
      />
    </Box>
  );
};

export default PodcastScreen;
