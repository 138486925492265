import { t } from "i18next";

export const nextLessonToastMessages: string[] = [
  t("Keep going! More videos ahead!"),
  t("Next up: More learning!"),
  t("Let's dive into the next video!"),
  t("Onward! The next video awaits!"),
  t("Moving forward to the next video!"),
  t("Time for the next lesson!"),
  t("One step closer—next video coming up!"),
  t("Keep the momentum going! Next video!"),
  t("You're doing great! On to the next!"),
  t("Another step forward—next video!"),
  t("Let's keep learning! Next video ahead!"),
  t("The journey continues—next video!"),
  t("Up next: Another great lesson!"),
  t("Keep pushing forward! Next video!"),
  t("Great job! Time for the next one!"),
  t("Another video, another lesson!"),
  t("You're making progress! Keep going!"),
  t("No stopping now—next video!"),
  t("Full speed ahead! Next video!"),
  t("Stay curious—next video awaits!"),
  t("Keep the streak alive! Next video!"),
  t("One more step—next video!"),
  t("You're on fire! Keep it up!"),
  t("Next stop: More knowledge!"),
  t("Here we go—next video!"),
  t("Don't stop now! More to learn!"),
  t("Let's roll—next video time!"),
  t("You're crushing it! Keep watching!"),
  t("Almost there! Next video!"),
  t("The adventure continues—next video!"),
  t("Another video, another win!"),
  t("Forward we go! Next video!"),
  t("No breaks, just learning—next up!"),
  t("Progress unlocked! Next video!"),
  t("You're in the zone! Next video!"),
  t("More insights ahead! Next video!"),
  t("One step ahead—let's go!"),
  t("Stay on track—next video!"),
  t("Keep watching, keep growing!"),
  t("The next video is ready for you!"),
  t("Stay focused—next video ahead!"),
  t("You're doing awesome! Next up!"),
  t("Level up! Next video time!"),
  t("Learning never stops—next video!"),
  t("Ready for more? Next video!"),
  t("Another step in your journey!"),
  t("Almost there! Keep watching!"),
  t("One lesson closer to mastery!"),
  t("You're on a roll! Keep going!"),
  t("Exciting things ahead—next video!"),
];

export const unitCompletionMessages: string[] = [
  t("Amazing work! You've completed this unit!"),
  t("Unit complete! You're making great progress!"),
  t("Well done! Another unit in the books!"),
  t("Congrats! You've finished this unit!"),
  t("You're unstoppable! This unit is done!"),
  t("Another milestone reached—unit completed!"),
  t("Great job! On to the next challenge!"),
  t("You've mastered this unit! Keep going!"),
  t("Unit complete! Keep up the momentum!"),
  t("Fantastic effort! One step closer to mastery!"),
];
