/* eslint-disable class-methods-use-this */
import { User as FirestoreUser } from "firebase/auth";
import {
  addDoc,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  QuerySnapshot,
  setDoc,
  Unsubscribe,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  getBlob,
  getStorage,
  ref,
  uploadBytes,
  UploadResult,
} from "firebase/storage";
import { db as idb } from "src/api/db";
import { EmployerData } from "src/pages/ClientScreen/EmploymentTab/EmployerForm";
import { ChatMessage } from "src/pages/DashboardPage/ChatbotTab/ChatbotTab";
import { AttendanceFormData } from "src/pages/DashboardPage/RangeTab/RangeAttendanceForm";
import { ReferenceFormData } from "src/pages/OnboardingScreen/ReferenceForm";
import { Organization, OrganizationStaff } from "src/types";
import { Admin } from "src/types/Admin";
import { Attendance } from "src/types/Attendance";
import { Cohort } from "src/types/Cohort";
import { Employer, StudentEmployerRelationship } from "src/types/Employer";
import { Note } from "src/types/Note";
import { Partner } from "src/types/Partner";
import { StudentReference } from "src/types/StudentReference";
import {
  UserAccount,
  UserOnboardingStatus,
  UserResourceProgress,
  UserType,
} from "src/types/User";
import { getFullName, timeout } from "src/utils";
import isAdminGuard from "src/utils/isAdminGuard";
import isAttendanceGuard from "src/utils/isAttendanceGuard";
import isCohortGuard from "src/utils/isCohortGuard";
import isEmployerGuard from "src/utils/isEmployerGuard";
import isNoteGuard from "src/utils/isNoteGuard";
import isOrganizationGuard from "src/utils/isOrganizationGuard";
import isOrganizationStaffGuard from "src/utils/isOrganizationStaffGuard";
import isPartnerGuard from "src/utils/isPartnerGuard";
import isQuizGradeGuard from "src/utils/isQuizGradeGuard";
import isStudentEmployerRelationshipGuard from "src/utils/isStudentEmployerRelationshipGuard";
import isStudentReference from "src/utils/isStudentReferenceGuard";
import sortBy from "src/utils/sortBy";
import { LIB_VERSION } from "src/version";
import { db } from ".";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

export type QuizGrade = {
  uid: string;
  quizId: string;
  grade: number;
  totalQuestions: number;
  totalCorrectAnswers: number;
  // was added to the schema after the first version -- adding it to create
  lastUpdatedAt?: string;
};

export type UpdateResourcePayload = Pick<
  UserResourceProgress,
  "progressSeconds" | "resourceId" | "progressFraction" | "hasCompleted"
>;

export type UpdateBookPayload = {
  location: string | number;
};

export type CurrentUser = FirestoreUser & UserAccount;

export const requiredEnrollmentDocuments = [
  "ssn",
  "driver_license",
  "birth_certificate",
  "pay_stub",
  "utility_bill",
  "selective_services",
  "food_stamps",
] as const;
export type RequiredEnrollmentDocument =
  typeof requiredEnrollmentDocuments[number];

const optionalEnrollmentDocuments: string[] = [];

export type OptionalEnrollmentDocument =
  typeof optionalEnrollmentDocuments[number];

const milestoneDocuments = [
  "updated_resume",
  "license",
  "theory_permit",
  "medical_exam",
  "completion_certificate",
  "job_offer",
  "cover_letter",
  "background_check",
  "driver_record",
] as const;

export type MilestoneDocument = typeof milestoneDocuments[number];

export type StudentDocument =
  | RequiredEnrollmentDocument
  | OptionalEnrollmentDocument
  | MilestoneDocument;

const LOCAL_STORAGE_USER_KEY = "LOCAL_STORAGE_USER_KEY";

export function getSerializedUser(): string | null {
  return localStorage.getItem(LOCAL_STORAGE_USER_KEY);
}

export type SessionAccountInformation =
  | (UserAccount & { type: UserType.User })
  | (OrganizationStaff & {
      type: UserType.OrganizationStaff;
      organization: Organization;
    })
  | (Admin & { type: UserType.Admin })
  | (Partner & { type: UserType.Partner });
export default class FirestoreClient {
  // TODO: update it to add admin type as well
  metadata = null as SessionAccountInformation | null;

  credentials = {} as FirestoreUser;

  loading = true;

  constructor(private readonly user: FirestoreUser) {
    this.credentials = user;
  }

  private async initialize() {
    const user = await this.fetchUserAccount();

    if (user) {
      this.metadata = { ...user, type: UserType.User };
      await this.updateCurrentUserMetadata("users");
      return;
    }

    const staff = await this.fetchOrganizationStaff(this.user.uid);

    if (staff) {
      const organization = await this.fetchOrganization(staff.organizationId);
      await this.updateCurrentUserMetadata("organizationStaff");

      if (!organization) throw new Error("Organization does not exist");
      this.metadata = {
        ...staff,
        type: UserType.OrganizationStaff,
        organization,
      };
      return;
    }

    const partner = await this.fetchPartner();
    if (partner) {
      await this.updateCurrentUserMetadata("partners");
      this.metadata = { ...partner, type: UserType.Partner };
      return;
    }

    const admin = await this.fetchAdmin();

    if (admin) {
      await this.updateCurrentUserMetadata("admins");

      this.metadata = { ...admin, type: UserType.Admin };
      return;
    }

    // if we reach this point it means the user has Firebase auth credentials but no account (e.g there is a race condition in AuthContext when creating partners)
    throw new Error(
      "Email credentials exist but the user account has not been set up yet"
    );
  }

  static async create(user: FirestoreUser) {
    const o = new FirestoreClient(user);
    await o.initialize();
    return o;
  }

  // demographic survey
  async fetchUserAccount(): Promise<UserAccount | undefined> {
    const snapshot = await getDoc(this.getUserDoc());

    // TODO: stop manually casting
    const data = cleanFirestoreDoc(snapshot);

    this.loading = false;

    return data as UserAccount | undefined;
  }

  async updateUserAccount(metadata: UserAccount) {
    this.metadata = { ...metadata, type: UserType.User };
  }

  subscribeToUserResourceProgress(
    cb: (snapshot: QuerySnapshot<DocumentData>) => void,
    id?: string
  ): Unsubscribe {
    const unsubscribe = onSnapshot(
      collection(db, "users", id || this.credentials.uid, "resources"),
      cb
    );
    return unsubscribe;
  }

  async setUserResourceProgress(
    resourceId: string,
    payload: UpdateResourcePayload
  ): Promise<void> {
    await setDoc(doc(db, `users/${this.user.uid}/resources/`, resourceId), {
      userId: this.user.uid,
      ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
      ...payload,
    });
    // Note: firebase has a clever implementation that does not resolve until the user's network connection is restored
    // once we have an API this functionality won't be built-in
    // I am manually forcing a timeout here (similar to what will happen when we have an actual API)
    // try {
    //   await timeout(
    //     3000,
    //     setDoc(doc(db, `users/${this.user.uid}/resources/`, resourceId), {
    //       userId: this.user.uid,
    //       ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
    //       ...payload,
    //     })
    //   );
    // } catch (err) {
    //   // fallback is to cache as an offline actions for later
    //   await idb.upsertOfflineAction({
    //     id: resourceId,
    //     type: "resource",
    //     payload,
    //   });
    // }
  }

  async setUserGrade(quizRes: Omit<QuizGrade, "uid">): Promise<void> {
    // Note: firebase has a clever implementation that does not resolve until the user's network connection is restored
    // once we have an API this functionality won't be built-in
    // I am manually forcing a timeout here (similar to what will happen when we have an actual API)
    try {
      await timeout(
        3000,
        setDoc(doc(db, `users/${this.user.uid}/grades/`, quizRes.quizId), {
          userId: this.user.uid,
          ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
          ...quizRes,
        })
      );
    } catch (err) {
      // fallback is to cache as an offline actions for later
      await idb.upsertOfflineAction({
        id: quizRes.quizId,
        type: "quiz",
        payload: quizRes,
      });
    }
  }

  async setBookPage(bookId: string, payload: UpdateBookPayload): Promise<void> {
    try {
      await timeout(
        3000,
        setDoc(doc(db, `users/${this.user.uid}/books/`, bookId), {
          ...payload,
        })
      );
    } catch (err) {
      // fallback is to cache as an offline actions for later
      await idb.upsertOfflineAction({ id: bookId, type: "book", payload });
    }
  }

  getUserDoc() {
    return doc(db, "users", this.user.uid);
  }

  // TODO: once we finalize migrating all the accounts to roles we need to revisit this
  async updateCurrentUserStatus(onboardingStatus: UserOnboardingStatus) {
    updateDoc(doc(db, `users/${this.user.uid}`), {
      onboardingStatus,
    });
  }

  async updateCurrentUserMetadata(
    collection: "users" | "organizationStaff" | "admins" | "partners"
  ) {
    updateDoc(doc(db, `${collection}/${this.user.uid}`), {
      ...generateFirestoreTimestamps(new Date(), "lastLoggedIn"),
      appVersion: LIB_VERSION,
    });
  }

  async updateCurrentUser(userId: string, update: Partial<UserAccount>) {
    updateDoc(doc(db, `users/${userId}`), {
      ...update,
      ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
    });
  }

  async uploadStudentDocument(
    document: { file: File; type: StudentDocument },
    id?: string
  ): Promise<UploadResult> {
    const storage = getStorage();
    const userId = id || this.user.uid;
    const storageRef = ref(
      storage,
      `documents/${userId}/${document.type}-${document.file.name}`
    );
    const res = await uploadBytes(storageRef, document.file);
    await setDoc(doc(db, `users/${userId}/documents/`, document.type), {
      path: res.ref.fullPath,
      userId,
      name: document.type,
    });

    return res;
  }

  async createAttendance(
    { photo, date, ...rest }: AttendanceFormData,
    id?: string
  ): Promise<Attendance> {
    const storage = getStorage();
    const userId = id || this.user.uid;

    const storageRef = ref(
      storage,
      `attendance/${userId}/${new Date().toISOString()}`
    );

    const payload: Omit<Attendance, "uid"> = {
      userId,
      ...rest,
      ...generateFirestoreTimestamps(new Date(), "createdAt"),
      ...generateFirestoreTimestamps(date, "date"),
    };

    if (photo) {
      const res = await uploadBytes(storageRef, photo);
      payload.path = res.ref.fullPath;
    }
    const doc = await addDoc(
      collection(db, "users", userId, "attendance"),
      payload
    );

    const attendance = cleanFirestoreDoc(await getDoc(doc));

    if (!isAttendanceGuard(attendance))
      throw new Error("Something went wrong creating the attendance record");

    return attendance;
  }

  async deleteAttendance(
    clientId: string,
    attendanceId: string
  ): Promise<void> {
    await deleteDoc(doc(db, `users/${clientId}/attendance/${attendanceId}`));
  }

  async fetchAttendanceRecords(userId?: string): Promise<Attendance[]> {
    const id = userId || this.user.uid;

    const snapshot = await getDocs(
      query(collection(db, "users", id, "attendance"))
    );

    return snapshot.docs.map(cleanFirestoreDoc).filter(isAttendanceGuard);
  }

  async getStorageBlob(
    type: StudentDocument,
    userId?: string
  ): Promise<File | undefined> {
    const storage = getStorage();
    const snapshot = await getDoc(
      doc(db, "users", userId || this.user.uid, "documents", type)
    );
    const data = snapshot.data();

    if (!data) return undefined;

    const file = new File([await getBlob(ref(storage, data.path))], data.path);

    return file;
  }

  // Student reference

  async createStudentReference(
    data: ReferenceFormData,
    userId?: string
  ): Promise<StudentReference> {
    const id = userId || this.user.uid;

    const res = await addDoc(collection(db, "users", id, "references"), {
      ...data,
      userId: id,
      ...generateFirestoreTimestamps(new Date(), "createdAt"),
    });

    const doc = cleanFirestoreDoc(await getDoc(res));

    if (!isStudentReference(doc)) {
      throw new Error("Erro creating reference");
    }

    return doc;
  }

  async fetchStudentReferences(userId?: string) {
    const id = userId || this.user.uid;
    const snapshot = await getDocs(collection(db, "users", id, "references"));
    return snapshot.docs.map(cleanFirestoreDoc).filter(isStudentReference);
  }

  // Chatbot
  async fetchQuestions(): Promise<ChatMessage[]> {
    const snapshot = await getDocs(
      query(collection(db, "questions"), where("userId", "==", this.user.uid))
    );
    const questions = snapshot.docs.map(cleanFirestoreDoc) as ChatMessage[];
    return questions;
  }

  async fetchOrganizationStaff(
    uid: string
  ): Promise<OrganizationStaff | undefined> {
    const data = cleanFirestoreDoc(
      await getDoc(doc(db, "organizationStaff", uid))
    );

    if (!isOrganizationStaffGuard(data)) return undefined;

    return data;
  }

  async fetchOrganization(
    organizationId: string
  ): Promise<Organization | undefined> {
    const snapshot = await getDoc(doc(db, "organizations", organizationId));
    const data = cleanFirestoreDoc(snapshot);
    if (!isOrganizationGuard(data))
      throw new Error("Organization does not exist");
    return data;
  }

  async fetchOrganizationClients(
    organizationId: string
  ): Promise<UserAccount[]> {
    // get all clients affiliated with the organization
    const clientsSnapshot = await getDocs(
      query(
        collection(db, "users"),
        where("organizationId", "==", organizationId)
      )
    );
    const clients = await Promise.all(
      clientsSnapshot.docs.map(async (doc) => {
        const client = cleanFirestoreDoc(doc) as UserAccount;
        return client;
      })
    );

    return clients;
  }

  async fetchClientGrades(
    userId: string
  ): Promise<(QuizGrade & { label: string })[]> {
    const snapshot = await getDocs(collection(db, "users", userId, "grades"));

    const grades = snapshot.docs
      .map(cleanFirestoreDoc)
      .filter(isQuizGradeGuard);

    function createGradeLabel(grade: number) {
      if (grade <= 0.1) return "0-10";
      if (grade <= 0.2) return "10-20";
      if (grade <= 0.3) return "20-30";
      if (grade <= 0.4) return "30-40";
      if (grade <= 0.5) return "40-50";
      if (grade <= 0.6) return "50-60";
      if (grade <= 0.7) return "60-70";
      if (grade <= 0.8) return "70-80";
      if (grade <= 0.9) return "80-90";
      return "90-100";
    }

    return grades.map((grade) => ({
      ...grade,
      label: createGradeLabel(grade.grade),
    }));
  }

  async fetchClient(uid: string): Promise<UserAccount> {
    if (!this.metadata) throw new Error("Needs to be logged in");

    const snapshot = await getDoc(doc(db, "users", uid));
    const client = cleanFirestoreDoc(snapshot) as UserAccount;

    return client;
  }

  async updateNote(
    clientId: string,
    noteId: string,
    update: Pick<Note, "description" | "date">
  ): Promise<void> {
    await updateDoc(doc(db, `users/${clientId}/notes/${noteId}`), update);
  }

  async deleteNote(clientId: string, noteId: string): Promise<void> {
    await deleteDoc(doc(db, `users/${clientId}/notes/${noteId}`));
  }

  async createNote(
    data: Omit<
      Note,
      | "uid"
      | "dateFirestoreTimestamp"
      | "createdAt"
      | "createdAtFirestoreTimestamp"
      | "lastUpdatedAt"
      | "lastUpdatedAtFirestoreTimestamp"
      | "author"
      | "authorId"
      | "authorOrganization"
    >,
    userId: string
  ): Promise<Note> {
    if (!this.metadata) throw new Error("Cannot load information");
    if (this.metadata.type === UserType.User)
      throw new Error("Cannot create case notes");

    const authorship: Pick<Note, "author" | "authorId" | "authorOrganization"> =
      {
        author: getFullName(this.metadata),
        authorOrganization:
          this.metadata.type === UserType.OrganizationStaff
            ? this.metadata.organization.name
            : "Emerge Career",
        authorId: this.metadata.uid,
      };

    const doc = await addDoc(collection(db, "users", userId, "notes"), {
      ...data,
      ...generateFirestoreTimestamps(new Date(data.date), "date"),
      ...generateFirestoreTimestamps(new Date(), "createdAt"),
      ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
      ...authorship,
    });

    const note = cleanFirestoreDoc(await getDoc(doc));
    if (!isNoteGuard(note)) throw new Error("Something went wrong");
    return note;
  }

  // admin routes
  async fetchAdmin(): Promise<Admin | undefined> {
    const snapshot = await getDoc(doc(db, "admins", this.user.uid));

    const data = cleanFirestoreDoc(snapshot);

    if (!isAdminGuard(data)) return undefined;

    return data;
  }

  // next step - figure out authentication. maybe hash password?
  async fetchPartner(): Promise<Partner | undefined> {
    const snapshot = await getDoc(doc(db, "partners", this.user.uid));

    const data = cleanFirestoreDoc(snapshot);

    if (!isPartnerGuard(data)) return undefined;

    return data;
  }

  async fetchUsers(): Promise<UserAccount[]> {
    const snapshot = await getDocs(
      query(collection(db, "users"), where("onboardingStatus", "!=", ""))
    );

    const users = snapshot.docs.map(cleanFirestoreDoc) as UserAccount[];

    return users;
  }

  async fetchOrganizations(): Promise<Organization[]> {
    const organizations = (
      await getDocs(collection(db, "organizations"))
    ).docs.map((snap) => cleanFirestoreDoc(snap)) as Organization[];

    return organizations;
  }

  async updateUserOrganization(
    user: Pick<UserAccount, "uid">,
    organizationId: string
  ) {
    updateDoc(doc(db, `users/${user.uid}`), {
      organizationId,
    });
  }

  async updateUserEmergeCoach(
    user: Pick<UserAccount, "uid">,
    emergeCoachId: string
  ) {
    updateDoc(doc(db, `users/${user.uid}`), {
      emergeCoachId,
    });
  }

  async fetchCohorts(): Promise<Cohort[]> {
    const cohorts = (await getDocs(collectionGroup(db, "cohorts"))).docs
      .map(cleanFirestoreDoc)
      .filter(isCohortGuard);

    return sortBy(cohorts, "name");
  }

  async fetchOrganizationCohorts(organizationId: string): Promise<Cohort[]> {
    const cohorts = (
      await getDocs(collection(db, "organizations", organizationId, "cohorts"))
    ).docs
      .map(cleanFirestoreDoc)
      .filter(isCohortGuard);

    return sortBy(cohorts, "name");
  }

  async updateCohort(
    client: Pick<UserAccount, "uid">,
    cohortId: string
  ): Promise<void> {
    await updateDoc(doc(db, `users/${client.uid}`), {
      cohortId,
    });
  }

  // Employers
  async fetchEmployers(): Promise<Employer[]> {
    const snapshot = await getDocs(collection(db, "employers"));
    const employers = snapshot.docs
      .map(cleanFirestoreDoc)
      .filter(isEmployerGuard);
    return employers;
  }

  async createEmployer(employer: EmployerData): Promise<Employer> {
    const doc = await addDoc(collection(db, "employers"), employer);
    const employerDoc = cleanFirestoreDoc(await getDoc(doc));
    if (!isEmployerGuard(employerDoc)) throw new Error("Something went wrong");
    return employerDoc;
  }

  async createStudentEmploymentRelationship(
    employerId: string,
    userId: string
  ): Promise<StudentEmployerRelationship> {
    const docReference = doc(db, `users/${userId}/employers/${employerId}`);
    await setDoc(docReference, {
      employerId,
      userId,
      ...generateFirestoreTimestamps(new Date(), "createdAt"),
      ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
    });
    const employerEmploymentRelationship = cleanFirestoreDoc(
      await getDoc(docReference)
    );
    if (!isStudentEmployerRelationshipGuard(employerEmploymentRelationship))
      throw new Error("Something went wrong");
    return employerEmploymentRelationship;
  }

  async fetchStudentEmploymentRelationships(
    userId?: string
  ): Promise<StudentEmployerRelationship[]> {
    const uid = userId || this.user.uid;
    const snapshot = await getDocs(collection(db, `users/${uid}}/employers`));
    return snapshot.docs
      .map(cleanFirestoreDoc)
      .filter(isStudentEmployerRelationshipGuard);
  }

  // Student Employment Plan
}
