import { Skeleton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import { SourceProps } from "react-player/base";

type Props = ReactPlayerProps & {
  start?: number;
};

// Cache-first video component
// It first checkes whether the given url path has been previously stored on IndexDB. Importnant for offline-first support
const VideoPlayer = ({ url, start, onPlay, ...rest }: Props) => {
  const [source, setSource] = useState<
    string | string[] | SourceProps[] | MediaStream
  >();

  const ref = useRef<ReactPlayer>(null);

  useEffect(() => {
    setSource(url);
    // TODO: consider uncommenting this if we revert back
    // (async () => {
    //   if (typeof url === "string") {
    //     const asset = await db.getAsset(url);
    //     setSource(
    //       asset ? URL.createObjectURL(asset.blob) : `${url}?t=${start}`
    //     );
    //   } else {
    //     setSource(url);
    //   }
    // })();
  }, [url, start]);

  if (!source) return <Skeleton variant="rectangular" />;

  return (
    <ReactPlayer
      ref={ref}
      url={source}
      controls
      onStart={() => {
        if (onPlay) onPlay();
        if (start) ref.current?.seekTo(start, "seconds");
      }}
      {...rest}
    />
  );
};

export default VideoPlayer;
