import { SchoolStudyMaterial } from "src/types/School";

export default function isSchoolStudyMaterialGuard(
  obj: unknown
): obj is SchoolStudyMaterial {
  const studyMaterial = obj as SchoolStudyMaterial;
  return (
    studyMaterial &&
    typeof studyMaterial.id === "string" &&
    typeof studyMaterial.name === "string" &&
    typeof studyMaterial.audioUrl === "string" &&
    typeof studyMaterial.transcriptUrl === "string" &&
    studyMaterial.type === "audio"
  );
}
