import { Box, Card, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Header, PaddedLayout } from "src/components/Layout";
import PaddedLayoutContainer from "src/components/Layout/PaddedLayoutContainer";
import Template from "src/components/Layout/Template";
import { useCurrentUser } from "src/SessionBoundary";
import GradesTab from "../ClientScreen/GradesTab";
import StudentPlanTab from "../ClientScreen/StudentPlanTab/StudentPlanTab";
import JobSearchTrainingTab from "../JobSearchTrainingTab";
import AdminParticipantChat from "./AdminParticipantChatTab/AdminParticipantChatTab";
import DashboardCohortAnnouncements from "./DashboardCohortAnnouncements";
import DashboardTabs, { DashboardTab } from "./DashboardTabs";
import OnlineTrainingView from "./OnlineTrainingView";
import ResourceTab from "./ProfileScreen";
import PWASnackbar from "./PWASnackbar";
import SkillsTrainingTab from "./RangeTab/SkillsTrainingTab";
import SavedTab from "./SavedTab";

// ID of  CLP is currently hardcoded
export const DashboardScreen = () => {
  const user = useCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams({
    tab: "home",
  });
  const [tab, setTab] = useState<DashboardTab>(
    (searchParams.get("tab") as DashboardTab) || "home"
  );

  const handleTabChange = (value: DashboardTab) => {
    setTab(value);
    setSearchParams((prev) => {
      prev.set("tab", value);
      return prev;
    });
  };

  useEffect(() => {
    if (
      user.enrollmentStatus === "skills_training" ||
      user.enrollmentStatus === "completed_skills_training"
    ) {
      setTab("range");
    } else if (user.enrollmentStatus === "licensed") {
      setTab("job_search");
    }
  }, [user.enrollmentStatus]);

  return (
    <Template>
      <PWASnackbar />
      <Header
        title="CDL Course"
        section={`Welcome back, ${user.firstName}! ${
          process.env.NODE_ENV === "development" ? user.uid : ""
        }`}
      >
        <DashboardTabs onChange={handleTabChange} value={tab} />
      </Header>
      {user.cohortId && user.organizationId && (
        <PaddedLayout>
          <DashboardCohortAnnouncements
            cohort={{ uid: user.cohortId, organizationId: user.organizationId }}
          />
        </PaddedLayout>
      )}
      {tab === "home" && (
        <Box>
          <OnlineTrainingView
            user={user}
            onViewSuccessPlan={() => setTab("success_plan")}
          />
        </Box>
      )}
      {tab === "resources" && <ResourceTab />}
      {tab === "grades" && <GradesTab client={user} />}
      {tab === "saved" && <SavedTab />}
      {tab === "chat" && (
        <Box>
          <Box margin="auto" maxWidth={800}>
            <Card>
              <CardContent>
                <AdminParticipantChat client={user} />
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
      {tab === "success_plan" && (
        <PaddedLayout>
          <PaddedLayoutContainer>
            <StudentPlanTab client={user} />
          </PaddedLayoutContainer>
        </PaddedLayout>
      )}
      {tab === "range" && (
        <Box>
          <SkillsTrainingTab user={user} />
        </Box>
      )}
      {tab === "job_search" && (
        <PaddedLayout>
          <JobSearchTrainingTab user={user} />
        </PaddedLayout>
      )}
    </Template>
  );
};

export default DashboardScreen;
