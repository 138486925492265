import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Empty from "src/components/Empty";
import { useAppContext } from "src/contexts/AppContext";
import createChatMessage from "src/firebase/createChatMessage";
import fetchOrganizationSchools from "src/firebase/fetchOrganizationSchools";
import updateUserSchool from "src/firebase/updateUserSchool";
import updateUserSkillsTrainingPlan from "src/firebase/updateUserSkillsTrainingPlan";
import { School } from "src/types/School";
import { UserAccount } from "src/types/User";
import getDistanceBetweenZips, {
  getCoordinatesFromZip,
} from "src/utils/getDistanceBetweenZips";
import useErrorHandler from "src/utils/useErrorHandler";
import AppSkeleton from "../AppSkeleton";
import { ChatMessageSenderType } from "../DashboardPage/AdminParticipantChatTab/ChatMessage";
import ConfirmSchoolSelection from "./ConfirmSchoolSelection";
import SchoolCard from "./SchoolCard";

type Props = { onSuccess: (selectedSchool: School) => void; user: UserAccount };
export default function SchoolSelectionForm({ onSuccess, user }: Props) {
  const { clients } = useAppContext();
  const [schools, setSchools] = useState<School[]>([]);
  const [filteredSchools, setFilteredSchools] = useState<School[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedSchedule, setSelectedSchedule] = useState<string | null>(null);
  const [selectedSchoolId, setSelectedSchoolId] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState<
    "filter" | "select" | "review"
  >("filter");
  const errorHandler = useErrorHandler();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userZipCode] = useState(user.application?.zipcode); // Store user's ZIP code
  const [schoolDistances, setSchoolDistances] = useState<
    Record<string, number>
  >({});
  const [loadingDistances, setLoadingDistances] = useState(true);

  if (!user.organizationId) {
    throw new Error(
      `You must be part of an active training cohort to select a school.`
    );
  }

  useEffect(() => {
    fetchOrganizationSchools(user.organizationId!, clients)
      .then(setSchools)
      .catch(errorHandler)
      .finally(() => setLoading(false));
  }, [user.organizationId, clients, errorHandler]);

  const handleScheduleFilter = () => {
    if (selectedSchedule === "both" || selectedSchedule === "full-time") {
      setFilteredSchools(schools);
    } else if (selectedSchedule === "part-time") {
      setFilteredSchools(
        schools.filter((school) => school.hasPartTimeSchedule)
      );
    }
    setCurrentStep("select");
  };

  const handleSelectSchool = (schoolId: string) => {
    setCurrentStep("review");
    setSelectedSchoolId(schoolId);
  };

  const handleConfirmReview = async (trainingSchedule: string) => {
    if (!selectedSchoolId) throw new Error("Invalid selection.");

    const selectedSchool = filteredSchools.find(
      (school) => school.uid === selectedSchoolId
    );

    if (!selectedSchool) throw new Error("Invalid selection.");

    await updateUserSchool(
      {
        user,
        school: selectedSchool,
      },
      clients
    )
      .then(() => onSuccess(selectedSchool))
      .catch(errorHandler);

    await updateUserSkillsTrainingPlan(
      {
        user,
        skillsTrainingPlan: {
          skillsTrainingSchedule: trainingSchedule,
          projectedDurationWeeks: selectedSchedule === "full-time" ? 4 : 8,
        },
      },
      clients
    ).catch(errorHandler);
  };

  const selectedSchool = filteredSchools.find(
    (school) => school.uid === selectedSchoolId
  );

  const handleNotifyTeam = async () => {
    try {
      await createChatMessage(
        {
          participantId: user.uid,
          senderId: user.uid,
          senderType: ChatMessageSenderType.Participant,
          text: `There are no schools available to me. I live in ${user.city} and I am looking for a ${selectedSchedule} schedule.`,
        },
        clients
      );
      navigate("/coach");
    } catch (error) {
      errorHandler(error);
    }
  };

  // load distances for all schols
  useEffect(() => {
    async function loadDistances() {
      if (!userZipCode) return;
      setLoadingDistances(true);

      const userLocation = await getCoordinatesFromZip(userZipCode);
      if (!userLocation) return;

      const distances: Record<string, number> = {};

      try {
        await Promise.all(
          schools.map(async (school) => {
            if (!school.zipcode) return;
            const distanceData = await getDistanceBetweenZips(
              userZipCode,
              school.zipcode
            );
            if (distanceData) {
              distances[school.uid] = distanceData.distanceInMiles;
            }
          })
        );
        setSchoolDistances(distances);
      } catch (error) {
        errorHandler(
          new Error("Please check the school that is closest to you!")
        );
      } finally {
        setLoadingDistances(false);
      }
    }

    loadDistances();
  }, [userZipCode, schools, errorHandler]);

  // after loading distances, sort school by distance
  useEffect(() => {
    if (!loadingDistances) {
      setFilteredSchools((prevSchools) =>
        [...prevSchools].sort(
          (a, b) =>
            (schoolDistances[a.uid] || Infinity) -
            (schoolDistances[b.uid] || Infinity)
        )
      );
    }
  }, [loadingDistances, schoolDistances]);

  if (loading) return <AppSkeleton />;

  return (
    <FormControl component="fieldset">
      {currentStep === "filter" && (
        <Box>
          <Typography variant="h6" gutterBottom>
            {t("Please select your availability:")}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {t(
              "Note: Even for part-time schedules, we require at least 20 hours a week of availability to train."
            )}
          </Typography>
          <RadioGroup
            value={selectedSchedule}
            onChange={(e) => setSelectedSchedule(e.target.value)}
          >
            <FormControlLabel
              value="full-time"
              control={<Radio />}
              label="Full-time"
            />
            <FormControlLabel
              value="part-time"
              control={<Radio />}
              label="Part-time"
            />
            <FormControlLabel value="both" control={<Radio />} label="Both" />
          </RadioGroup>
          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleScheduleFilter}
              disabled={!selectedSchedule}
            >
              {t("Confirm Availability")}
            </Button>
          </Box>
        </Box>
      )}

      {currentStep === "select" && (
        <Box>
          <Typography variant="h6" gutterBottom marginBottom={2}>
            {t("Select your school:")}
          </Typography>
          {filteredSchools.length > 0 ? (
            <Grid
              container
              spacing={2}
              maxHeight={500}
              sx={{ overflowY: "auto" }}
            >
              {filteredSchools.map((school) => (
                <Grid item xs={12} key={school.uid}>
                  <SchoolCard
                    variant="outlined"
                    key={school.uid}
                    school={school}
                    onSelect={() => handleSelectSchool(school.uid)}
                    selected={selectedSchoolId === school.uid}
                    distance={
                      schoolDistances[school.uid]
                        ? `${schoolDistances[school.uid].toFixed(1)} miles`
                        : undefined
                    }
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Empty
              description="Looks like no schools match your schedule right now, but don’t worry! We can alert you when new options open up."
              cta={{
                label: "Notify the Team",
                onClick: handleNotifyTeam,
              }}
              secondaryCta={{
                label: "Change Availability",
                onClick: () => setCurrentStep("filter"),
              }}
            />
          )}
        </Box>
      )}

      {currentStep === "review" && selectedSchool && (
        <Box>
          <ConfirmSchoolSelection
            selectedSchool={selectedSchool}
            onConfirm={handleConfirmReview}
            onBackButton={() => setCurrentStep("select")}
          />
        </Box>
      )}
    </FormControl>
  );
}
