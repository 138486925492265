import {
  AnalyticsOutlined,
  BusinessOutlined,
  CasesOutlined,
  GroupWorkOutlined,
  LogoutOutlined,
  PeopleOutlineOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery as measureScreenWidth,
  useTheme,
} from "@mui/material";
import { common } from "@mui/material/colors";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { ReactComponent as Logo } from "src/assets/Logo.svg";
import LogoIcon from "src/assets/LogoIcon.svg";
import { belowLargeTablet } from "src/components/responsiveHelpers";
import { useAuth } from "src/contexts/AuthContext";
import useIsAdmin from "src/utils/useIsAdmin";
import { LIB_VERSION } from "src/version";
import {
  AdminAccountDetails,
  IconLink,
  MenuTab,
  OrganizationStaffAccountDetails,
} from "./Menus";

const DRAWER_WIDTH = 240;

type LayoutProps = {
  currentTab: MenuTab;
  children: React.ReactNode;
};

function LogoContainer() {
  return (
    <Box>
      <Logo width={200} height={100} />
      <Typography variant="body2" color="text.secondary" textAlign="center">
        v{LIB_VERSION}
      </Typography>
    </Box>
  );
}
// TODO: fix this
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function MobileSmallTabletLayout({ currentTab, children }: LayoutProps) {
  const theme = useTheme();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const menuChildren = <LogoContainer />;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: 1, width: 1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: 1,
          minHeight: theme.spacing(8),
          px: 2,
          backgroundColor: common.white,
        }}
      >
        <IconButton
          sx={{
            color: "text.primary",
          }}
          size="large"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <MenuIcon />
        </IconButton>
        {/* <Logo /> */}
      </Box>
      {menuOpen && (
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: 1,
              width: 1,
              backgroundColor: theme.palette.text.secondary,
              zIndex: 1101,
            }}
            onClick={() => setMenuOpen(false)}
          />
          <Paper
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: 1,
              borderRadius: `0 ${theme.spacing(2)} ${theme.spacing(2)} 0`,
              display: "flex",
              minWidth: DRAWER_WIDTH,
              flexDirection: "column",
              maxHeight: "100%",
              overflow: "auto",
              zIndex: "modal",
            }}
          >
            {menuChildren}
          </Paper>
        </>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: 1,
          padding: 2,
          paddingTop: 2,
          marginLeft: 0,
          maxWidth: "100%",
        }}
      >
        {children || <Outlet />}
      </Box>
    </Box>
  );
}

function LargeTabletLaptopUpLayout({ currentTab, children }: LayoutProps) {
  const { logout } = useAuth();
  const theme = useTheme();
  const isAdmin = useIsAdmin();
  const { t } = useTranslation();
  const menuChildren = (
    <Box
      mt={2}
      mb={5}
      display="flex"
      flexDirection="column"
      sx={{ height: "100%" }}
    >
      <Stack
        spacing={theme.spacing(2)}
        my={theme.spacing(3)}
        paddingX={theme.spacing(2)}
      >
        <Box display="flex" justifyContent="center">
          {isAdmin ? (
            <AdminAccountDetails />
          ) : (
            <OrganizationStaffAccountDetails />
          )}
        </Box>
        <Divider />

        <Stack spacing={theme.spacing(2)} direction="column" mx={3}>
          <IconLink
            current={currentTab === "clients"}
            to="/admin"
            icon={<PeopleOutlineOutlined />}
            tooltip={t("Students")}
          />
          <IconLink
            current={currentTab === "cohorts"}
            to="/admin/cohorts"
            icon={<AnalyticsOutlined />}
            tooltip={t("Cohorts")}
          />
          {isAdmin && (
            <>
              <IconLink
                current={currentTab === "caseload"}
                to="/admin/caseload"
                icon={<CasesOutlined />}
                tooltip={t("My Caseload")}
              />
              <IconLink
                current={currentTab === "superadmin"}
                to="/admin/super-admin"
                icon={<SettingsOutlined />}
                tooltip={t("Super Admin Settings")}
              />
              <IconLink
                current={currentTab === "partners"}
                to="/admin/partners"
                icon={<GroupWorkOutlined />}
                tooltip={t("Partners")}
              />
            </>
          )}
          {!isAdmin && (
            <IconLink
              current={currentTab === "team"}
              to="/admin/team"
              icon={<BusinessOutlined />}
              tooltip={t("Team")}
            />
          )}
        </Stack>
        <Divider />

        <IconLink
          to=""
          onClick={logout}
          icon={<LogoutOutlined />}
          tooltip={t("Logout")}
        />
      </Stack>
      <Box marginTop="auto" mx={3}>
        <img src={LogoIcon} alt="Logo icon" />
      </Box>
    </Box>
  );
  return (
    <Box sx={{ display: "flex", height: 1, maxWidth: "100vw" }}>
      <Paper
        sx={{
          borderRadius: 4,
          minWidth: 90,
          maxWidth: 60,
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          maxHeight: "100vh",
          overflow: "auto",
        }}
      >
        {menuChildren}
      </Paper>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          maxWidth: "100%",
        }}
      >
        {children || <Outlet />}
      </Box>
    </Box>
  );
}

// function LargeTabletLaptopUpLayout({ currentTab, children }: LayoutProps) {
//   const { t } = useTranslation();
//   const { logout } = useAuth();
//   const theme = useTheme();
//   const isAdmin = useIsAdmin();
//   const menuChildren = (
//     <Box
//       mt={2}
//       mb={5}
//       display="flex"
//       flexDirection="column"
//       sx={{ height: "100%" }}
//     >
//       <Stack
//         spacing={theme.spacing(2)}
//         my={theme.spacing(3)}
//         paddingX={theme.spacing(2)}
//       >
//         <Box mx={3}>
//           {isAdmin ? (
//             <AdminAccountDetails />
//           ) : (
//             <OrganizationStaffAccountDetails />
//           )}
//         </Box>
//         <Divider />

//         <Stack spacing={theme.spacing(2)} direction="column" mx={3}>
//           <MenuLink
//             current={currentTab === "clients"}
//             to="/admin"
//             icon={<PeopleOutlineOutlined />}
//             label={t("Clients")}
//           />
//           <MenuLink
//             current={currentTab === "cohorts"}
//             to="/admin/cohorts"
//             icon={<AnalyticsOutlined />}
//             label={t("Cohorts")}
//           />
//           {isAdmin && (
//             <MenuLink
//               current={currentTab === "superadmin"}
//               to="/admin/super-admin"
//               icon={<SettingsOutlined />}
//               label={t("Super Admin")}
//             />
//           )}

//           {!isAdmin && (
//             <MenuLink
//               current={currentTab === "team"}
//               to="/admin/team"
//               icon={<BusinessOutlined />}
//               label={t("Team")}
//             />
//           )}
//         </Stack>
//         <Divider />

//         <MenuLink
//           to=""
//           onClick={logout}
//           icon={<LogoutOutlined />}
//           label={t("Log out")}
//         />
//       </Stack>
//       <Box marginTop="auto" mx={3}>
//         <LogoContainer />
//       </Box>
//     </Box>
//   );
//   return (
//     <Box sx={{ display: "flex", height: 1, maxWidth: "100vw" }}>
//       <Paper
//         sx={{
//           borderRadius: 4,
//           minWidth: DRAWER_WIDTH,
//           maxWidth: DRAWER_WIDTH,
//           display: "flex",
//           flexDirection: "column",
//           height: "100vh",
//           maxHeight: "100vh",
//           overflow: "auto",
//         }}
//       >
//         {menuChildren}
//       </Paper>
//       <Box
//         component="main"
//         sx={{
//           flexGrow: 1,
//           maxWidth: "100%",
//         }}
//       >
//         {children || <Outlet />}
//       </Box>
//     </Box>
//   );
// }

export default function MenuLayout({
  currentTab,
  children,
}: {
  currentTab: MenuTab;
  children?: React.ReactNode;
}) {
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);

  if (isMobileOrSmallTablet) {
    return (
      <MobileSmallTabletLayout currentTab={currentTab}>
        {children}
      </MobileSmallTabletLayout>
    );
  }

  return (
    <LargeTabletLaptopUpLayout currentTab={currentTab}>
      {children}
    </LargeTabletLaptopUpLayout>
  );
}
