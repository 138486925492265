import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LocationCard from "src/components/Card/LocationCard";
import MapWithZipcodeMarkers from "src/components/MapWithZipcodeMarkers";
import { useAppContext } from "src/contexts/AppContext";
import fetchSchool from "src/firebase/fetchSchool";
import AppSkeleton from "src/pages/AppSkeleton";
import LoadingTransitionScreen from "src/pages/BackgroundSurveyScreen/LoadingTransitionScreen";
import { School } from "src/types/School";
import { UserAccount } from "src/types/User";
import getDistanceBetweenZips from "src/utils/getDistanceBetweenZips";
import useErrorHandler from "src/utils/useErrorHandler";
import { WEEKDAY_MAP } from "./DrivingScheduleSelection";

export type StipendInformation = {
  startPointZipcode: string;
  distanceMiles: number;
  dailyStipendEstimateCents: number;
};

type Props = {
  user: UserAccount;
  trainingDays: number[];
  onComplete: (stipendInfo: StipendInformation) => void;
};

export default function StipendSetupForm({
  user,
  trainingDays,
  onComplete,
}: Props) {
  const { t } = useTranslation();

  const [distanceMiles, setdistanceMiles] = useState<number | null>(null);
  const [dailyStipendCents, setDailyStipendCents] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [school, setSchool] = useState<School>();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  const startingPointZipcode =
    user.eligibilityData?.zipcode || user.application?.zipcode;

  if (!startingPointZipcode)
    throw new Error("Please contact your coach to provide your zipcode.");
  if (!user.schoolId)
    throw new Error("You must pick a school before you move forward.");

  useEffect(() => {
    fetchSchool(user.schoolId!, clients).then(setSchool).catch(errorHandler);
  }, [user.schoolId, clients, errorHandler]);

  const AVERAGE_GALLON_PRICE_CENTS = 331;
  const AVERAGE_MILES_PER_GALLON = 24.4;

  const handleCalculate = useCallback(async () => {
    if (!school) return;
    setLoading(true);
    try {
      // Example function that returns distance in kilometers
      const { distanceInMiles } = await getDistanceBetweenZips(
        startingPointZipcode,
        school.zipcode
      );

      setdistanceMiles(distanceInMiles);

      const dailyStipendCents =
        ((distanceInMiles * 2) / AVERAGE_MILES_PER_GALLON) *
        AVERAGE_GALLON_PRICE_CENTS;

      // max stipend we can allocate is 20 dollars
      const actualStipendCents = Math.min(dailyStipendCents, 20 * 100);

      setDailyStipendCents(actualStipendCents);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [school, startingPointZipcode, errorHandler]);

  useEffect(() => {
    handleCalculate();
  }, [handleCalculate]);

  const handleSubmit = () => {
    if (distanceMiles !== null) {
      onComplete({
        startPointZipcode: startingPointZipcode,
        distanceMiles,
        dailyStipendEstimateCents: dailyStipendCents,
      });
    }
  };

  if (!school) return <AppSkeleton />;

  if (loading)
    return (
      <Card>
        <CardContent>
          <LoadingTransitionScreen
            messages={[
              t("We're compiling all the information you provided!"),
              t("This won't take long!"),
              t("Please wait a moment..."),
            ]}
          />
        </CardContent>
      </Card>
    );

  return (
    <Box>
      <Card>
        <CardMedia>
          <MapWithZipcodeMarkers
            user={user}
            includeSelfLocation
            locations={[
              {
                zipcode: school.zipcode,
                label: school.name,
              },
            ]}
          />
        </CardMedia>
        <CardContent>
          <Box marginY={2}>
            <Typography variant="body2" color="textSecondary">
              {t("Your Training Days:")}
            </Typography>
            <Typography>
              {trainingDays.map((d) => WEEKDAY_MAP[d]).join(", ")}
            </Typography>
          </Box>

          <LocationCard
            variant="outlined"
            startLocation={startingPointZipcode}
            destination={school.name}
            destinationLabel={t("Your School")}
            distance={distanceMiles || 0}
            dailyStipend={dailyStipendCents / 100}
            trainingDays={trainingDays}
          />
          <Alert color="info" sx={{ marginTop: 2 }}>
            <AlertTitle>
              {t("Get Reimbursed—Don’t Forget to Log Your Attendance!")}
            </AlertTitle>
            {t(
              "To receive your reimbursement, you must log your attendance daily in the app."
            )}
          </Alert>
        </CardContent>
      </Card>

      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          disabled={distanceMiles === null}
          onClick={handleSubmit}
        >
          {t("Sounds Good")}
        </Button>
      </Box>
    </Box>
  );
}
