import { useLazyQuery, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbarContext } from "src/SnackbarProvider";
import pickRandom from "src/utils/pickRandom";
import useErrorHandler from "src/utils/useErrorHandler";
import { GetResourcesByUnitDocument } from "../UnitPage/GetResourcesByUnit.generated";
import { GetUnitDocument } from "../UnitPage/GetUnit.generated";
import { GetResourceQuery } from "./GetResource.generated";
import { nextLessonToastMessages, unitCompletionMessages } from "./toastCopy";

export default function useResourceNavigation(
  resource: NonNullable<GetResourceQuery["resource"]>,
  redirectToDashboardTutorial?: boolean
) {
  const [getUnit] = useLazyQuery(GetUnitDocument);
  const errorHandler = useErrorHandler();
  const navigate = useNavigate();
  const snackbarContext = useSnackbarContext();

  const { data: allUnitResources } = useQuery(GetResourcesByUnitDocument, {
    onError: errorHandler,
    variables: { unitId: resource.unit.id },
  });

  const resources = useMemo(
    () => allUnitResources?.allResources || [],
    [allUnitResources]
  );

  const navigateForwardPath = useCallback(async () => {
    if (!resources) return null;

    if (redirectToDashboardTutorial) return "/?showTutorial=true";

    const nextResource = resources.find(
      (res) => res.order === resource.order + 1
    );

    if (nextResource) {
      snackbarContext.alert("success", pickRandom(nextLessonToastMessages));

      return `/resources/${nextResource.id}`;
    }

    // Fetch the next unit if there's no next resource in the current unit
    const { data: nextUnit } = await getUnit({
      variables: { order: resource.unit.order + 1 },
    });

    if (!nextUnit || !nextUnit.unit) return null;

    const firstUnitResource =
      nextUnit?.unit?.resources?.length > 0
        ? nextUnit.unit.resources.reduce(
            (lowest, current) =>
              !lowest || current.order < lowest.order ? current : lowest,
            nextUnit.unit.resources[0]
          )
        : null;

    if (firstUnitResource) {
      snackbarContext.alert("success", pickRandom(unitCompletionMessages));
      return `/resources/${firstUnitResource.id}`;
    }

    return "/";
  }, [
    resource,
    resources,
    getUnit,
    redirectToDashboardTutorial,
    snackbarContext,
  ]);

  const navigateBackPath = useCallback(async () => {
    if (!resources) return null;

    const previousResource = resources.find(
      (res) => res.order === resource.order - 1
    );

    if (previousResource) return `/resources/${previousResource.id}`;

    // Fetch the previous unit if there's no previous resource in the current unit
    const { data: previousUnit } = await getUnit({
      variables: { order: resource.unit.order - 1 },
    });

    const lastUnitResource =
      previousUnit?.unit?.resources && previousUnit?.unit?.resources?.length > 0
        ? previousUnit.unit.resources.reduce(
            (highest, current) =>
              !highest || current.order > highest.order ? current : highest,
            previousUnit.unit.resources[0]
          )
        : null;

    return lastUnitResource ? `/resources/${lastUnitResource.id}` : "/";
  }, [resource, resources, getUnit]);

  const navigateForward = useCallback(async () => {
    const path = await navigateForwardPath();
    if (path) navigate(path);
    else navigate("/");
  }, [navigateForwardPath, navigate]);

  const navigateBack = useCallback(async () => {
    const path = await navigateBackPath();
    if (path) navigate(path);
    else navigate("/");
  }, [navigateBackPath, navigate]);

  const totalResources = useMemo(() => resources.length, [resources]);

  return {
    navigateForward,
    navigateBack,
    totalResources,
  };
}
