import { Timestamp } from "firebase/firestore";
import { CreateUserInput } from "src/contexts/AuthContext";
import { BackgroundFormData } from "src/pages/BackgroundSurveyScreen/BackgroundSurveyView";
import { SkillsTrainingAvailabilitySchedule } from "src/pages/DashboardPage/RangeTab/DrivingScheduleSelection";
import { StipendInformation } from "src/pages/DashboardPage/RangeTab/StipendSetupForm";
import { PostalAddress } from "src/pages/OnboardingScreen/EligibilityScreen/EligibilityPostalAddressForm";
import { StudyPlanData } from "src/pages/OnboardingScreen/generateStudyPlan";
import { JobPreferenceFormData } from "src/pages/OnboardingScreen/JobPreferenceForm";
import TransportationSituation from "src/utils/TransportationSituation";
import { BackgroundCheckReport } from "./BackgroundCheck";
import { TrainingStage } from "./Organization";
import USState from "./states";
import {
  ApplicationStatus,
  DeprecatedApplicationStatus,
  EmploymentStatus,
  EnrollmentStatus,
  IncarcerationStatus,
} from "./UserStatus";

export enum UserOnboardingStatus {
  CREATED_ACCOUNT = "created_account",
  APPLIED = "applied",
  PRE_ADMISSION = "pre_admission",
  ENROLLED = "enrolled",
  COMPLETED_THEORY = "theory_completed",
  RANGE = "range",
  GRADUATED = "graduated",
  LICENSED = "licensed",
  EMPLOYED = "employed",
  DROPPED = "dropped",
  GLOBAL_EXIT = "global_exit",
  REJECTED = "rejected",
  UNSUCCESSFUL_EXIT = "unsuccessful_exit",
}

export type UserEligibilityData = {
  legalFirstName: string;
  legalLastName: string;
  legalMiddleName: string;
  ssn: string;
  zipcode: string;
  driverLicenseNumber: string;
  driverLicenseState: USState;
  driverLicenseIssueDate: string;
  driverLicenseExpirationDate: string;
  hasPreviousDriverLicense: boolean;
  previousDriverLicenseNumber?: string;
  previousDriverLicenseState?: USState;
  postalAddress: PostalAddress;
};

// TODO: nix this
export type UserTrainingData = {
  schoolId?: string;
  onboardingStatus: UserOnboardingStatus;
  // whether the user already has a DOL account
  dolId?: string;
  // the ID of the workforce board staff member assigned to the user
  careerNavigatorId?: string;
  // the ID of the Emerge admin assigned to the user
  emergeCoachId?: string;
  organizationId?: string;
  cohortId?: string;
};
export type UserSkillsTrainingDraftPlan = {
  skillsTrainingSchedule: string;
  projectedDurationWeeks: number;
};

// happens when users hit the driving yard
export type UserSkillsTrainingFullPlan = UserSkillsTrainingDraftPlan & {
  type: "full"; // for type narrowing
  // check weekday_map
  availableDays: SkillsTrainingAvailabilitySchedule;
  transportationSituation: keyof typeof TransportationSituation;
} & StipendInformation;

export type UserSkillsTrainingPlan =
  | UserSkillsTrainingDraftPlan
  | UserSkillsTrainingFullPlan;

export type UserSkillsCoachChecklist = {
  hasIssuedTheoryCertification?: boolean;
  hasSubmittedSchoolEnrollment?: boolean;
  hasPaidSchool?: boolean;
  hasSubmittedFundingAgencyEnrollment?: boolean;
};

export type UserAccount = {
  uid: string;

  // NEW status fields
  incarcerationStatus?: keyof typeof IncarcerationStatus;
  incarcerationStatusDetails?: string;
  incarcerationStatusLastUpdatedAt?: string;
  applicationStatus?:
    | keyof typeof ApplicationStatus
    | keyof typeof DeprecatedApplicationStatus;
  applicationStatusDetails?: string;
  applicationStatusLastUpdatedAt?: string;
  enrollmentStatus?: keyof typeof EnrollmentStatus;
  enrollmentStatusDetails?: string;
  enrollmentStatusLastUpdatedAt?: string;
  employmentStatus?: keyof typeof EmploymentStatus;
  employmentStatusDetails?: string;
  employmentStatusLastUpdatedAt?: string;

  resources: { [resourceId: string]: UserResourceProgress };
  lastLoggedIn?: string;
  createdAt: string;
  appVersion?: string;
  // video ask profile
  videoAskRespondentId?: string;

  // background check
  backgroundCheckReport?: BackgroundCheckReport;

  // application form
  application?: BackgroundFormData;

  // success plan onboarding
  jobPreference?: JobPreferenceFormData;
  theoryTrainingPlan?: StudyPlanData;
  skillsTrainingPlan?: UserSkillsTrainingPlan;
  skillsCoachChecklist?: UserSkillsCoachChecklist;

  // orientation videos
  hasCompletedOrientationLesson?: boolean;

  // partnerId
  referredByPartnerId?: string;

  // is only created when the user approaches enrollment and we need to run the eligibility check
  // and income verification
  eligibilityData?: UserEligibilityData;

  // only exists when the user is on skills training
  latestAttendanceDate?: string;
  latestAttendanceDateFirestoreTimestamp?: Timestamp;
} & UserTrainingData &
  CreateUserInput;

export type UserResourceProgress = {
  userId: string;
  resourceId: string;
  progressSeconds: number;
  progressFraction: number;
  hasCompleted?: boolean;
};

export type UserUploadedDocument = {
  uid: string;
  name: string;
  path: string;
  userId: string;
  stage?: TrainingStage;
  verifiedAt?: string | null;
  verifiedAtFirestoreTimestamp?: Timestamp | null;
  organizationDocumentId?: string; // Only if the source is an organization, note the edge case in case the person gets moved from one organization to another
  // These fields were only included on the Feb 10, 2025 release
  createdAt?: string;
  createdAtFirestoreTimestamp?: Timestamp;
};

export enum UserType {
  User = "user",
  OrganizationStaff = "organizationStaff",
  Admin = "admin",
  Partner = "partner",
}
