import {
  AccessTime,
  AddTwoTone,
  ArrowDownward,
  ArrowUpward,
  CalendarMonth,
  CalendarToday,
  DeleteOutline,
  MonetizationOn,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  addDays,
  differenceInDays,
  getDay,
  isAfter,
  isBefore,
  isEqual,
  startOfWeek,
} from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import ActionButtonWithConfirmDialog from "src/components/Button/ActionButtonWithConfirmDialog";
import Empty from "src/components/Empty";
import { Header32 } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import { useSession } from "src/contexts/AuthContext";
import createAttendance from "src/firebase/createAttendance";
import fetchStorageBlob from "src/firebase/fetchStorageBlob";
import AppSkeleton from "src/pages/AppSkeleton";
import { useSnackbarContext } from "src/SnackbarProvider";
import { Attendance } from "src/types/Attendance";
import { UserAccount } from "src/types/User";
import displayLocalDate from "src/utils/displayLocalDate";
import featureEnabled from "src/utils/featureEnabled";
import isFullSkillsTrainingPlanGuard from "src/utils/isFullSkillsTrainingPlanGuard";
import sortBy from "src/utils/sortBy";
import useErrorHandler from "src/utils/useErrorHandler";
import useIsAdmin from "src/utils/useIsAdmin";
import { WEEKDAY_MAP } from "./DrivingScheduleSelection";
import RangeAttendanceForm, { AttendanceFormData } from "./RangeAttendanceForm";

type Props = {
  user: UserAccount;
  /**
   * An array of days of the week (0 = Sunday, 1 = Monday, etc.)
   * that are considered "training days."
   */
};

export default function SkillsTrainingDashboard({ user }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const session = useSession();
  const errorHandler = useErrorHandler();
  const snackbarContext = useSnackbarContext();
  const isAdmin = useIsAdmin();
  const { clients } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const [attendancesWithPhoto, setAttendanceWithPhoto] =
    useState<(Attendance & { file?: File })[]>();
  const [showTrainingActivity, setShowTrainingActivity] = useState(false);

  // --- FETCH ATTENDANCE ON MOUNT ---
  useEffect(() => {
    session
      .fetchAttendanceRecords(user.uid)
      .then(async (records) => {
        const processed = await Promise.all(
          records.map(async (r) => ({
            ...r,
            file: r.path
              ? await fetchStorageBlob({ path: r.path }, clients)
              : undefined,
          }))
        );
        setAttendanceWithPhoto(processed);
      })
      .catch(errorHandler);
  }, [session, errorHandler, user, clients]);

  // --- HANDLER: SUBMIT ATTENDANCE ---
  const handleSubmission = useCallback(
    async (data: AttendanceFormData) => {
      await createAttendance({ user, ...data }, clients)
        .then((res) => {
          snackbarContext.alert(
            "success",
            t("We added your attendance record!")
          );
          setIsOpen(false);
          setAttendanceWithPhoto((prev) => [
            { ...res, file: data.photo || undefined },
            ...(prev || []),
          ]);
        })
        .catch(errorHandler);
    },
    [user, clients, errorHandler, snackbarContext, t]
  );

  // --- HANDLER: DELETE ATTENDANCE ---
  const handleDeletion = async (attendanceId: string) => {
    snackbarContext.alert("info", t("Deleting record..."));
    if (!user) {
      throw new Error("Only admins can delete an attendance record");
    }
    await session.deleteAttendance(user.uid, attendanceId);
    setAttendanceWithPhoto((prev) =>
      prev?.filter((att) => att.uid !== attendanceId)
    );
  };

  // --- LOADING STATE ---
  if (!attendancesWithPhoto) {
    return <AppSkeleton />;
  }

  // --- Check if today is a training day ---
  const today = new Date();
  const todayWeekday = getDay(today); // 0 = Sunday, 1 = Monday, ...
  const trainingDays = isFullSkillsTrainingPlanGuard(user.skillsTrainingPlan)
    ? user.skillsTrainingPlan.availableDays
    : undefined;
  const isTrainingDay =
    trainingDays &&
    Object.keys(trainingDays).map(Number).includes(todayWeekday);

  // --- FILTER & SORT ATTENDANCE ---
  const sorted = sortBy(
    attendancesWithPhoto,
    (record) => new Date(record.date).getTime(),
    "desc"
  );

  // 1) Show only the last 20 days
  const now = new Date();

  // 2) Calculate stats:
  //    A) Days attended this week (starting last Sunday)
  const lastSunday = startOfWeek(now, { weekStartsOn: 0 }); // Sunday as start of week
  const daysAttendedThisWeek = attendancesWithPhoto.filter((rec) => {
    const recDate = new Date(rec.date);
    const nextSunday = addDays(lastSunday, 7);
    return (
      (isAfter(recDate, lastSunday) || isEqual(recDate, lastSunday)) &&
      isBefore(recDate, nextSunday)
    );
  }).length;

  //    B) Total hours of attendance
  const totalHours = attendancesWithPhoto.reduce(
    (sum, rec) => sum + (rec.duration || 0),
    0
  );
  //    C) Reimbursement (simple example: $15/hour)

  const totalReimbursementCents = isFullSkillsTrainingPlanGuard(
    user.skillsTrainingPlan
  )
    ? daysAttendedThisWeek * user.skillsTrainingPlan.dailyStipendEstimateCents
    : 0;

  //    D) Hours remaining out of 160
  const HOURS_TARGET = 120;
  const hoursLeft =
    HOURS_TARGET - totalHours > 0 ? HOURS_TARGET - totalHours : 0;

  return (
    <Stack spacing={theme.spacing(2)}>
      {/* --- TRAINING DAY CHECK-IN CARD --- */}
      <Card sx={(theme) => ({ backgroundColor: theme.palette.primary.dark })}>
        <CardHeader
          title={
            isTrainingDay
              ? t("Today is a Training Day!")
              : t("Have you trained recently?")
          }
          sx={{ color: theme.palette.primary.contrastText }}
        />
        <CardContent>
          {trainingDays && (
            <Box marginY={2}>
              <Typography variant="body2" color="white">
                {t("Your Training Days:")}
              </Typography>
              <Typography color="white" fontWeight="bold">
                {Object.keys(trainingDays)
                  .map((d) => WEEKDAY_MAP[Number(d)])
                  .join(", ")}
              </Typography>
            </Box>
          )}
          <Button
            variant="outlined"
            size="large"
            onClick={() => setIsOpen(true)}
            sx={{ backgroundColor: "white" }}
            startIcon={<AddTwoTone />}
          >
            {t("Add Attendance")}
          </Button>
        </CardContent>
      </Card>
      {/* --- ALL STATISTICS IN ONE CARD --- */}
      <Card>
        <CardHeader title={t("Your Journey So Far")} />
        <CardContent>
          <Grid container spacing={2}>
            {/* Days Attended This Week */}
            <Grid item xs={6} md={3}>
              <Stack alignItems="center">
                <CalendarToday sx={{ fontSize: 40 }} />
                <Header32>{daysAttendedThisWeek}</Header32>
                <Typography variant="body2" textAlign="center">
                  {t("7-D Attendance")}
                </Typography>
              </Stack>
            </Grid>

            {/* Total Reimbursement */}
            {featureEnabled("SKILLS_TRAINING_REVAMP") && (
              <Grid item xs={6} md={3}>
                <Stack alignItems="center">
                  <MonetizationOn sx={{ fontSize: 40 }} />
                  <Header32>
                    {`$${(totalReimbursementCents / 100).toFixed(2)}`}
                  </Header32>
                  <Typography variant="body2" textAlign="center">
                    {t("This Week's Reimbursement")}
                  </Typography>
                </Stack>
              </Grid>
            )}

            {/* Total Sessions Attended */}
            <Grid item xs={6} md={3}>
              <Stack alignItems="center">
                <CalendarMonth sx={{ fontSize: 40 }} />
                <Header32>{sorted.length}</Header32>
                <Typography variant="body2">{t("Total Attendance")}</Typography>
              </Stack>
            </Grid>

            {/* Hours Left */}
            <Grid item xs={6} md={3}>
              <Stack alignItems="center">
                <AccessTime sx={{ fontSize: 40 }} />
                {hoursLeft > 0 ? (
                  <Header32>{hoursLeft}</Header32>
                ) : (
                  <Header32 color="success">Done</Header32>
                )}
                <Typography variant="body2" textAlign="center">
                  {t("Estimated Training Hours Left")}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Button
              variant="text"
              onClick={() => setShowTrainingActivity((prev) => !prev)}
              startIcon={
                showTrainingActivity ? <ArrowUpward /> : <ArrowDownward />
              }
            >
              {showTrainingActivity
                ? t("Hide Training Activity")
                : t("View All Training Activity")}
            </Button>
          </Box>
          {/* --- ATTENDANCE CARDS (LAST 20 DAYS) --- */}
          <Collapse in={showTrainingActivity}>
            <Card variant="outlined">
              <CardHeader title={t("Your Training Activity")} />
              <CardContent>
                {sorted.length === 0 && <Empty />}

                <Stack
                  direction={{
                    md: "row",
                    xs: "column",
                  }}
                  spacing={2}
                  flexWrap="wrap"
                >
                  {sorted.map((record) => (
                    <Card variant="outlined" key={record.uid}>
                      {record.file && (
                        <CardMedia
                          component="img"
                          src={URL.createObjectURL(record.file)}
                          height={100}
                          sx={{
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                        />
                      )}
                      <CardHeader
                        title={displayLocalDate(record.date)}
                        subheader={
                          record.duration
                            ? t("{{duration}} hours", {
                                duration: record.duration,
                              })
                            : undefined
                        }
                        action={
                          (isAdmin ||
                            differenceInDays(
                              new Date(),
                              new Date(record.createdAt)
                            ) < 7) && (
                            <ActionButtonWithConfirmDialog
                              onAction={() => handleDeletion(record.uid)}
                              buttonLabel={t("Delete")}
                              title={t("Delete this record?")}
                              buttonColor="error"
                              startIcon={<DeleteOutline />}
                            />
                          )
                        }
                      />
                    </Card>
                  ))}
                </Stack>
              </CardContent>
            </Card>
          </Collapse>
        </CardContent>
      </Card>
      {/* --- MODAL FOR ADDING ATTENDANCE --- */}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullScreen={isMobileOnly}
      >
        <DialogContent>
          <Box
            height="100%"
            margin="auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              width="100%"
              sx={{
                width: { md: 500, xs: "100%" },
                height: { md: 700, xs: "100%" },
              }}
            >
              <RangeAttendanceForm handleSubmission={handleSubmission} />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
