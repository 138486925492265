import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { useMemo } from "react";
import { UserAccount } from "src/types/User";
import { CLP_ID } from "src/utils/constants";
import groupBy from "src/utils/groupBy";
import useCompleteQuizGrades from "src/utils/useCompleteQuizGrades";
import useErrorHandler from "src/utils/useErrorHandler";
import AppSkeleton from "../AppSkeleton";
import ExamCategoryCard from "./CourseTab/ExamCategoryCard";
import { GetMockExamsDocument } from "./GetMockExams.generated";

interface Props {
  courseId: string;
  user: UserAccount;
}

export default function ExamsSection({ courseId, user }: Props) {
  const errorHandler = useErrorHandler();

  //  load all exams that are available for the course
  const { data: allExams, loading } = useQuery(GetMockExamsDocument, {
    variables: { courseId },
    onError: errorHandler,
  });

  const { completeReport } = useCompleteQuizGrades(user.uid);

  const examsWithProgress = useMemo(() => {
    if (!completeReport || !allExams) return [];

    return allExams.allQuizzes
      .filter(
        (exam) =>
          courseId !== CLP_ID ||
          // this is harcoded for the the CLP exams
          exam.category === "general_knowledge" ||
          exam.category === "air_brakes" ||
          exam.category === "combination_vehicles"
      )
      .sort((a, b) => {
        // sort by required first
        if (a.isRequired && !b.isRequired) return -1;
        if (!a.isRequired && b.isRequired) return 1;

        if (a.isRequired && b.isRequired) {
          return a.name.localeCompare(b.name);
        }
        // if not required, sort by length
        return a.questions.length - b.questions.length;
      })
      .map((exam) => {
        const existingGrade = completeReport.completeQuizGrades.find(
          (grade) => grade.id === exam.id
        );

        return {
          ...exam,
          grade: existingGrade?.grade,
        };
      });
  }, [allExams, completeReport, courseId]);

  const groupedExams = groupBy(examsWithProgress, (q) => q.category);

  if (loading) {
    return <AppSkeleton />;
  }

  return (
    <Grid container spacing={2} justifyContent="space-between">
      {Object.entries(groupedExams).map(([category, exams]) => (
        <Grid item key={category} spacing={1} xs={12} md={4}>
          <ExamCategoryCard key={category} category={category} exams={exams} />
        </Grid>
      ))}
    </Grid>
  );
}
