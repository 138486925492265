import { Alert, AlertTitle, Box, Grid, Stack } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import SubmitButton from "src/components/Button/SubmitButton";
import YesNoToggleInput from "src/components/Input/YesNoToggleInput";

/**
 * Now includes two extra boolean fields:
 * - missingPayStubs?: boolean
 * - bigGaps?: boolean
 */
export type IncomeCalcWizardData = {
  hasSingleEmployer?: boolean; // Step 1
  payIsConsistent?: boolean; // Step 2
  hasYTDStub?: boolean; // Step 3
  missingPayStubs?: boolean; // Step 4
  bigGaps?: boolean; // Step 5
};

/**
 * The possible calculation methods
 */
export type CalculationMethod = "straight" | "average" | "ytd" | "intermittent";

type Props = {
  onConfirmMethod: (method: CalculationMethod) => void;
};

const CalculationMethodAlertDescription = ({
  method,
}: {
  method: CalculationMethod;
}) => {
  const { t } = useTranslation();

  switch (method) {
    case "straight":
      return (
        <Alert severity="info" sx={{ my: 2 }}>
          <AlertTitle>{t("We recommend the Straight Pay method!")}</AlertTitle>
          {t(
            "Your job has consistent pay, and you don't have a YTD stub. This means we'll just need a few of your most recent pay stubs, which we'll multiply to find your 6-month total."
          )}
        </Alert>
      );
    case "average":
      return (
        <Alert severity="info" sx={{ my: 2 }}>
          <AlertTitle>{t("We recommend the Average Pay method!")}</AlertTitle>
          {t(
            "Since your pay has varied from check to check, we'll collect multiple stubs from the last few months to find an average, which we'll then use for the 6-month period."
          )}
        </Alert>
      );
    case "ytd":
      return (
        <Alert severity="info" sx={{ my: 2 }}>
          <AlertTitle>{t("We recommend the Year-To-Date method!")}</AlertTitle>
          {t(
            "We can quickly calculate your 6-month income based on your YTD stub, so your total might be smaller and easier to document."
          )}
        </Alert>
      );
    case "intermittent":
    default:
      return (
        <Alert severity="info" sx={{ my: 2 }}>
          <AlertTitle>
            {t("We recommend the Intermittent Work method!")}
          </AlertTitle>
          {t(
            "It looks like you had multiple employers, missing stubs, or big gaps in pay. We'll collect any available stubs for each job, plus an explanation for any periods with no income, to accurately determine your total."
          )}
        </Alert>
      );
  }
};

export default function EligibilityIncomeMethodForm({
  onConfirmMethod,
}: Props) {
  const { t } = useTranslation();

  // Updated defaultValues with the two new fields
  const {
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<IncomeCalcWizardData>({
    mode: "onTouched",
    defaultValues: {
      hasSingleEmployer: undefined,
      payIsConsistent: undefined,
      hasYTDStub: undefined,
      missingPayStubs: undefined,
      bigGaps: undefined,
    },
  });

  // We'll have 5 steps now
  const [step, setStep] = useState<number>(1);

  // Once we have a final method, we set it here and show the Alert
  const [recommendedMethod, setRecommendedMethod] =
    useState<CalculationMethod | null>(null);

  // Watch values in real-time
  const hasSingleEmployer = watch("hasSingleEmployer");
  const payIsConsistent = watch("payIsConsistent");
  const hasYTDStub = watch("hasYTDStub");
  const missingPayStubs = watch("missingPayStubs");
  const bigGaps = watch("bigGaps");

  /**
   * The updated decision logic to pick a method, or null if we still need more info.
   */
  function computeMethod(data: IncomeCalcWizardData): CalculationMethod | null {
    const {
      hasSingleEmployer,
      payIsConsistent,
      hasYTDStub,
      missingPayStubs,
      bigGaps,
    } = data;

    // If any of the relevant answers is still undefined,
    // we can't finalize a decision yet.
    // But we check step by step.

    // Step 1: single employer? (Required for any next logic)
    if (typeof hasSingleEmployer === "undefined") {
      return null; // can't decide
    }
    // If multiple employers => Intermittent
    if (!hasSingleEmployer) {
      return "intermittent";
    }

    // Step 2: payIsConsistent? (If undefined, can't decide)
    if (typeof payIsConsistent === "undefined") {
      return null;
    }

    // Step 3: hasYTDStub?
    if (typeof hasYTDStub === "undefined") {
      return null;
    }

    // Step 4: missingPayStubs?
    if (typeof missingPayStubs === "undefined") {
      return null;
    }
    // If missing stubs => Intermittent
    if (missingPayStubs) {
      return "intermittent";
    }

    // Step 5: bigGaps?
    if (typeof bigGaps === "undefined") {
      return null;
    }
    // If big gaps => Intermittent
    if (bigGaps) {
      return "intermittent";
    }

    // If we reach here, we have single employer, no missing stubs, no big gaps
    // Then it's down to consistent or variable + YTD
    if (payIsConsistent) {
      // consistent
      return hasYTDStub ? "ytd" : "straight";
    }
    // variable
    return hasYTDStub ? "ytd" : "average";
  }

  // Each time the user submits, we see if we can pick a method
  function handleStep() {
    const method = computeMethod({
      hasSingleEmployer,
      payIsConsistent,
      hasYTDStub,
      missingPayStubs,
      bigGaps,
    });
    if (method) {
      setRecommendedMethod(method);
    } else {
      // Move to the next step until all are answered
      setStep((prev) => prev + 1);
    }
  }

  // Once user clicks "Continue" on the recommendation
  const handleConfirm = () => {
    if (recommendedMethod) {
      onConfirmMethod(recommendedMethod);
    }
  };

  return (
    <Box>
      {/* If there's a recommendation, show it and let user confirm */}
      {recommendedMethod && (
        <Stack spacing={2}>
          <CalculationMethodAlertDescription method={recommendedMethod} />
          <Grid container justifyContent="flex-start">
            <SubmitButton loading={false} onClick={handleConfirm}>
              {t("Sounds Good!")}
            </SubmitButton>
          </Grid>
        </Stack>
      )}

      {/* If not decided yet, show the step-based questions */}
      {!recommendedMethod && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleStep();
          }}
        >
          <Stack spacing={4} marginBottom={6}>
            {/* STEP 1: Single vs multiple employers */}
            {step === 1 && (
              <YesNoToggleInput
                name="hasSingleEmployer"
                prompt={t(
                  "Have you worked for ONLY one employer in the past 6 months?"
                )}
                control={control}
              />
            )}

            {/* STEP 2: Pay consistent? */}
            {step === 2 && (
              <YesNoToggleInput
                name="payIsConsistent"
                prompt={t("Has each paycheck been about the same amount?")}
                control={control}
              />
            )}

            {/* STEP 3: YTD stub? */}
            {step === 3 && (
              <YesNoToggleInput
                name="hasYTDStub"
                prompt={t(
                  "Do you have a recent pay stub showing YTD earnings?"
                )}
                control={control}
              />
            )}

            {/* STEP 4: Missing any pay stubs? */}
            {step === 4 && (
              <YesNoToggleInput
                name="missingPayStubs"
                prompt={t(
                  "Are you missing any pay stubs from the last 6 months?"
                )}
                control={control}
              />
            )}

            {/* STEP 5: Zero-income gaps? */}
            {step === 5 && (
              <YesNoToggleInput
                name="bigGaps"
                prompt={t(
                  "Have you had any weeks or months of zero income in the last 6 months?"
                )}
                control={control}
              />
            )}
          </Stack>

          <Grid container justifyContent="flex-start">
            <SubmitButton loading={isSubmitting}>{t("Next")}</SubmitButton>
          </Grid>
        </form>
      )}
    </Box>
  );
}
