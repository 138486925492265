import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FullscreenBox from "src/components/FullScreenBox";
import { ScreenTitle } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import createUserChatMessage from "src/firebase/createUserChatMessage";
import { useCurrentUser } from "src/SessionBoundary";
import useErrorHandler from "src/utils/useErrorHandler";
import { trackEvent } from "../OnboardingScreen/amplitude";
import ReactivationDialog from "./ReactivationDialog";

const DisabledReasonBody = () => {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  const handleRejectionContact = async () => {
    try {
      trackEvent("Rejected Screen - Clicked on Contact");
      await createUserChatMessage(
        {
          text: `I was rejected from the application process for an incorrect reason. Can you help me fix this? This is the incorrect reason: ${user.applicationStatusDetails}`,
          user,
        },
        clients
      );
      navigate("/coach");
    } catch (err) {
      errorHandler(err);
    }
  };

  switch (user.applicationStatus) {
    case "rejected":
      return (
        <Card sx={{ width: "100%", maxWidth: 600 }}>
          <CardContent>
            <Stack spacing={2}>
              <Typography>
                {t(
                  "{{ firstName }}, our team has finished reviewing the application form you submitted.",
                  {
                    firstName: user.firstName,
                  }
                )}
              </Typography>
              <Typography>
                {user.applicationStatusDetails
                  ? t(
                      "Unfortunately, we cannot move you forward in the process for the following reason:"
                    )
                  : t(
                      "Unfortunately, based on the information you provided, we cannot move you forward in the application process at this time."
                    )}
              </Typography>

              <Box sx={{ paddingX: 2 }}>
                <Typography fontWeight="bold">
                  {user.applicationStatusDetails}
                </Typography>
              </Box>
              <Typography marginTop={2}>
                {t(
                  "We know mistakes can happen when filling out the application. Do you believe this was a mistake? Please contact our team."
                )}
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleRejectionContact}
                >
                  {t("Get in touch")}
                </Button>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      );

    case "withdrawn":
    case "inactive":
    case "dropped":
    default:
      return <ReactivationDialog />;
  }
};

export default function DisabledAccountScreen() {
  const { t } = useTranslation();
  const user = useCurrentUser();

  useEffect(() => {
    trackEvent("Disabled Account Screen - Viewed", {
      applicationStatus: user.applicationStatus,
    });
  }, [user.applicationStatus]);

  return (
    <FullscreenBox
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
      })}
    >
      <Stack
        spacing={6}
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingX: theme.spacing(2),
          marginTop: "100px",
        })}
      >
        <ScreenTitle sx={{ textAlign: "center" }}>
          {t("Welcome back, {{ firstName }}!", { firstName: user.firstName })}
        </ScreenTitle>
        <DisabledReasonBody />
      </Stack>
    </FullscreenBox>
  );
}
