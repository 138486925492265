import { LockOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardProps,
  Chip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSessionAccountInformation } from "src/SessionBoundary";
import { Reimbursement } from "src/types/Reimbursement";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserType } from "src/types/User";
import formatOptionalTimestamp from "src/utils/formatOptionalTimestamp";
import DownloadFileButton from "../Button/DownloadFileButton";
import { Header20 } from "../Typography";

type Props = {
  reimbursement: Reimbursement;
  onReimbursementCompletion: () => void;
  milestones: StudentPlanMilestone[];
  onDelete: () => void;
} & CardProps;

export default function ReimbursementCard({
  reimbursement,
  onReimbursementCompletion,
  onDelete,
  milestones,
  ...rest
}: Props) {
  const { t } = useTranslation();
  const { type } = useSessionAccountInformation();
  const milestone = reimbursement.milestoneId
    ? milestones.find((m) => m.uid === reimbursement.milestoneId)
    : undefined;

  return (
    <Card {...rest}>
      <CardContent>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          marginBottom={1}
        >
          <Header20 marginRight={1}>{reimbursement.title}</Header20>
          {reimbursement.sentReimbursement ? (
            <Chip variant="outlined" color="success" label={t("Reimbursed")} />
          ) : null}
        </Box>
        <Typography>
          {t("Amount")}: ${reimbursement.amount}
        </Typography>
        <Typography variant="body2">
          {t("Milestone")}: {milestone ? milestone.title : "N/A"}
        </Typography>
        <Typography variant="body2">
          {t("Created At")}: {formatOptionalTimestamp(reimbursement.createdAt)}
        </Typography>
      </CardContent>
      <CardActions>
        <DownloadFileButton
          label={t("View Receipt")}
          filePath={reimbursement.filePath}
        />
        {type === UserType.Admin && (
          <Button
            sx={(theme) => ({
              color: reimbursement.sentReimbursement
                ? theme.palette.grey[500]
                : theme.palette.success.main,
            })}
            startIcon={<LockOutlined />}
            onClick={onReimbursementCompletion}
          >
            {reimbursement.sentReimbursement
              ? t("Mark as not reimbursed ")
              : t("Mark as reimbursed")}
          </Button>
        )}
        {/* TODO: bring back ability to delete once I figure out how to deal with the case the reimbursement is tied ot a milestone */}
        {/* {!reimbursement.sentReimbursement && (
          <ActionButtonWithConfirmDialog
            buttonColor="error"
            onAction={onDelete}
            buttonLabel={t("Delete")}
          />
        )} */}
      </CardActions>
    </Card>
  );
}
