import { initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";
import { Functions, getFunctions } from "firebase/functions";
import { FirebaseStorage, getStorage } from "firebase/storage";
import React, { createContext, useContext } from "react";
import firebaseConfig from "src/firebase/config";

// Define the context type
export type Clients = {
  db: Firestore;
  storage: FirebaseStorage;
  functions: Functions;
};

export type Context = {
  clients: Clients;
};

const app = initializeApp(firebaseConfig);

// Create the initial context value
const initialContextValue: Context = {
  clients: {
    db: getFirestore(app),
    storage: getStorage(app),
    functions: getFunctions(app),
  },
};

// Create the app context
export const AppContext = createContext<Context>(initialContextValue);

export const useAppContext = () => useContext(AppContext);

// Create the provider component
interface AppContextProviderProps {
  children: React.ReactNode;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => (
  <AppContext.Provider value={initialContextValue}>
    {children}
  </AppContext.Provider>
);
