import { doc, updateDoc } from "firebase/firestore";
import Joi from "joi";
import { EligibilityFormData } from "src/pages/OnboardingScreen/EligibilityScreen";
import { UserAccount, UserEligibilityData } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

const JoiSchema: Record<keyof EligibilityFormData | "user", Joi.AnySchema> = {
  user: Joi.object().required(),
  // personal identification form
  legalFirstName: Joi.string().required(),
  legalLastName: Joi.string().required(),
  legalMiddleName: Joi.string().optional().allow(""),
  zipcode: Joi.string().required(),
  ssn: Joi.string().optional(),

  // postal address
  postalAddress: Joi.object().required(),
  // driver license
  driverLicenseExpirationDate: Joi.date().required(),
  driverLicenseIssueDate: Joi.date().required(),
  driverLicenseNumber: Joi.string().required(),
  driverLicenseState: Joi.string().required(),
  hasPreviousDriverLicense: Joi.boolean().optional(),
  previousDriverLicenseNumber: Joi.string().optional().allow(""),
  previousDriverLicenseState: Joi.string().optional().allow(""),
};

const schema = Joi.object<EligibilityFormData & { user: UserAccount }>(
  JoiSchema
);

const updateUserEligibilityData = serviceFunction(
  schema,
  async (
    {
      user,

      driverLicenseExpirationDate,
      driverLicenseIssueDate,
      ...rest
    },
    { db }
  ): Promise<void> => {
    const updated: UserEligibilityData = {
      ...generateFirestoreTimestamps(
        driverLicenseIssueDate,
        "driverLicenseIssueDate"
      ),
      ...generateFirestoreTimestamps(
        driverLicenseExpirationDate,
        "driverLicenseExpirationDate"
      ),
      ...rest,
    };

    await updateDoc(doc(db, "users", user.uid), {
      eligibilityData: updated,
    });
  }
);

export default updateUserEligibilityData;
