import { StudentDocument } from "src/firebase/FirestoreClient";
import { DocumentDescription } from "./DocumentCard";

const DOCUMENT_SCHEMA: Record<StudentDocument, DocumentDescription> = {
  ssn: {
    label: "Social Security Card",
    key: "ssn",
  },
  birth_certificate: {
    label: "Birth Certificate Or Passport",
    key: "birth_certificate",
  },
  driver_license: {
    label: "Driver's License",
    key: "driver_license",
    description: "We need to see your name and driver's license clearly.",
  },
  utility_bill: {
    label: "Proof of Residence",
    key: "utility_bill",
    description:
      "Gas bill, electric bill, water bill, bank statement, etc. Needs to be in your name and no more than 3 months old.",
  },
  pay_stub: {
    label: "Proof of Income",
    key: "pay_stub",
    description:
      "At least one pay stub within the past 6 months, bank records with direct deposit payments, tax forms over the past year or an offer letter / employer letter that details the pay rate / annual salary.",
  },
  selective_services: {
    label: "Selective Services Registration",
    key: "selective_services",
    description:
      "Required for Males born after Jan 1, 1960. You can verify your status at https://www.sss.gov/verify/. If you are exempt due to incarceration, you can request a Status Information Letter here: https://www.sss.gov/verify/sil/.",
  },
  food_stamps: {
    label: "Proof of Public Benefits",
    key: "food_stamps",
    optional: true,
    description:
      "Proof of receipt of these benefits may include letters, deposits, or EBT cards. Public benefits include, but are not limited to, TANF (Temporary Assistance for Needy Families), SNAP (Supplemental Nutrition Assistance Program), SSI (Supplemental Security Income), and SSDI (Social Security Disability Insurance).",
  },
};

export default DOCUMENT_SCHEMA;
