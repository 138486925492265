import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import MilestoneType from "src/types/MilestoneType.enum";
import { UserAccount } from "src/types/User";

export default async function checkIfMilestoneExists(
  { user, type }: { user: UserAccount; type: MilestoneType },
  { db }: Clients
): Promise<boolean> {
  const planCollection = collection(db, "users", user.uid, "plan");

  const eventsQuery = query(planCollection, where("type", "==", type));

  const snapshot = await getCountFromServer(eventsQuery);

  return snapshot.data().count > 0;
}
