import {
  Alert,
  AlertTitle,
  Button,
  Grow,
  Stack,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import RocketLottie from "src/assets/Lotties/Rocket.json";
import { Header40 } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import checkIfMilestoneExists from "src/firebase/checkIfMilestoneExists";
import fetchExamProgressCount from "src/firebase/fetchExamProgressCount";
import MilestoneType from "src/types/MilestoneType.enum";
import { UserAccount } from "src/types/User";

type Props = {
  user: UserAccount;
  onMount: () => void;
  onClickPrimary: () => void;
  correctAnswers: number;
  numQuestions: number;
};

// 1. A styled container with a gradient background that adjusts for mobile/tablet+
const GradientCard = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: isMobileOnly ? 0 : theme.shape.borderRadius,
  padding: theme.spacing(4),
  // Gradient from primary to secondary
  background: `linear-gradient(
    to top right,
    ${theme.palette.primary.main},
    ${theme.palette.primary.dark}
  )`,
  // On tablet+ we limit width and center it, on mobile it can be full-width
  [theme.breakpoints.up("sm")]: {
    maxWidth: 500,
    margin: "auto",
  },
}));

const QuizGradesSection = ({
  user,
  onMount,
  onClickPrimary,
  correctAnswers,
  numQuestions,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [totalExams, setTotalExams] = useState(0);
  const { clients } = useAppContext();
  const [needsScheduleAppointment, setNeedsScheduleAppointment] = useState<
    "medical" | "exam"
  >();

  // Calculate the percentage score
  const score = Math.ceil((correctAnswers / numQuestions) * 100);

  useEffect(onMount, [onMount]);

  useEffect(() => {
    fetchExamProgressCount(user.uid, clients).then(setTotalExams);
  }, [user, clients]);

  useEffect(() => {
    const checkMilestones = async () => {
      if (
        process.env.NODE_ENV === "development" ||
        (totalExams > 5 && score >= 80)
      ) {
        const medicalExists = await checkIfMilestoneExists(
          { user, type: MilestoneType.MEDICAL_EXAM },
          clients
        );
        if (!medicalExists) {
          setNeedsScheduleAppointment("medical");
        } else {
          const examExists = await checkIfMilestoneExists(
            { user, type: MilestoneType.THEORY_EXAM },
            clients
          );
          if (!examExists) {
            setNeedsScheduleAppointment("exam");
          }
        }
      }
    };
    checkMilestones();
  }, [totalExams, clients, user, score]);

  return (
    <GradientCard>
      {/* Overlapping Score + Rocket */}
      <Stack alignItems="center" spacing={2}>
        <Typography variant="body2" color="white" sx={{ opacity: 0.8 }}>
          {t("YOUR GRADE")}
        </Typography>
        <Grow in timeout={1000}>
          <div>
            <Header40
              sx={{
                zIndex: 2, // Ensure text is above the rocket
                color: "white",
              }}
            >
              {score}%
            </Header40>
          </div>
        </Grow>

        {/* Rocket overlapping the score slightly */}

        <Lottie animationData={RocketLottie} style={{ height: 200 }} />

        {/* Title and Body */}
        <Typography
          variant="h6"
          align="center"
          sx={{ mt: 6, fontWeight: "bold", color: "white" }}
        >
          {score >= 80 ? t("You Passed!") : t("Nice Work!")}
        </Typography>

        {needsScheduleAppointment === "medical" && (
          <Alert severity="info" sx={{ mt: 2 }}>
            <AlertTitle>{t("Schedule your DOT physical exam.")}</AlertTitle>
            {t("Make it real by scheduling the first in-person step today!")}
          </Alert>
        )}
        {needsScheduleAppointment === "exam" && (
          <Alert severity="info" sx={{ mt: 2 }}>
            <AlertTitle>{t("Schedule your Permit")}</AlertTitle>
            {t(
              "You're ready to schedule your permit (CLP) and secure your spot today!"
            )}
          </Alert>
        )}
        {/* Two Buttons */}
        <Button
          variant="contained"
          sx={{ backgroundColor: "white", color: theme.palette.primary.main }}
          onClick={onClickPrimary}
          fullWidth
        >
          {t("Continue")}
        </Button>
      </Stack>
    </GradientCard>
  );
};

export default QuizGradesSection;
