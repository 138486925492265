/**
 * Calculates the proficiency score using an Exponential Moving Average (EMA) of exam attempts.
 * The exam attempts are first sorted by their 'lastUpdatedAt' field (oldest to newest), so that the most recent exams receive more weight.
 * If an exam attempt does not have a 'lastUpdatedAt', it is treated as an early attempt.
 * Proficiency for us means having an EMA above 0.8. With fewer than 5 exams, the score is capped at 0.8 to reflect a penalty for insufficient data.
 * Additionally, the EMA update uses a dynamic smoothing factor: scores that improve the average are given more weight, and scores that lower it are given less weight.
 * @param examAttempts Array of exam attempt objects. Each object must have a numeric 'grade' property and an optional 'lastUpdatedAt' property (ISO string).
 * @returns The calculated proficiency score (a number between 0 and 1).
 */

const calculateProficiencyScore = (
  examAttempts: { grade: number; lastUpdatedAt?: string }[]
): number => {
  if (examAttempts.length === 0) return 0;

  // Sort exam attempts by 'lastUpdatedAt' (undefined dates are treated as the oldest)
  const sortedAttempts = examAttempts.slice().sort((a, b) => {
    const dateA = a.lastUpdatedAt ? new Date(a.lastUpdatedAt) : new Date(0);
    const dateB = b.lastUpdatedAt ? new Date(b.lastUpdatedAt) : new Date(0);
    return dateA.getTime() - dateB.getTime();
  });

  // smoothing factor - can adjust
  // based on the desired responsiveness of the EMA
  const baseAlpha = 0.3;
  // Use the first exam attempt's grade as the initial EMA value
  let ema = sortedAttempts[0].grade;

  for (let i = 1; i < sortedAttempts.length; i += 1) {
    const { grade } = sortedAttempts[i];
    // Grades that are higher than the current EMA are given more weight
    // while lower grades are given less weight
    const effectiveAlpha =
      grade >= ema ? Math.min(baseAlpha * 1.33, 1) : baseAlpha * 0.67;

    ema = effectiveAlpha * grade + (1 - effectiveAlpha) * ema;
  }

  // Apply penalty: if fewer than 5 exams
  if (examAttempts.length < 5) {
    const penalty = 1 - examAttempts.length / 5;
    ema = Math.min(ema, 1 - penalty);
  }

  return ema;
};

export default calculateProficiencyScore;
