import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  gridFilteredSortedRowIdsSelector,
  GridSlotsComponentsProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ExportForCallingButton from "src/components/Button/ExportForCallingButton";
import ExportForOpenPhoneButton from "src/components/Button/ExportForOpenPhoneButton";
import { UserAccount } from "src/types/User";

// augment the props for the toolbar slot
declare module "@mui/x-data-grid" {
  interface ToolbarPropsOverrides {
    users: UserAccount[];
  }
}

const DataGridCustomToolbar: React.FC<
  NonNullable<GridSlotsComponentsProps["toolbar"]>
> = ({ users, ...rest }) => {
  const apiRef = useGridApiContext();
  const [filteredUsers, setFilteredUsers] = useState<UserAccount[]>(users);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    const ids = gridFilteredSortedRowIdsSelector(apiRef);
    const filteredSet = new Set(ids);
    setFilteredUsers(
      (users as UserAccount[]).filter((client) => filteredSet.has(client.uid))
    );
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <GridToolbarContainer {...rest}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Box sx={{ flexGrow: 1 }} />
      <Button onClick={handleMenuOpen} disabled={filteredUsers.length === 0}>
        {t("Export Options")}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem>
          <GridToolbarExport />
        </MenuItem>
        <MenuItem>
          <ExportForCallingButton variant="text" users={filteredUsers} />
        </MenuItem>
        <MenuItem>
          <ExportForOpenPhoneButton users={filteredUsers} />
        </MenuItem>
      </Menu>
    </GridToolbarContainer>
  );
};

export default DataGridCustomToolbar;
