import { addDoc, collection, getDoc } from "firebase/firestore";
import Joi from "joi";
import { AttendanceFormData } from "src/pages/DashboardPage/RangeTab/RangeAttendanceForm";
import { Attendance } from "src/types/Attendance";
import { UserAccount } from "src/types/User";
import getFileExtension from "src/utils/getFileExtension";
import isAttendanceGuard from "src/utils/isAttendanceGuard";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";
import uploadFile from "./uploadFile";

type Data = {
  user: UserAccount;
} & AttendanceFormData;

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  date: Joi.date().required(),
  duration: Joi.number().required(),
  photo: Joi.object().instance(File).optional(),
});

const createAttendance = serviceFunction(
  schema,
  async ({ user, photo, date, duration }, clients): Promise<Attendance> => {
    // add some validation
    const userId = user.uid;
    const payload: Omit<Attendance, "uid"> = {
      userId,
      duration,
      ...generateFirestoreTimestamps(new Date(), "createdAt"),
      ...generateFirestoreTimestamps(date, "date"),
    };

    if (photo) {
      const res = await uploadFile(
        {
          blob: photo,
          url: `documents/${userId}/attendance/${new Date().toISOString()}.${getFileExtension(
            photo.name
          )}`,
        },
        clients
      );
      payload.path = res.ref.fullPath;
    }

    const doc = await addDoc(
      collection(clients.db, "users", userId, "attendance"),
      payload
    );

    const attendance = cleanFirestoreDoc(await getDoc(doc));

    if (!isAttendanceGuard(attendance)) {
      throw new Error("Something went wrong while submitting the attendance.");
    }

    return attendance;
  }
);

export default createAttendance;
