import { OpenInNew, VideoCallOutlined } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { trackEvent } from "src/pages/OnboardingScreen/amplitude";

type Webinar = {
  id: string;
  title: string;
  description: string;
  registrationUrl: string;
};

type WebinarKey = "kickoff" | "scheduling_appointments";

export const WEBINAR_SERIES: Record<WebinarKey, Webinar> = {
  kickoff: {
    id: "1",
    title: "Getting Started: Orientation",
    description:
      "Training online means you can go as fast you want, but getting started can be hard. Join us for tips!",
    registrationUrl: "https://forms.gle/mTmFdXfnF5njvc1k8",
  },
  scheduling_appointments: {
    id: "2",
    title: "Shifting Gears: Scheduling Appointments",
    description:
      "Finished the videos—what’s next? Time to book your medical and permit appointment. Join us to learn more!",
    registrationUrl: "https://forms.gle/g6epFNp24HksUY9o9",
  },
};

// const steps: SliderExplanationStepProp[] = [
//   {
//     type: "component",
//     icon: VideoCallTwoTone,
//     title: "A webinar is live videoconference",
//     titleProps: { fontWeight: "bold" },
//     body: "You will join a Google Meet link and get your questions answered and stay on track.",
//   },
//   {
//     type: "component",
//     icon: HandshakeTwoTone,
//     title: "Connect with fellow students",
//     titleProps: { fontWeight: "bold" },
//     body: "You can meet fellow students and get guidance from an Emerge coach",
//   },
//   {
//     type: "component",
//     icon: PersonPinCircleTwoTone,
//     title: "Learn tips and tricks",
//     titleProps: { fontWeight: "bold" },
//     body: "You will meet fellow students and learn tips and tricks to help you succeed in your training",
//   },
// ];

type Props = {
  webinar: Webinar;
};

export default function WebinarCard({ webinar }: Props) {
  const { t } = useTranslation();
  const handleClick = () => {
    trackEvent("Webinar Card - Clicked on Sign Up", {
      webinar_title: webinar.title,
    });
    window.open(webinar.registrationUrl, "_blank");
  };

  return (
    <Alert severity="info" icon={false}>
      <Stack spacing={1}>
        <Box>
          <Chip
            icon={<VideoCallOutlined />}
            label={t("Virtual Office Hours")}
            variant="outlined"
            size="small"
            sx={{
              color: "inherit",
            }}
          />
        </Box>
        <AlertTitle>{webinar.title}</AlertTitle>

        <Typography variant="body2">{webinar.description}</Typography>
        <Box>
          <Button
            variant="outlined"
            onClick={handleClick}
            color="inherit"
            size="small"
            startIcon={<OpenInNew />}
          >
            {t("REGISTER")}
          </Button>
        </Box>
      </Stack>
    </Alert>
  );
}
