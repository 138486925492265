import { useQuery } from "@apollo/client";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { endOfTomorrow } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UnitCard } from "src/components/Card";
import { UnitWithLessonProgress } from "src/components/Card/UnitCard";
import { PaddedLayout } from "src/components/Layout";
import Template from "src/components/Layout/Template";
import { useAppContext } from "src/contexts/AppContext";
import fetchCohortMeetings from "src/firebase/fetchCohortMeetings";
import { useCurrentUser } from "src/SessionBoundary";
import { CohortMeeting } from "src/types/CohortMeeting";
import sortBy from "src/utils/sortBy";
import useCompleteQuizGrades from "src/utils/useCompleteQuizGrades";
import useErrorHandler from "src/utils/useErrorHandler";
import useResourceProgress from "src/utils/useResourceProgress";
import AppSkeleton from "../AppSkeleton";
import DashboardTrainingProgressCard from "./DashboardTrainingProgressCard";
import ExamsSection from "./ExamsSection";
import { GetUnitsDocument } from "./GetUnits.generated";
import MeetingCard from "./MeetingCard";

type Props = {
  courseId: string;
};

const CourseTab = ({ courseId }: Props) => {
  const errorHandler = useErrorHandler();
  const user = useCurrentUser();

  const { data } = useQuery(GetUnitsDocument, {
    variables: { courseId },
    onError: errorHandler,
  });

  const { progress } = useResourceProgress();
  const { t } = useTranslation();
  const { clients } = useAppContext();
  const [cohortMeetings, setCohortMeetings] = useState<CohortMeeting[]>();

  const { completeReport, findGradeByQuizId } = useCompleteQuizGrades(user.uid);

  const unitsWithResourceProgress: UnitWithLessonProgress[] | undefined =
    useMemo(() => {
      if (!data || !completeReport || !progress) return undefined;

      const sortedUnits = sortBy(data.allUnits, "order");
      const completeUnits = [];

      for (let i = 0; i < sortedUnits.length; i += 1) {
        const unit = sortedUnits[i];

        // Process resources
        const resources = unit.resources.map((resource) => ({
          ...resource,
          videoCompleted: progress[resource.id]
            ? !!(
                progress[resource.id].progressFraction >= 0.8 ||
                progress[resource.id].hasCompleted
              )
            : false,
          quizCompleted:
            !resource.quiz || !!findGradeByQuizId(resource.quiz?.id),
        }));

        // Determine if the unit is unlocked
        let unlocked = i === 0; // First unit is always unlocked
        let current = false;
        if (i > 0) {
          const previousUnit: UnitWithLessonProgress = completeUnits[i - 1];
          unlocked = previousUnit.resources.every(
            (resource) => resource.videoCompleted && resource.quizCompleted
          );
        }

        if (unlocked)
          // it's current if there are missing videos
          current = !resources.every(
            (resource) => resource.videoCompleted && resource.quizCompleted
          );

        // Add unit with its resources and unlocked status to the array
        completeUnits.push({
          ...unit,
          resources,
          unlocked,
          current,
        });
      }

      return completeUnits;
    }, [data, progress, completeReport, findGradeByQuizId]);

  useEffect(() => {
    if (user.cohortId && user.organizationId) {
      fetchCohortMeetings(
        {
          organizationId: user.organizationId,
          cohortId: user.cohortId,
          futureOnly: true,
          lastMeetingDate: endOfTomorrow(),
        },
        clients
      )
        .then(setCohortMeetings)
        .catch(errorHandler);
    }
  }, [user, clients, errorHandler]);

  if (!data || !progress || !unitsWithResourceProgress) return <AppSkeleton />;

  return (
    <Template>
      <DashboardTrainingProgressCard client={user} />
      <PaddedLayout>
        {cohortMeetings && cohortMeetings.length > 0 && (
          <Stack marginBottom={2}>
            {cohortMeetings.map((meeting) => (
              <Box key={meeting.uid} marginTop={2}>
                <MeetingCard meeting={meeting} />
              </Box>
            ))}
          </Stack>
        )}
        <Grid container spacing={2}>
          {unitsWithResourceProgress.map((unit) => (
            <Grid item xs={12} lg={6} key={unit.id}>
              <UnitCard unit={unit} />
            </Grid>
          ))}
        </Grid>
        <Box my={4}>
          <Typography marginBottom={2}>{t("Practice Exams")}</Typography>
          <ExamsSection user={user} courseId={courseId} />
        </Box>
      </PaddedLayout>
    </Template>
  );
};

export default CourseTab;
