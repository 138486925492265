import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import CodeOfConductCard from "src/components/Card/CodeOfConductCard";
import SkillsTrainingPlanCard from "src/components/Card/SkillsTrainingPlanCard";
import { ScreenTitle } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import updateUserSkillsTrainingPlan from "src/firebase/updateUserSkillsTrainingPlan";
import SchoolSelectionForm from "src/pages/OnboardingScreen/SchoolSelectionForm";
import { UserAccount } from "src/types/User";
import isFullSkillsTrainingPlanGuard from "src/utils/isFullSkillsTrainingPlanGuard";
import TransportationSituation from "src/utils/TransportationSituation";
import useErrorHandler from "src/utils/useErrorHandler";
import DrivingScheduleSelection, {
  SkillsTrainingAvailabilitySchedule,
} from "./DrivingScheduleSelection";
import StipendSetupForm, { StipendInformation } from "./StipendSetupForm";
import TransportationSituationSelection from "./TransportationSituationSelection";

enum STEPS {
  EDIT_SCHOOL,
  REVIEW_ORIGINAL_PLAN,
  TRANSPORTATION_PLAN,
  DRIVING_SCHEDULE_SELECTION,
  CODE_OF_CONDUCT,
  STIPEND_SETUP,
  REIMBURSEMENT_EXPLANATION,
}

type Props = { user: UserAccount; onFinish: () => void };

export default function SkillsTrainingOnboardingWizardView({
  user,
  onFinish,
}: Props) {
  const [step, setStep] = useState<STEPS>(
    user.skillsTrainingPlan ? STEPS.REVIEW_ORIGINAL_PLAN : STEPS.EDIT_SCHOOL
  );
  const [stipendInfo, setStipendInfo] = useState<StipendInformation>();
  const [selectedWeekdays, setSelectedWeekdays] =
    useState<SkillsTrainingAvailabilitySchedule>([]);
  const { t } = useTranslation();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();
  const [selectedTransportationSituation, setSelectedTransportationSituation] =
    useState<keyof typeof TransportationSituation | null>(null);

  const handleComplete = async () => {
    // type narrowing
    if (!stipendInfo) return;
    if (!user.skillsTrainingPlan) return;
    if (!selectedTransportationSituation) return;

    // store stipend info in
    await updateUserSkillsTrainingPlan(
      {
        user,
        skillsTrainingPlan: {
          type: "full",
          ...user.skillsTrainingPlan,
          ...stipendInfo,
          availableDays: selectedWeekdays,
          ...stipendInfo,
          transportationSituation: selectedTransportationSituation,
        },
      },
      clients
    )
      .then(onFinish)
      .catch(errorHandler);
  };

  const handleTrainingDaySelection = (
    selectedWeekdays: SkillsTrainingAvailabilitySchedule
  ) => {
    setSelectedWeekdays(selectedWeekdays);
    setStep(STEPS.CODE_OF_CONDUCT);
  };

  return (
    <Box>
      {step === STEPS.EDIT_SCHOOL && (
        <SchoolSelectionForm
          user={user}
          onSuccess={() => setStep(STEPS.REVIEW_ORIGINAL_PLAN)}
        />
      )}

      {step === STEPS.REVIEW_ORIGINAL_PLAN && (
        <Stack spacing={1}>
          <ScreenTitle>{t("Review your School")}</ScreenTitle>
          <Typography>
            {t(
              "It’s been a while since you put together your success plan! Let’s take a moment to review the option you picked and make sure it still works for you."
            )}
          </Typography>
          <SkillsTrainingPlanCard user={user} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop={2}
          >
            <Button
              variant="outlined"
              onClick={() => setStep(STEPS.EDIT_SCHOOL)}
            >
              {t("Edit Plan")}
            </Button>
            <Button
              variant="contained"
              onClick={() => setStep(STEPS.TRANSPORTATION_PLAN)}
            >
              {t("Looks Good!")}
            </Button>
          </Box>
        </Stack>
      )}
      {step === STEPS.TRANSPORTATION_PLAN && (
        <TransportationSituationSelection
          defaultValue={
            selectedTransportationSituation || user.application?.transportation
          }
          onBack={() => setStep(STEPS.REVIEW_ORIGINAL_PLAN)}
          onSave={(situation) => {
            setSelectedTransportationSituation(situation);
            setStep(STEPS.DRIVING_SCHEDULE_SELECTION);
          }}
        />
      )}
      {step === STEPS.DRIVING_SCHEDULE_SELECTION && (
        <DrivingScheduleSelection
          existingSchedule={user.skillsTrainingPlan?.skillsTrainingSchedule}
          onBack={() => setStep(STEPS.TRANSPORTATION_PLAN)}
          onSave={handleTrainingDaySelection}
          defaultSelected={
            isFullSkillsTrainingPlanGuard(user.skillsTrainingPlan)
              ? user.skillsTrainingPlan.availableDays
              : {}
          }
        />
      )}

      {step === STEPS.CODE_OF_CONDUCT && (
        <CodeOfConductCard
          user={user}
          title={t("Code of Conduct")}
          onBack={() => setStep(STEPS.REVIEW_ORIGINAL_PLAN)}
          onContinue={() => setStep(STEPS.STIPEND_SETUP)}
          expectations={[
            {
              title: "Own Your Opportunity",
              description:
                "You’re in control of your experience—literally. Take ownership of your journey and make the most of this opportunity.",
            },
            {
              title: "Resilience",
              description:
                "There will be challenges. You might not pass your first exam, struggle in the yard, or feel like you needed more from your instructor. That’s all part of the process. Keep going.",
            },
            {
              title: "Be Responsive",
              description:
                "Stay engaged. Respond to the Emerge team within 48 hours.",
            },
            {
              title: "Show Up",
              description:
                "Unexcused absences aren’t an option. This scholarship gives you limited training hours—make every one of them count. You’re just 160 hours away.",
            },
          ]}
        />
      )}

      {step === STEPS.STIPEND_SETUP && (
        <Stack spacing={1}>
          <ScreenTitle>
            {t(
              "Let's now make sure we understand how you will get to the yard!"
            )}
          </ScreenTitle>
          <Typography>
            {t(
              "We want to make sure you have the resources you need to get to the yard every day. Let’s set up your stipend."
            )}
          </Typography>
          <StipendSetupForm
            user={user}
            trainingDays={Object.keys(selectedWeekdays).map(Number)}
            onComplete={(info) => {
              setStipendInfo(info);
              setStep(STEPS.REIMBURSEMENT_EXPLANATION);
            }}
          />
        </Stack>
      )}

      {step === STEPS.REIMBURSEMENT_EXPLANATION && (
        <Box>
          <CodeOfConductCard
            user={user}
            title={t("Reimbursement Expectations")}
            onBack={() => setStep(STEPS.REVIEW_ORIGINAL_PLAN)}
            onContinue={handleComplete}
            expectations={[
              {
                title: "Log your attendance daily",
                description: "If you don't log, we cannot reimburse you!",
              },
              {
                title: "Monday Reimbursements",
                description:
                  "Every Sunday, we’ll calculate your reimbursement based on the number of days you attended and logged over the previous week.",
              },
              {
                title: "First Reimbursement",
                description:
                  "Your first reimbursement will happen on the Monday of your second week of training.",
              },
              {
                title: "Receiving Reimbursements",
                description:
                  "You'll receive a virtual debit card on your email using the amount that was reported.",
              },
            ]}
          />
        </Box>
      )}
    </Box>
  );
}
