/**
 * Validates a Social Security Number (SSN).
 * Accepts SSN in formats: XXX-XX-XXXX, XXXXXXXXX, or XXX XX XXXX.
 * @param {string} ssn - The SSN to validate.
 * @returns {boolean} True if the SSN is valid, false otherwise.
 */
export default function isValidSSN(ssn: unknown): boolean {
  // Remove dashes or spaces for simplicity
  if (typeof ssn !== "string") return false;

  const cleanSSN = ssn.replace(/[- ]/g, "");

  // Check if the SSN has exactly nine digits
  if (!/^\d{9}$/.test(cleanSSN)) {
    return false;
  }

  // Split the SSN into its parts
  const part1 = cleanSSN.substring(0, 3);
  const part2 = cleanSSN.substring(3, 5);
  const part3 = cleanSSN.substring(5, 9);

  // Check for any section that is entirely zeros
  if (part1 === "000" || part2 === "00" || part3 === "0000") {
    return false;
  }

  // Rule 1: Reject SSNs that start with 666
  if (part1 === "666") {
    return false;
  }

  // Rule 2: Reject SSNs that start with 9
  if (part1.startsWith("9")) {
    return false;
  }

  // Rule 3: Reject SSNs with all the same digit (e.g., 111-11-1111)
  if (/^(\d)\1{8}$/.test(cleanSSN)) {
    return false;
  }

  // Rule 4: Reject SSNs with sequential digits (e.g., 123-45-6789 or 987-65-4321)
  if ("123456789".includes(cleanSSN) || "987654321".includes(cleanSSN)) {
    return false;
  }

  return true;
}
