import { Box, Chip, CircularProgress, Typography } from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
} from "@mui/x-data-grid-pro";
import { differenceInDays, format, isAfter } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Empty from "src/components/Empty";
import { PaddedLayout } from "src/components/Layout";
import { TableSkeleton } from "src/components/Skeleton/closet";
import TrueFalseChip from "src/components/TrueFalseChip";
import { BackgroundCheckReport } from "src/types/BackgroundCheck";
import MilestoneType from "src/types/MilestoneType.enum";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserAccount } from "src/types/User";
import { IncarcerationStatus } from "src/types/UserStatus";
import { getFullName } from "src/utils";
import calculateExpectedDailyEngagementRatio from "src/utils/calculateExpectedDailyEngagementRatio";
import formatDateString from "src/utils/formatDateString";
import formatOptionalTimestamp from "src/utils/formatOptionalTimestamp";
import isJusticeImpacted from "src/utils/isJusticeImpacted";
import isJusticeInvolved from "src/utils/isJusticeInvolved";
import useAdminsById from "src/utils/useAdminsById";
import useCohorts from "src/utils/useCohorts";
import useErrorHandler from "src/utils/useErrorHandler";
import useTimelineEventCountByUserId, {
  ENGAGEMENT_TIME_HORIZON,
} from "src/utils/useTimelineEventCountByUserId";
import useUsersExamProgressCount from "src/utils/useUsersExamProgressCount";
import useUsersLessonProgressCount from "src/utils/useUsersLessonProgressCount";
import useUsersMilestonesById from "src/utils/useUsersMilestonesById";
import useUsersPinnedNotesById from "src/utils/useUsersPinnedNotesById";
import determineEngagementRisk from "../Admin/CaseloadScreen/determineEngagementRisk";
import ApplicationStatusChip from "../ClientScreen/ApplicationStatusChip";
import EnrollmentStatusChip from "../ClientScreen/EnrollmentStatusChip";
import NoteCard from "../ClientScreen/NoteCard";
import DataGridCustomToolbar from "./DataGridCustomToolbar";
import { determineConditionalAcceptanceAction } from "./determineCoachAction";
import { OnlineTrainingDataGridRow } from "./OnlineTrainingDataGridRow";
import OnlineTrainingKeyIndicatorsSection from "./OnlineTrainingKeyIndicatorsSection";

type Props = {
  participants: UserAccount[];
  showKpis?: boolean;
};

type ClientGridColDef = GridColDef & { field: keyof OnlineTrainingDataGridRow };

type MilestoneColumnProps = {
  field: keyof OnlineTrainingDataGridRow;
  headerName: string;
};

function createMilestoneColumn({
  field,
  headerName,
}: MilestoneColumnProps): ClientGridColDef {
  return {
    field,
    headerName,
    disableColumnMenu: true,

    valueGetter: (milestone?: StudentPlanMilestone | "loading") => {
      if (milestone === "loading") return "loading";
      if (!milestone) return undefined;
      if (milestone.completedAt)
        return { status: "completed", date: milestone.date };
      return milestone.date
        ? {
            status: "pending",
            date: format(new Date(milestone.date), "MM/dd/yy"),
          }
        : undefined;
    },

    renderCell: (params: {
      value?: "loading" | { status: "completed" | "pending"; date: Date };
    }) =>
      params.value === "loading" ? (
        <CircularProgress size={12} variant="indeterminate" />
      ) : params.value?.status === "completed" ? (
        <Typography
          variant="body2"
          color="green"
          alignSelf="center"
          margin="auto"
          display="flex"
        >
          ✓ {format(new Date(params.value.date), "MMM d")}
        </Typography>
      ) : params.value !== undefined ? (
        <Typography
          variant="body2"
          alignSelf="center"
          display="flex"
          margin="auto"
          color={
            isAfter(new Date(), new Date(params.value.date))
              ? // overdue
                "error"
              : differenceInDays(new Date(params.value.date), new Date()) <= 7 // upcoming deadline
              ? "darkblue"
              : "text.secondary"
          }
        >
          {format(new Date(params.value.date), "MMM d")}
        </Typography>
      ) : (
        ""
      ),
    sortComparator: (
      a: { status: string; date: string } | undefined | "loading",
      b: { status: string; date: string } | undefined | "loading"
    ) => {
      if (!a || a === "loading" || !a.date) return -1; // Sort undefined dates last
      if (!b || b === "loading" || !b.date) return 1;
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    },
  };
}

export default function CohortCondictionalAcceptanceTable({
  participants,
  showKpis,
}: Props) {
  const { t } = useTranslation();
  const errorHandler = useErrorHandler();
  const [data, setData] = useState<OnlineTrainingDataGridRow[]>();

  const { getLessonProgress, loading: lessonsLoading } =
    useUsersLessonProgressCount(participants);
  const { getEngagementRatioByUser, loading: timelineEventsCountLoading } =
    useTimelineEventCountByUserId(participants);
  const {
    getMilestonesById,
    getOverdueMilestones,
    getUpcomingMilestones,
    loading: milestonesLoading,
  } = useUsersMilestonesById(participants);
  const notesById = useUsersPinnedNotesById(participants);
  const { adminsById } = useAdminsById();
  const { cohortsById } = useCohorts();
  const { loading: examsCountLoading, getExamProgress } =
    useUsersExamProgressCount(participants);

  useEffect(() => {
    if (
      lessonsLoading ||
      timelineEventsCountLoading ||
      milestonesLoading ||
      examsCountLoading
    )
      return;

    Promise.all(
      participants.map(async (participant) => {
        const milestones = getMilestonesById(participant);
        const { lastCall, pinnedNotes } = notesById[participant.uid] || {};
        const lessonProgress = getLessonProgress(participant);
        const timelineEventCount = getEngagementRatioByUser(participant);
        const { tierOfSupport, description: tierOfSupportDescription } =
          determineEngagementRisk({
            overdueMilestones: getOverdueMilestones(participant),
            upcomingMilestones: getUpcomingMilestones(participant),
            engagementRatio: timelineEventCount,
            lastLoggedIn: participant.lastLoggedIn,
          });
        const examCount = getExamProgress(participant);

        const row: OnlineTrainingDataGridRow = {
          id: participant.uid,
          uid: participant.uid,
          firstName: participant.firstName,
          lastName: participant.lastName,
          phone: participant.phone,
          email: participant.email,
          createdAt: participant.createdAt
            ? new Date(participant.createdAt)
            : undefined,
          isJusticeInvolved: isJusticeInvolved(participant.application),
          isJusticeImpacted: isJusticeImpacted(participant.application),
          income: participant.application?.totalIncomeLastSixMonths || 0,
          employmentStatus: participant.application?.employmentStatus || "N/A",
          incarcerationStatus: participant.incarcerationStatus,
          enrollmentStatus: participant.enrollmentStatus,
          lastLoggedIn: participant.lastLoggedIn
            ? new Date(participant.lastLoggedIn)
            : undefined,
          timelineEventCount: timelineEventCount
            ? timelineEventCount / 7
            : undefined,
          lessonProgress,
          examCount,
          pinnedNotes: pinnedNotes || [],
          lastCalledDate: lastCall ? new Date(lastCall.createdAt) : undefined,
          applicationStatus: participant.applicationStatus,
          applicationStatusLastUpdatedAt:
            participant.applicationStatusLastUpdatedAt,
          referralSource: participant.referralSource,
          backgroundCheckReport: participant.backgroundCheckReport,
          mvrRiskScore: participant.backgroundCheckReport?.mvrRiskScore,
          criminalRiskScore:
            participant.backgroundCheckReport?.criminalRiskScore,
          medicalExamMilestone: milestones
            ? milestones.find(
                (milestone) => milestone.type === MilestoneType.MEDICAL_EXAM
              )
            : "loading",
          theoryTrainingCompletionMilestone: milestones
            ? milestones.find(
                (milestone) =>
                  milestone.type === MilestoneType.THEORY_TRAINING_COMPLETION
              )
            : "loading",
          theoryExamMilestone: milestones
            ? milestones.find(
                (milestone) => milestone.type === MilestoneType.THEORY_EXAM
              )
            : "loading",
          skillsTrainingStartMilestone: milestones
            ? milestones.find(
                (milestone) =>
                  milestone.type === MilestoneType.SKILLS_TRAINING_START
              )
            : "loading",
          skillsTrainingEndMilestone: milestones
            ? milestones.find(
                (milestone) =>
                  milestone.type === MilestoneType.SKILLS_TRAINING_END
              )
            : "loading",
          skillsTrainingExamMilestone: milestones
            ? milestones.find(
                (milestone) => milestone.type === MilestoneType.LICENSE_EXAM
              )
            : "loading",
          coachName: adminsById
            ? !participant.emergeCoachId
              ? "N/A"
              : Object.keys(adminsById).includes(participant.emergeCoachId)
              ? getFullName(adminsById[participant.emergeCoachId])
              : "INVALID COACH ID"
            : "Loading...",

          expectedDailyEngagement: participant.theoryTrainingPlan
            ? calculateExpectedDailyEngagementRatio(
                participant.theoryTrainingPlan
              )
            : undefined,
          coachAction: determineConditionalAcceptanceAction({
            lastLoggedIn: participant.lastLoggedIn,
            backgroundCheckReport: participant.backgroundCheckReport,
          }),
          tierOfSupport,
          tierOfSupportDescription,
          cohortName: participant.cohortId
            ? cohortsById[participant.cohortId]?.name
            : "",
          ...participant.skillsCoachChecklist,
        };

        return row;
      })
    )
      .then(setData)
      .catch(errorHandler);
  }, [
    participants,
    errorHandler,
    getEngagementRatioByUser,
    notesById,
    t,
    adminsById,
    getLessonProgress,
    getMilestonesById,
    lessonsLoading,
    timelineEventsCountLoading,
    milestonesLoading,
    getOverdueMilestones,
    getUpcomingMilestones,
    cohortsById,
    examsCountLoading,
    getExamProgress,
  ]);

  const columns: ClientGridColDef[] = [
    {
      field: "firstName",
      headerName: t("First Name"),
      width: 100,
    },
    {
      field: "lastName",
      headerName: t("Last Name"),
      width: 100,
    },
    {
      field: "phone",
      headerName: t("Phone"),
      width: 100,
    },
    {
      field: "email",
      headerName: t("Email"),
      width: 100,
    },
    {
      field: "cohortName",
      headerName: t("Cohort"),
      width: 100,
    },
    {
      field: "coachAction",
      headerName: t("Coach Action"),
      width: 100,
      renderCell: (params) =>
        params.value ? (
          <Chip label={params.value.action} color={params.value.color} />
        ) : (
          <div />
        ),
    },
    {
      field: "tierOfSupport",
      headerName: t("Tier of Support"),
      width: 100,
      renderCell: (params) => (
        <Chip
          label={params.value}
          variant="outlined"
          color={
            params.value === "risky"
              ? "error"
              : params.value === "attention"
              ? "warning"
              : params.value === "healthy"
              ? "success"
              : "default"
          }
        />
      ),
    },
    {
      field: "tierOfSupportDescription",
      headerName: t("Tier description"),
      width: 100,
    },
    {
      field: "createdAt",
      headerName: t("Created At"),
      width: 100,
      valueFormatter: (date: string) => formatDateString(date, "dateyear"),
      renderCell: (params) => formatOptionalTimestamp(params.value),
    },
    {
      field: "referralSource",
      headerName: t("Referral Source"),
      width: 100,
    },
    {
      field: "lastLoggedIn",
      headerName: t("Last seen"),
      width: 100,
      valueFormatter: (date: string) => formatDateString(date, "dateyear"),
      renderCell: (params) => formatOptionalTimestamp(params.value),
    },
    {
      field: "lastCalledDate",
      headerName: t("Last Called"),
      width: 100,
      valueFormatter: (date: string) => formatDateString(date, "dateyear"),
      renderCell: (params) => formatOptionalTimestamp(params.value),
    },
    {
      field: "timelineEventCount",
      headerName: t("{{timeHorizon}}-D Engagement", {
        timeHorizon: ENGAGEMENT_TIME_HORIZON,
      }),
      width: 75,
    },
    {
      field: "expectedDailyEngagement",
      headerName: t("Expected Engagement"),
      width: 75,
    },
    {
      field: "applicationStatus",
      headerName: t("Application Status"),
      width: 150,
      renderCell: (params) => <ApplicationStatusChip status={params.value} />,
    },
    {
      field: "applicationStatusLastUpdatedAt",
      headerName: t("Application Status Last Updated"),
      width: 100,
      valueFormatter: (date?: string) =>
        date ? format(new Date(date), "MM/dd/yy") : undefined,
      renderCell: (params) => formatOptionalTimestamp(params.value),
    },
    { field: "isJusticeInvolved", headerName: "Justice Involved", width: 100 },
    { field: "isJusticeImpacted", headerName: "Justice Impacted", width: 100 },
    { field: "income", headerName: "6-Month Income", width: 100 },
    { field: "employmentStatus", headerName: "Employment Status", width: 100 },
    {
      field: "enrollmentStatus",
      headerName: t("Enrollment Status"),
      width: 150,
      renderCell: (params) => <EnrollmentStatusChip status={params.value} />,
    },
    {
      field: "incarcerationStatus",
      headerName: t("Incarceration Status"),
      width: 150,
      valueGetter: (value?: keyof typeof IncarcerationStatus) =>
        value ? IncarcerationStatus[value] : "N/A",
      renderCell: (params) => params.value,
    },
    {
      field: "releaseDate",
      headerName: t("Release Date"),
      valueFormatter: (value) => formatDateString(value, "dateyear"),
    },
    {
      field: "lessonProgress",
      headerName: t("Lessons Watched"),
      width: 75,
      renderCell: (params) =>
        params.value !== undefined ? (
          params.value
        ) : (
          <CircularProgress size={12} variant="indeterminate" />
        ),
    },
    {
      field: "backgroundCheckReport",
      headerName: t("Background Check"),
      valueGetter: (report?: BackgroundCheckReport) => !!report,
      width: 100,
      renderCell: (params) => <TrueFalseChip value={params.value} />,
    },
    {
      field: "mvrRiskScore",
      headerName: t("MVR Risk"),
      width: 100,
    },
    {
      field: "criminalRiskScore",
      headerName: t("Criminal Risk"),
      width: 100,
    },
    {
      field: "examCount",
      headerName: t("Exams Completed"),
      width: 75,
    },
    {
      field: "engagementEfficiency",
      headerName: t("Engagement Efficiency"),
      width: 75,
    },
    createMilestoneColumn({
      field: "theoryTrainingCompletionMilestone",
      headerName: "Theory Training Completion",
    }),
    createMilestoneColumn({
      field: "medicalExamMilestone",
      headerName: "Medical Exam",
    }),
    createMilestoneColumn({
      field: "theoryExamMilestone",
      headerName: "Theory Exam",
    }),
    {
      field: "hasIssuedTheoryCertification",
      headerName: t("Has issued training record completion?"),
      width: 100,
      renderCell: (params) => <TrueFalseChip value={params.value} />,
    },
    {
      field: "hasSubmittedSchoolEnrollment",
      headerName: t("School enrollment completed?"),
      width: 100,
      renderCell: (params) => <TrueFalseChip value={params.value} />,
    },
    {
      field: "hasSubmittedFundingAgencyEnrollment",
      headerName: t("Funding agency enrollment completed?"),
      width: 100,
      renderCell: (params) => <TrueFalseChip value={params.value} />,
    },
    createMilestoneColumn({
      field: "skillsTrainingStartMilestone",
      headerName: "Skills Training Start",
    }),
    {
      field: "hasPaidSchool",
      headerName: t("Has paid school?"),
      width: 100,
      renderCell: (params) => <TrueFalseChip value={params.value} />,
    },
    createMilestoneColumn({
      field: "skillsTrainingEndMilestone",
      headerName: "Skills Training End",
    }),
    createMilestoneColumn({
      field: "skillsTrainingExamMilestone",
      headerName: "Skills Exam",
    }),
    {
      field: "coachName",
      headerName: t("Coach"),
    },
  ];

  const getDetailPanelHeight: DataGridProProps["getDetailPanelHeight"] =
    useCallback(() => "auto" as const, []);

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps["getDetailPanelContent"]>
  >(
    ({ row }) => {
      const typedRow = row as OnlineTrainingDataGridRow; // Type casting

      return (
        <PaddedLayout noYMargin>
          <Typography marginBottom={2}>{t("Pinned Notes")}</Typography>
          {typedRow.pinnedNotes?.map((note) => (
            <NoteCard key={note.uid} note={note} />
          ))}
        </PaddedLayout>
      );
    },
    [t]
  );

  if (!data) return <TableSkeleton />;

  if (participants.length === 0) return <Empty />;

  return (
    <Box>
      {showKpis && (
        <Box marginY={2}>
          <OnlineTrainingKeyIndicatorsSection data={data} />
        </Box>
      )}
      <DataGridPro
        sx={{ backgroundColor: "white" }}
        rows={data}
        columns={columns}
        onRowSelectionModelChange={(model) => {
          window.open(`/admin/clients/${model[0]}`, "_blank");
        }}
        slots={{
          toolbar: DataGridCustomToolbar,
        }}
        slotProps={{
          toolbar: { users: participants },
        }}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        initialState={{
          pinnedColumns: { left: ["name", "progressToNextMilestone"] },
        }}
      />
    </Box>
  );
}
