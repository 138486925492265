import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { PaddedLayout } from "src/components/Layout";
import StudentPlanTab from "src/pages/ClientScreen/StudentPlanTab/StudentPlanTab";
import StudentReferenceSection from "src/pages/OnboardingScreen/StudentReferenceSection";
import { useCurrentUser } from "src/SessionBoundary";
import { theme } from "src/theme";
import isUserOnboarded from "src/utils/isUserOnboarded";
import { LIB_VERSION } from "src/version";
import DocumentList from "../DocumentsTab";
import ReimbursementSection from "../RangeTab/ReimbursementSection";

export default function ProfileScreen() {
  const { t } = useTranslation();
  const user = useCurrentUser();

  // if (!user.enrollmentStatus) return <OnboardingScreen />;

  return (
    <PaddedLayout>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="column" gap={theme.spacing(2)}>
            <DocumentList
              client={user}
              showMilestones={isUserOnboarded(user)}
            />
            <Card>
              <CardHeader title={t("My Success Plan")} />
              <CardContent>
                <StudentPlanTab client={user} />
              </CardContent>
            </Card>
            <Card>
              <CardHeader title={t("References")} />
              <CardContent>
                <StudentReferenceSection client={user} />
              </CardContent>
            </Card>
            <ReimbursementSection user={user} />

            <Typography variant="body2" color="textSecondary">
              Emerge Career | Version {LIB_VERSION}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </PaddedLayout>
  );
}
