import {
  FlagOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  OpenInNew,
  ReceiptOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import DateInput from "src/components/Input/DateInput";
import ScribeEmbed from "src/components/ScribeEmbed";
import { ScreenTitle } from "src/components/Typography";
import MilestoneType from "src/types/MilestoneType.enum";
import USState from "src/types/states";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserAccount } from "src/types/User";
import {
  REQUIRED_MILESTONES,
  StudentPlanMilestoneFormData,
} from "./StudentPlanMilestoneForm";

type Props = {
  user: UserAccount;
  onSubmit: (data: StudentPlanMilestoneFormData) => void;
  milestoneType: MilestoneType;
  defaultValues?: Partial<Omit<StudentPlanMilestone, "date"> & { date: Date }>;
  formType: "edit" | "create";
};

const SingleMilestoneForm = ({
  user,
  milestoneType,
  onSubmit,
  defaultValues,
  formType,
}: Props) => {
  const template = REQUIRED_MILESTONES.find((m) => m.type === milestoneType);
  if (!template) throw new Error("Could not find template for milestone type");
  if (!template.createInstructions)
    throw new Error(
      "Cannot use this special form template to create a milestone."
    );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<StudentPlanMilestoneFormData>({
    mode: "onTouched",
    defaultValues:
      defaultValues || template
        ? {
            title: template.name,
            type: template.type,
          }
        : {},
  });
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [showScribeEmbed, setShowScribeEmbed] = useState(false);
  const [hasScheduled, setHasScheduled] = useState(false);

  return (
    <Box paddingY={2}>
      {step === 1 ? (
        <Stack spacing={4}>
          <ScreenTitle>{template.createInstructions.title}</ScreenTitle>
          <Box>
            <Typography variant="subtitle2" fontWeight="bold" marginBottom={1}>
              {t("Step 1: Schedule the appointment")}
            </Typography>

            <List>
              {template.type === "medical_exam" && (
                <>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <ReceiptOutlined
                            fontSize="small"
                            style={{ marginRight: 4 }}
                          />
                          <strong>{t("We Will Reimburse You")}</strong>
                        </span>
                      }
                      secondary={t(
                        "It costs between $90 - $150 depending on the provider. Keep the receipt. We will reimburse you after your appointment."
                      )}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <FlagOutlined
                            fontSize="small"
                            style={{ marginRight: 4 }}
                          />
                          <strong>{t("We Recommend CVS Minute Clinic")}</strong>
                        </span>
                      }
                      secondary={t(
                        "You can also Google other DOT Physical providers in your area."
                      )}
                    />
                  </ListItem>
                </>
              )}
              {template.type === "theory_test" && (
                <>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <ReceiptOutlined
                            fontSize="small"
                            style={{ marginRight: 4 }}
                          />
                          <strong>{t("We Will Reimburse You")}</strong>
                        </span>
                      }
                      secondary={t(
                        "It costs about ~$100. Keep the receipt. We will reimburse you after your appointment."
                      )}
                    />
                  </ListItem>
                  {user.state === USState.MA && (
                    <ListItem disableGutters>
                      <ListItemText
                        primary={
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FlagOutlined
                              fontSize="small"
                              style={{ marginRight: 4 }}
                            />
                            <strong>{t("You’ll Use the RMV Website")}</strong>
                          </span>
                        }
                        secondary={t(
                          "Click on schedule. Then select 'Appointments' > 'Service Center Appts' > 'Commercial Driver Services' > 'CDL - Commercial Permit Exam'. Once you do that, the RMV will send you an email to pick a location and time."
                        )}
                      />
                    </ListItem>
                  )}
                </>
              )}
            </List>

            <Stack direction="row" spacing={2} marginBottom={2}>
              <Button
                variant={hasScheduled ? "outlined" : "contained"}
                target="_blank"
                onClick={() => setHasScheduled(true)}
                startIcon={<OpenInNew />}
                href={
                  template.createInstructions.link[user.state]
                    ? template.createInstructions.link[user.state]
                    : template.createInstructions.link.default
                }
              >
                {t("Schedule")}
              </Button>
              <Button
                variant="text"
                onClick={() => setShowScribeEmbed(!showScribeEmbed)}
                startIcon={
                  showScribeEmbed ? (
                    <KeyboardArrowUpOutlined />
                  ) : (
                    <KeyboardArrowDownOutlined />
                  )
                }
              >
                {showScribeEmbed ? t("Hide") : t("Need more help?")}
              </Button>
            </Stack>
            <Collapse in={showScribeEmbed}>
              <Typography variant="body2" marginTop={1}>
                {t(
                  "This step-by-step guide will help you with scheduling your appointment. Follow the instructions below:"
                )}
              </Typography>
              <ScribeEmbed
                user={user}
                scribe={template.createInstructions.scribe}
              />
            </Collapse>
            <Stack spacing={2} marginTop={1}>
              <Divider />
              <Box>
                <Trans
                  t={t}
                  defaults="<bold>Done scheduling</bold>?"
                  components={{ bold: <b /> }}
                />
                <Typography variant="body2">
                  {t(
                    "Go ahead and update your app so we know when you’ll be done with this step."
                  )}
                </Typography>
              </Box>
              <Button
                variant={hasScheduled ? "contained" : "outlined"}
                onClick={() => setStep(2)}
                size="large"
                color="success"
              >
                {t("Yes, I've scheduled it!")}
              </Button>
            </Stack>
          </Box>
        </Stack>
      ) : (
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4} sx={{ margin: "auto", height: "100%" }}>
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                marginBottom={2}
              >
                {t(
                  "Step 2: Record the date of the appointment you just booked"
                )}
              </Typography>
              <DateInput
                control={control}
                name="date"
                label={t("When is your scheduled appointment?")}
                rules={{
                  required: { value: true, message: t("The date is required") },
                }}
                disablePast
              />
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Button
                  variant="outlined"
                  onClick={() => setStep(1)}
                  size="large"
                >
                  {t("Back")}
                </Button>
                <SubmitButton loading={isSubmitting} size="large">
                  {formType === "edit" ? t("Edit") : t("Confirm")}
                </SubmitButton>
              </Stack>
            </Stack>
          </form>
        </Box>
      )}
    </Box>
  );
};

export default SingleMilestoneForm;
