import React from "react";
import { isMobileOnly } from "react-device-detect";
import { UserAccount } from "src/types/User";

// Enum to control the possible Scribe URLs
export enum ScribeUrl {
  SchedulingDOTPhysical = "https://scribehow.com/shared/DOT_Physical__CVS__eTTVcT67SReM8h0qfCW84g",
  // Add additional Scribe URLs here as needed, for example:
  SchedulingPermitMA = "https://scribehow.com/embed/CLP_Exam_MA_How_to_Schedule__hw0EorFvS9OE-DLs4FJ6RQ",
  SchedulingPermitCO = "https://scribehow.com/embed/CLP_Permit_CO_How_to_schedule__nc95x70iT_qLwSc04xasQA",
  SchedulingPermitCA = "https://scribehow.com/embed/CLP_Exam_CA_How_to_Schedule__DtwH-bVSQXKaxWP9qDC9bw",
  SchedulingPermitNY = "https://scribehow.com/embed/CLP_Exam_NY_How_to_Schedule__0SXkCZ2tSh6TnDlV3Ce31Q",
}

export type ScribeLink = {
  default: ScribeUrl;
  [state: string]: ScribeUrl;
};

type ScribeEmbedProps = {
  user: UserAccount;
  scribe: ScribeLink;
  width?: string;
  height?: string;
};

// React component to embed a Scribe iframe
const ScribeEmbed: React.FC<ScribeEmbedProps> = ({
  user,
  scribe,
  width = "100%",
  height = isMobileOnly ? 400 : 480,
}) => {
  const getScribeLink = (): ScribeUrl => {
    if (user.state && scribe[user.state]) {
      return scribe[user.state];
    }
    return scribe.default;
  };

  const link = getScribeLink();

  return (
    <iframe
      src={link}
      width={width}
      height={height}
      allowFullScreen
      title="Scribe Embed"
    />
  );
};

export default ScribeEmbed;
