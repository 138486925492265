import { Box, Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactJoyride from "react-joyride";
import { useNavigate } from "react-router-dom";
import { PaddedLayout } from "src/components/Layout";
import PaddedLayoutContainer from "src/components/Layout/PaddedLayoutContainer";
import { Header20 } from "src/components/Typography";
import useJoyrideTutorial from "src/hooks/useJoyrideTutorial";
import { UserAccount } from "src/types/User";
import { CLP_ID } from "src/utils/constants";
import StudentPlanTab from "../ClientScreen/StudentPlanTab/StudentPlanTab";
import DashboardTrainingModuleCard from "./DashboardTrainingModuleCard";
import DashboardTrainingProgressCard from "./DashboardTrainingProgressCard";
import PWASnackbar from "./PWASnackbar";
import TrainingProgressStepper from "./TrainingProgressStepper";

// ID of  CLP is currently hardcoded
type Props = {
  user: UserAccount;
  onViewSuccessPlan: () => void;
};

const OnlineTrainingView = ({ user, onViewSuccessPlan }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { isTutorialRunning, joyrideSteps, handleJoyrideCallback } =
    useJoyrideTutorial({
      steps: [
        {
          disableBeacon: true,
          target: ".training-stepper",
          content: t(
            "Welcome to your Emerge dashboard 🚀! This is where you can track the high-level progress of your training."
          ),
        },
        {
          disableBeacon: true,
          target: ".training-card",
          content: t(
            "Your goal now is to finish the online training. Once you get your permit, we will send you to a partner school for in-person training."
          ),
        },
        {
          disableBeacon: true,
          target: ".success-plan-card",
          content: t(
            "This is your Success Plan. It tracks the milestones you need to complete outside of the app to succeed."
          ),
        },
        {
          disableBeacon: true,
          target: ".training-card",
          content: t(
            "The next step is for you to finish the first unit! Click here to keep going."
          ),
        },
      ],
    });

  const navigateToCourse = () => navigate(`/courses/${CLP_ID}`);

  return (
    <Box>
      <PWASnackbar />
      <ReactJoyride
        steps={joyrideSteps}
        continuous
        run={isTutorialRunning}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: theme.palette.primary.main,
          },
        }}
      />

      <Box>
        <PaddedLayout noYMargin>
          <PaddedLayoutContainer>
            <Box className="training-stepper">
              <TrainingProgressStepper user={user} />
            </Box>
          </PaddedLayoutContainer>
        </PaddedLayout>

        <PaddedLayout>
          <PaddedLayoutContainer>
            <Box className="training-card">
              {user.theoryTrainingPlan ? (
                <DashboardTrainingModuleCard user={user} />
              ) : (
                <DashboardTrainingProgressCard
                  client={user}
                  handleClick={navigateToCourse}
                />
              )}
            </Box>

            <Box marginTop={4}>
              <Grid container justifyContent="space-between">
                <Grid item xs={12} md={6} className="success-plan-card">
                  <Header20 my={2}>{t("Upcoming Milestones")}</Header20>
                  <StudentPlanTab
                    client={user}
                    hideButtons
                    onlyShowUpcomingMilestones
                    onReviewSuccessPlan={onViewSuccessPlan}
                  />
                </Grid>
              </Grid>
            </Box>
          </PaddedLayoutContainer>
        </PaddedLayout>
      </Box>
    </Box>
  );
};

export default OnlineTrainingView;
