import { useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchDocuments from "src/firebase/fetchDocuments";
import fetchOrganizationDocuments from "src/firebase/fetchOrganizationDocuments";
import {
  requiredEnrollmentDocuments,
  StudentDocument,
} from "src/firebase/FirestoreClient";
import getItemsStatus from "src/pages/ClientScreen/StudentPlanTab/getItemsStatus";
import getItemsStatusWithExtractors from "src/pages/ClientScreen/StudentPlanTab/getItemsStatusWithExtractors";
import { OrganizationDocument } from "src/types";
import { UserAccount, UserUploadedDocument } from "src/types/User";
import useErrorHandler from "./useErrorHandler";

type DocumentStatus = {
  missingOrganizationDocuments: OrganizationDocument[];
  missingEmergeDocuments: StudentDocument[];
  submittedOrganizationDocuments: {
    organizationDocument: OrganizationDocument;
    uploadedDocument: UserUploadedDocument;
  }[];
  submittedEmergeDocuments: UserUploadedDocument[];
  loading: boolean;
  hasSubmittedAllDocuments?: boolean;
  totalDocumentsSubmitted: number;
  totalDocumentsRequired: number;
};

const useUserUploadedDocuments = (user: UserAccount): DocumentStatus => {
  const { clients } = useAppContext();
  const [missingOrganizationDocuments, setMissingOrganizationDocuments] =
    useState<OrganizationDocument[]>([]);
  const [missingEmergeDocuments, setMissingEmergeDocuments] = useState<
    StudentDocument[]
  >([]);
  const [submittedOrganizationDocuments, setSubmittedOrganizationDocuments] =
    useState<DocumentStatus["submittedOrganizationDocuments"]>([]);
  const [submittedEmergeDocuments, setSubmittedEmergeDocuments] = useState<
    UserUploadedDocument[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const errorHandler = useErrorHandler();

  useEffect(() => {
    const loadDocuments = async () => {
      setLoading(true);

      try {
        // Fetch user uploaded documents
        const uploadedDocuments = await fetchDocuments(user.uid, clients);

        // Fetch organization documents if user.organizationId is provided
        let organizationDocuments: OrganizationDocument[] = [];
        if (user.organizationId) {
          // documents required by the organization
          organizationDocuments = await fetchOrganizationDocuments(
            user.organizationId,
            clients
          );
        }

        const {
          missingItems: missingDefaultEnrollmentDocuments,
          existingItems: existingDefaultEnrollmentDocuments,
        } = getItemsStatus(
          [...requiredEnrollmentDocuments],
          uploadedDocuments,
          (requiredDocument, uploadedDocument) =>
            uploadedDocument.name === requiredDocument
        );

        const submittedEmergeDocs: UserUploadedDocument[] = [
          ...existingDefaultEnrollmentDocuments.map((doc) => doc.item),
        ];

        if (organizationDocuments.length > 0) {
          const {
            missingItems: missingOrgDocs,
            existingItems: existingOrgDocs,
          } = getItemsStatusWithExtractors({
            requiredItems: organizationDocuments,
            allItemsIHave: uploadedDocuments,
            requiredItemsExtractor: (organizationDocument) =>
              organizationDocument.uid,
            allItemsIHaveExtractor: (uploadedDocument) =>
              uploadedDocument.organizationDocumentId || "",
            mergeItems: (requiredItem, existingItem) => ({
              organizationDocument: requiredItem,
              uploadedDocument: existingItem,
            }),
          });

          setMissingOrganizationDocuments(missingOrgDocs);
          setSubmittedOrganizationDocuments(existingOrgDocs);
        }

        setMissingEmergeDocuments(missingDefaultEnrollmentDocuments);
        setSubmittedEmergeDocuments(submittedEmergeDocs);
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    };

    loadDocuments();
  }, [user, clients, errorHandler]);

  const totalDocuments =
    missingEmergeDocuments.length +
    missingOrganizationDocuments.length +
    submittedEmergeDocuments.length +
    submittedOrganizationDocuments.length;

  const totalDocumentsSubmitted =
    submittedEmergeDocuments.length + submittedOrganizationDocuments.length;

  return {
    missingOrganizationDocuments,
    missingEmergeDocuments,
    submittedOrganizationDocuments,
    submittedEmergeDocuments,
    loading,
    hasSubmittedAllDocuments: loading
      ? undefined
      : !!(
          missingEmergeDocuments.length === 0 &&
          missingOrganizationDocuments.length === 0
        ),
    totalDocumentsSubmitted,
    totalDocumentsRequired: totalDocuments,
  };
};

export default useUserUploadedDocuments;
