import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Header20 } from "src/components/Typography";
import { UserSkillsTrainingPlan } from "src/types/User";

export const WEEKDAY_MAP: Record<number, string> = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export type SkillsTrainingAvailabilitySchedule = Record<
  number,
  {
    start: { hour: number; minute: number };
    end: { hour: number; minute: number };
  }
>;

interface DrivingScheduleSelectionProps {
  existingSchedule?: UserSkillsTrainingPlan["skillsTrainingSchedule"];
  defaultSelected?: SkillsTrainingAvailabilitySchedule;
  onSave?: (selectedSchedule: SkillsTrainingAvailabilitySchedule) => void;
  onBack: () => void;
}

export default function DrivingScheduleSelection({
  existingSchedule,
  defaultSelected = {},
  onSave,
  onBack,
}: DrivingScheduleSelectionProps) {
  const { t } = useTranslation();

  const [selectedDays, setSelectedDays] = useState<number[]>(
    Object.keys(defaultSelected).map(Number)
  );
  const [scheduleDetails, setScheduleDetails] =
    useState<SkillsTrainingAvailabilitySchedule>(defaultSelected);

  const toggleDay = (dayInt: number) => {
    setSelectedDays((prev) =>
      prev.includes(dayInt)
        ? prev.filter((d) => d !== dayInt)
        : [...prev, dayInt]
    );
  };

  const updateScheduleDetail = (
    dayInt: number,
    field: keyof {
      start: { hour: number; minute: number };
      end: { hour: number; minute: number };
    },
    value: { hour: number; minute: number }
  ) => {
    setScheduleDetails((prev) => ({
      ...prev,
      [dayInt]: { ...prev[dayInt], [field]: value },
    }));
  };

  const handleSave = () => {
    onSave?.(scheduleDetails);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={2}>
        <Header20>{t("Select Your Availability")}</Header20>
        <Typography>
          {t(
            "Toggle on the days you are available and specify your availability window."
          )}
        </Typography>
        {existingSchedule && (
          <Alert severity="info" sx={{ mb: 2 }}>
            <AlertTitle>{t("Remember Your Schedule")}</AlertTitle>
            {t(
              "You've already chosen: {{ schedule }}. Make sure the days you select align with this commitment.",
              {
                schedule: existingSchedule,
              }
            )}
          </Alert>
        )}
        <Card variant="outlined">
          <CardContent>
            {Object.entries(WEEKDAY_MAP).map(([dayInt, dayName]) => {
              const dayNumber = parseInt(dayInt, 10);
              return (
                <Box key={dayInt} mb={2}>
                  <FormControlLabel
                    label={t(dayName)}
                    control={
                      <Switch
                        checked={selectedDays.includes(dayNumber)}
                        onChange={() => toggleDay(dayNumber)}
                      />
                    }
                  />
                  {selectedDays.includes(dayNumber) && (
                    <Stack direction="row" spacing={2} mt={1} ml={4}>
                      <TimePicker
                        label={t("From")}
                        minutesStep={30}
                        value={
                          scheduleDetails[dayNumber]?.start
                            ? new Date(
                                0,
                                0,
                                0,
                                scheduleDetails[dayNumber]?.start?.hour || 0,
                                scheduleDetails[dayNumber]?.start?.minute || 0
                              )
                            : undefined
                        }
                        onChange={(value) =>
                          value &&
                          updateScheduleDetail(dayNumber, "start", {
                            hour: value.getHours(),
                            minute: value.getMinutes(),
                          })
                        }
                      />
                      <TimePicker
                        label={t("To")}
                        value={
                          scheduleDetails[dayNumber]?.end
                            ? new Date(
                                0,
                                0,
                                0,
                                scheduleDetails[dayNumber]?.end?.hour || 0,
                                scheduleDetails[dayNumber]?.end?.minute || 0
                              )
                            : undefined
                        }
                        minutesStep={30}
                        // TODO: the picker on mobile does not work for some reason
                        // minTime={
                        //   scheduleDetails[dayNumber]?.start
                        //     ? new Date(
                        //         0,
                        //         0,
                        //         0,
                        //         scheduleDetails[dayNumber]?.start?.hour || 0,
                        //         scheduleDetails[dayNumber]?.start?.minute || 0
                        //       )
                        //     : undefined
                        // }
                        onChange={(value) =>
                          value &&
                          updateScheduleDetail(dayNumber, "end", {
                            hour: value.getHours(),
                            minute: value.getMinutes(),
                          })
                        }
                      />
                    </Stack>
                  )}
                </Box>
              );
            })}
          </CardContent>
        </Card>
        <Box marginTop={2}>
          <Button onClick={onBack}>{t("Back")}</Button>
          <Button variant="contained" onClick={handleSave}>
            {t("Save Selection")}
          </Button>
        </Box>
      </Stack>
    </LocalizationProvider>
  );
}
