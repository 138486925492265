import { Typography } from "@mui/material";
import { lazy, Suspense, useEffect, useState } from "react";
import { UserAccount } from "src/types/User";
import { getCoordinatesFromZip } from "src/utils/getDistanceBetweenZips";
import useErrorHandler from "src/utils/useErrorHandler";
import { CardSkeleton } from "./Skeleton/closet";

// Lazy-load the underlying map component
const LazyMapWithMarkers = lazy(() => import("src/components/MapWithMarkers"));

type ZipMarker = {
  lat: number;
  lon: number;
  label: string;
};

type MapWithZipcodeMarkersProps = {
  user: UserAccount;
  locations: {
    zipcode: string;
    label: string;
  }[];

  /**
   * If true, automatically prepend the user's location zip code as a marker.
   * Defaults to false.
   */
  includeSelfLocation?: boolean;
};

export default function MapWithZipcodeMarkers({
  user,
  locations,
  includeSelfLocation = false,
}: MapWithZipcodeMarkersProps) {
  const [markers, setMarkers] = useState<ZipMarker[]>([]);
  const errorHandler = useErrorHandler();
  const selfZipcode =
    user.eligibilityData?.zipcode || user.application?.zipcode;

  useEffect(() => {
    // Collect all zipcodes in one array
    let finalLocations = locations;

    // If includeSelfLocation is true and selfZipcode is present, prepend it
    if (includeSelfLocation) {
      if (selfZipcode) {
        finalLocations = [
          {
            zipcode: selfZipcode,
            label: "Your Location",
          },
          ...finalLocations,
        ];
      }
    }

    // Function to fetch lat/lon for each zip, store in markers
    async function fetchMarkers() {
      const markerPromises = finalLocations.map(async (location) => {
        const startCoords = await getCoordinatesFromZip(location.zipcode);
        if (!startCoords) return null;

        return {
          lat: startCoords.lat,
          lon: startCoords.lon,
          label: location.label,
        };
      });

      // Resolve all lat/lon lookups
      const results = await Promise.all(markerPromises);
      setMarkers(results.filter((m) => m !== null) as ZipMarker[]);
    }

    fetchMarkers().catch((e) => {
      errorHandler(e);
      setMarkers([]);
    });
  }, [includeSelfLocation, selfZipcode, errorHandler, locations]);

  // If we haven’t loaded any markers yet, show fallback
  if (!markers.length) {
    return <CardSkeleton />;
  }

  // Pass the prepared markers (with lat, lon, label) into your lazy-loaded Map
  return (
    <Suspense fallback={<Typography>Map loading...</Typography>}>
      <LazyMapWithMarkers locations={markers} />
    </Suspense>
  );
}
