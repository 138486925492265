import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { addDays, format, subMonths } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IncomeCalcFormData } from "src/utils/Income";
import computeSixMonthIncomeTotal from "./computeSixMonthIncomeTotal";
import PayStubForm, { PayStub } from "./IncomeVerificationScreen/PayStubForm";
import PayStubTable from "./PayStubTable";

export type CalculationMethod = "straight" | "average" | "ytd" | "intermittent";

interface IncomeCalculationPayStubsFormProps {
  calculationMethod: CalculationMethod;
  onSubmit: (fullData: IncomeCalcFormData & { sixMonthTotal: number }) => void;
}

export default function IncomeCalculationPayStubsForm({
  calculationMethod,
  onSubmit,
}: IncomeCalculationPayStubsFormProps) {
  const { t } = useTranslation();

  const MIN_DATE = subMonths(new Date(), 6);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const MAX_DATE = new Date();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useForm<IncomeCalcFormData>({
    defaultValues: {
      stubs: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "stubs",
  });

  const watchAllFields = useWatch({ control });
  const [result, setResult] = useState<number | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const computeSixMonthTotal = useCallback(
    (data: IncomeCalcFormData): number =>
      computeSixMonthIncomeTotal(calculationMethod, data, MIN_DATE, MAX_DATE),
    [MAX_DATE, MIN_DATE, calculationMethod]
  );

  useEffect(() => {
    const data = getValues();
    setResult(computeSixMonthTotal(data));
  }, [computeSixMonthTotal, getValues, watchAllFields]);

  function onFormSubmit(data: IncomeCalcFormData) {
    onSubmit({ ...data, sixMonthTotal: computeSixMonthTotal(data) });
  }

  function findMissingDateRanges(): { start: Date; end: Date }[] {
    const filledRanges = fields
      .map(({ startDate, endDate }) => ({
        start: new Date(startDate),
        end: new Date(endDate),
      }))
      .sort((a, b) => a.start.getTime() - b.start.getTime());

    const missingRanges = [];
    let currentDate = new Date(MIN_DATE);

    // eslint-disable-next-line no-restricted-syntax
    for (const range of filledRanges) {
      if (currentDate < range.start) {
        missingRanges.push({
          start: currentDate,
          end: addDays(range.start, -1),
        });
      }
      currentDate = addDays(range.end, 1);
    }

    if (currentDate < MAX_DATE) {
      missingRanges.push({ start: currentDate, end: MAX_DATE });
    }

    return missingRanges;
  }

  const missingRanges = findMissingDateRanges();
  return (
    <Box sx={{ mt: 2 }}>
      {missingRanges.length > 0 && (
        <Alert severity="warning">
          <AlertTitle>
            {t(
              "We are missing the income records for the following date ranges:"
            )}
          </AlertTitle>

          {missingRanges.map((range) => (
            <Typography key={JSON.stringify(range)}>
              {format(range.start, "MMM dd, yyyy")} –{" "}
              {format(range.end, "MMM dd, yyyy")}
            </Typography>
          ))}
        </Alert>
      )}

      <form onSubmit={handleSubmit(onFormSubmit)}>
        {/* Existing Pay Stubs */}
        {fields.length > 0 && (
          <Box sx={{ mt: 3 }}>
            {fields.length > 0 && (
              <PayStubTable
                stubs={fields}
                onRemove={(index) => remove(index)}
              />
            )}
          </Box>
        )}

        {/* Button to Open Full-Screen PayStubForm */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsDialogOpen(true)}
          sx={{ mt: 2 }}
        >
          {t("Add Income Record")}
        </Button>

        {/* Dialog for PayStubForm */}
        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
          <DialogTitle>{t("Income Record")}</DialogTitle>
          <DialogContent>
            <PayStubForm
              onAddPayStub={(data) => {
                const payStub: PayStub = {
                  startDate: data.startDate,
                  endDate: data.endDate,
                  ...(data.incomeStatus === "noIncome"
                    ? { incomeStatus: "noIncome" }
                    : data.incomeStatus === "withProof"
                    ? {
                        incomeStatus: "withProof",
                        grossPay: data.grossPay!,
                        file: data.file!,
                      }
                    : {
                        incomeStatus: "withoutProof",
                        grossPay: data.grossPay!,
                      }),
                };
                append(payStub);
                setIsDialogOpen(false); // Close the dialog after adding a pay stub
              }}
              missingPeriods={findMissingDateRanges()}
              onClose={() => setIsDialogOpen(false)}
            />
          </DialogContent>
        </Dialog>

        {/* Total Income Calculation */}
        {result !== null && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {t("Your current 6-month total is: {{val}}", { val: result })}
          </Alert>
        )}

        {missingRanges.length === 0 && (
          <Button
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            sx={{ mt: 3 }}
          >
            {t("Finish & Submit")}
          </Button>
        )}
      </form>
    </Box>
  );
}
