import { Box } from "@mui/material";
import { useCurrentUser } from "src/SessionBoundary";
import { MIN_NUMBER_REFERENCES } from "../OnboardingScreen/OnboardingScreen";
import StudentReferenceSection from "../OnboardingScreen/StudentReferenceSection";

type Props = {
  onFinish: () => void;
};

export default function ReferenceOnboardingSection({ onFinish }: Props) {
  const user = useCurrentUser();

  const onAdd = (totalItems: number) => {
    if (totalItems >= MIN_NUMBER_REFERENCES) {
      onFinish();
    }
  };

  return (
    <Box>
      <StudentReferenceSection onChangeTotalReferences={onAdd} client={user} />
    </Box>
  );
}
