import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IncomeCalcFormData } from "src/utils/Income";
import EligibilityIncomeMethodForm, {
  CalculationMethod,
} from "./EligibilityIncomeMethodForm";
import IncomeCalculationPayStubsForm from "./IncomeCalculationPayStubsForm";

interface IncomeCalculationWizardProps {
  /**
   * Called when the user finishes both steps.
   * The final data includes the income calculation form data plus the computed six-month total.
   */
  onSubmit: (fullData: IncomeCalcFormData & { sixMonthTotal: number }) => void;
}

export default function IncomeCalculationWizard({
  onSubmit,
}: IncomeCalculationWizardProps) {
  // Step 1: the Eligibility Income Method Form; Step 2: the Pay Stubs Form
  const [step, setStep] = useState<number>(1);
  const { t } = useTranslation();
  const [calculationMethod, setCalculationMethod] =
    useState<CalculationMethod | null>(null);

  /**
   * Called when the EligibilityIncomeMethodForm has determined the calculation method.
   * Advances the wizard to the next step.
   */
  const handleMethodConfirm = (method: CalculationMethod) => {
    setCalculationMethod(method);
    setStep(2);
  };

  /**
   * Called when the IncomeCalculationPayStubsForm finishes.
   * Passes the full form data (plus computed six-month total) to the parent.
   */
  const handleFinalSubmit = (
    data: IncomeCalcFormData & { sixMonthTotal: number }
  ) => {
    onSubmit(data);
  };

  return (
    <Box>
      {step === 1 && (
        <Box>
          <Box marginBottom={2}>
            <Typography variant="body2">
              {t(
                "We will ask you some questions to understand what's the best way to calculate your income."
              )}
            </Typography>
          </Box>
          <EligibilityIncomeMethodForm onConfirmMethod={handleMethodConfirm} />
        </Box>
      )}
      {step === 2 && calculationMethod && (
        <IncomeCalculationPayStubsForm
          calculationMethod={calculationMethod}
          onSubmit={handleFinalSubmit}
        />
      )}
    </Box>
  );
}
