import { PlayCircle } from "@mui/icons-material";
import { Button, Card, CardActions, CardHeader } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "src/pages/OnboardingScreen/amplitude";
import { School, SchoolStudyMaterial } from "src/types/School";

export default function StudyMaterialCard({
  studyMaterial,
  school,
}: {
  studyMaterial: SchoolStudyMaterial;
  school: Pick<School, "name">;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleRedirect = () => {
    trackEvent("Study Material Card - Clicked", {
      study_material_id: studyMaterial.id,
      school_name: school.name,
      study_material_name: studyMaterial.name,
    });
    navigate(`/skills-training/practice/${studyMaterial.id}`);
  };

  return (
    <Card
      onClick={handleRedirect}
      style={{ cursor: "pointer" }}
      variant="outlined"
    >
      <CardHeader title={studyMaterial.name} subheader={school.name} />
      <CardActions>
        <Button startIcon={<PlayCircle />} variant="outlined">
          {t("Listen")}
        </Button>
      </CardActions>
    </Card>
  );
}
