import { useEffect, useMemo, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import createReference from "src/firebase/createReference";
import fetchReferences from "src/firebase/fetchReferences";
import updateReference from "src/firebase/updateReference";
import { ReferenceFormData } from "src/pages/OnboardingScreen/ReferenceForm";
import {
  StudentReference,
  StudentReferenceType,
} from "src/types/StudentReference";
import { UserAccount } from "src/types/User";
import replaceItem from "src/utils/replaceItem";
import useErrorHandler from "src/utils/useErrorHandler";
import formatNumberToE164 from "./formatNumberToE164";
import retryWithBackoff from "./retryWithBackoff";

export default function useUserReferences(user: UserAccount) {
  const [references, setReferences] = useState<StudentReference[]>([]);
  const [loading, setLoading] = useState(true);
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    retryWithBackoff(() =>
      fetchReferences(user.uid, clients)
        .then((data) => setReferences(data))
        .catch(errorHandler)
        .finally(() => setLoading(false))
    );
  }, [clients, errorHandler, user.uid]);

  const createNewReference = async (data: ReferenceFormData) => {
    if (references.some((r) => r.phone === formatNumberToE164(data.phone))) {
      throw new Error("You already added this phone number before!");
    }

    const newReference = await createReference({ user, ...data }, clients);
    setReferences((prev) => [...prev, newReference]);
  };

  const updateExistingReference = async (
    reference: StudentReference,
    data: ReferenceFormData
  ) => {
    try {
      const updatedReference = await updateReference(
        { user, reference, payload: data },
        clients
      );
      setReferences((prev) => {
        const updated = replaceItem(
          prev || [],
          reference,
          updatedReference,
          (item) => item.uid === updatedReference.uid
        );

        return [...updated];
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const communitySupervisor = useMemo(
    () =>
      references.find(
        (ref) => ref.type === StudentReferenceType.community_supervision
      ),
    [references]
  );

  return {
    references,
    loading,
    createNewReference,
    updateExistingReference,
    communitySupervisor,
    totalReferences: references.length,
  };
}
