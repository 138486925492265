import { collection, getCountFromServer } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";

export default async function fetchReferencesCount(
  participantId: string,
  { db }: Clients
): Promise<number> {
  const collectionRef = collection(db, "users", participantId, "references");
  const snapshot = await getCountFromServer(collectionRef);

  return snapshot.data().count; // Return the count from the server
}
