import { REQUIRED_MILESTONES } from "src/pages/ClientScreen/StudentPlanTab/StudentPlanMilestoneForm";
import MilestoneType from "src/types/MilestoneType.enum";

export default function getMilestoneCompletionInstructions(
  milestoneType: MilestoneType
) {
  const milestoneTemplate = REQUIRED_MILESTONES.find(
    (template) => template.type === milestoneType
  );

  if (!milestoneTemplate) return undefined;

  return milestoneTemplate.completeInstructions ?? undefined;
}
