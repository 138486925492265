import {
  CelebrationTwoTone,
  ComputerTwoTone,
  FactCheckTwoTone,
  HealthAndSafetyTwoTone,
  LocalShippingTwoTone,
  RocketLaunchTwoTone,
  TaskSharp,
  WorkTwoTone,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FloatingVideo from "src/components/FloatingVideo";
import useWarnBeforeUnload from "src/hooks/useWarnBeforeUnload";
import { useCurrentUser } from "src/SessionBoundary";
import SliderExplanationScreen, {
  SliderExplanationStepProp,
} from "../SliderExplanationScreen";
import BackgroundSurveyView from "./BackgroundSurveyView";
import LoadingTransitionScreen from "./LoadingTransitionScreen";

const ApplicationScreen = () => {
  const user = useCurrentUser();
  const [playVideo, setPlayVideo] = useState(false);
  const handleStart = () => {
    setPlayVideo(true); // Start playing the video after user interaction
  };
  const navigate = useNavigate();
  useWarnBeforeUnload();
  const [step, setStep] = useState<
    "pending" | "processing_eligible" | "finished_eligible"
  >("pending");

  const steps: SliderExplanationStepProp[] = [
    {
      type: "component",
      icon: CelebrationTwoTone,
      title: "Congratulations! You are IN",
      titleProps: { fontWeight: "bold" },
      body: t(
        "Based on your background, you qualify for a spot in our CDL training program. Here’s what this means ➡️"
      ),
    },
    {
      type: "component",
      icon: ComputerTwoTone,
      title: "Complete the online training.",
      titleProps: { fontWeight: "bold" },
      body: t(
        "Complete the video course in preparation for your Commercial Learner’s Permit."
      ),
    },
    {
      type: "component",
      icon: HealthAndSafetyTwoTone,
      title: "Get a DOT Physical Exam.",
      titleProps: { fontWeight: "bold" },
      body: "A quick medical check to confirm you’re fit to drive a truck. You need this before you get your Permit. Don’t worry though, we’ll help you with this.",
    },
    {
      type: "component",
      icon: TaskSharp,
      title: "Get your Commercial Learner’s Permit.",
      titleProps: { fontWeight: "bold" },
      body: "We cover all the fees. Getting your permit locks in your scholarship seat. So the quicker you take the exam, the sooner you can hit the road.",
    },
    {
      type: "component",
      icon: LocalShippingTwoTone,
      title: "Enroll in trucking school.",
      titleProps: { fontWeight: "bold" },
      body: "This is where the fun begins. After you get your permit, we’ll enroll you in one of our tuition-free trucking school partners. There, you’ll complete the driving training required to get your CDL.",
    },
    {
      type: "component",
      icon: WorkTwoTone,
      title: "Get employed.",
      titleProps: { fontWeight: "bold" },
      body: "90% of our candidates land their first job offer within 30 days–we’ll make sure you’re one of them.",
    },
    {
      type: "component",
      icon: FactCheckTwoTone,
      title: "From OUR side",
      titleProps: { fontWeight: "bold" },
      body: "We'll do a final check to confirm the information you provided looks good. If everything you submitted is accurate, you have nothing to worry about.",
    },
    {
      type: "component",
      icon: RocketLaunchTwoTone,
      title: "Ready to Get Started?",
      titleProps: { fontWeight: "bold" },
      body: "Let’s get you set up 👉",
    },
  ];

  const handleFinish = (isEligible: boolean, isTargetPopulation: boolean) => {
    if (!isEligible || !isTargetPopulation) navigate("/", { replace: true });
    else {
      setStep("processing_eligible");
    }
  };

  if (step === "processing_eligible") {
    return (
      <LoadingTransitionScreen
        variant="rocket"
        onAnimationEnd={() => setStep("finished_eligible")}
        messages={[
          t("Processing your information..."),
          t("Connecting you with scholarships..."),
          t("Verifying your eligibility..."),
        ]}
      />
    );
  }

  if (step === "finished_eligible") {
    return (
      <SliderExplanationScreen
        finishButtonProps={{
          onFinish: () =>
            navigate("/onboarding/success-plan", { replace: true }),
        }}
        steps={steps}
      />
    );
  }

  return (
    <Box onClick={handleStart}>
      <BackgroundSurveyView user={user} onFinish={handleFinish} />
      <FloatingVideo
        videoUrl="https://f004.backblazeb2.com/file/emerge-courses/CDL/videos/admissions/IntroAdmissionsVideo.mp4"
        playVideo={playVideo}
      />
    </Box>
  );
};

export default ApplicationScreen;
