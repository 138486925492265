import { LocalShipping } from "@mui/icons-material";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import {
  Box,
  Card,
  CardContent,
  CardProps,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  startLocation: string;
  destination: string;
  destinationLabel?: string;
  distance: number;
  dailyStipend: number;
  trainingDays: number[];
} & CardProps;

export default function LocationCard({
  startLocation,
  destination,
  destinationLabel,
  distance,
  dailyStipend,
  trainingDays,
  ...rest
}: Props) {
  const { t } = useTranslation();
  return (
    <Card {...rest}>
      <CardContent>
        {/* Start Location */}
        <Box
          display="flex"
          flexDirection={{ md: "row", xs: "column" }}
          alignItems={{ md: "center" }}
          justifyContent={{ md: "space-between" }}
        >
          <Box>
            <Box display="flex" alignItems="center">
              <FmdGoodOutlinedIcon sx={{ color: "black", mr: 1 }} />
              <Box>
                <Typography variant="body2" color="textSecondary">
                  {t("Start Location")}
                </Typography>
                <Typography fontWeight="bold">{startLocation}</Typography>
              </Box>
            </Box>

            {/* Dashed Line */}
            <Box
              sx={{
                borderLeft: "2px dashed gray",
                height: 20,
                ml: 2,
              }}
            />

            {/* Destination */}
            <Box display="flex" alignItems="center">
              <LocalShipping sx={{ color: "black", mr: 1 }} />
              <Box>
                <Typography variant="body2" color="textSecondary">
                  {destinationLabel || t("Your Destination")}
                </Typography>
                <Typography fontWeight="bold">{destination}</Typography>
              </Box>
            </Box>
          </Box>

          <Divider
            sx={{
              display: { md: "none", xs: "block" },
              marginY: { md: "none", xs: 2 },
            }}
          />

          <Stack spacing={2}>
            <Box>
              <Typography variant="body2" color="textSecondary">
                {t("One-Way Distance")}
              </Typography>
              <Typography fontWeight="bold">
                {distance.toFixed(2)} miles
              </Typography>
            </Box>

            <Box>
              <Typography variant="body2" color="textSecondary">
                {t("Round-Trip Distance")}
              </Typography>
              <Typography fontWeight="bold">
                {(distance * 2).toFixed(2)} miles
              </Typography>
            </Box>
          </Stack>

          <Stack spacing={2} marginTop={{ xs: 2, md: 0 }}>
            <Box>
              <Typography
                variant="body2"
                color="textSecondary"
                marginBottom={0.5}
              >
                {t("Daily Attendance Stipend")}
              </Typography>
              <Chip
                color="success"
                size="medium"
                variant="outlined"
                label={`$ ${dailyStipend.toFixed(2)}`}
              />
            </Box>

            <Box>
              <Typography
                variant="body2"
                color="textSecondary"
                marginBottom={0.5}
              >
                {t("Max Weekly Reimbursement")}
              </Typography>
              <Chip
                color="success"
                size="medium"
                variant="filled"
                label={`$ ${(dailyStipend * trainingDays.length).toFixed(2)}`}
              />
            </Box>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
}
