import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Header20 } from "src/components/Typography";
import { UserAccount } from "src/types/User";
import isFullSkillsTrainingPlanGuard from "src/utils/isFullSkillsTrainingPlanGuard";

type Props = { user: UserAccount };
export default function CreateSkillsTrainingPlanCard({ user }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  //  if the user has already created a full skills training plan return <div/>
  if (isFullSkillsTrainingPlanGuard(user.skillsTrainingPlan)) return <div />;

  return (
    <Card>
      <CardActionArea onClick={() => navigate("/skills-training/success-plan")}>
        <CardContent>
          <Chip variant="outlined" label={t("Task")} color="info" />
          <Header20 marginTop={1}>
            {t("Create Your Skills Training Success Plan")}
          </Header20>
          <Typography marginTop={1}>
            {t(
              "Time to create your skills training success plan that will take you to the finish line. Click here to get started!"
            )}
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="contained">{t("Start")}</Button>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
