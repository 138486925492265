import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Avatar from "src/components/Avatar";
import { useAppContext } from "src/contexts/AppContext";
import fetchPartner from "src/firebase/fetchPartner";
import fetchPartners from "src/firebase/fetchPartners";
import { Partner } from "src/types/Partner";
import useErrorHandler from "src/utils/useErrorHandler";
import AppSkeleton from "../AppSkeleton";
import PartnerHomeView from "../PartnerScreen/PartnerHomeView";

export default function AdminPartnerScreen(): JSX.Element {
  const [partnerId, setPartnerId] = useState<string>();
  const [partner, setPartner] = useState<Partner>();
  const [allPartners, setAllPartners] = useState<Partner[]>();
  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();

  useEffect(() => {
    fetchPartners(clients).then(setAllPartners).catch(errorHandler);
  }, [clients, errorHandler, partnerId]);

  useEffect(() => {
    if (partnerId) {
      fetchPartner(partnerId, clients).then(setPartner).catch(errorHandler);
    }
  }, [partnerId, clients, errorHandler]);

  const handleChange = (id: string) => {
    setPartner(undefined);
    setPartnerId(id);
  };

  if (!allPartners) return <AppSkeleton />;

  return (
    <Box>
      <Autocomplete
        options={allPartners.map((partner) => ({
          name: `${partner.firstName} ${partner.lastName}`,
          value: partner.uid,
        }))}
        getOptionLabel={(option) => option.name} // Add this line
        onChange={(_, value) => value && handleChange(value.value)}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Select a Partner" />
        )}
      />

      {partner && (
        <>
          <Card sx={{ width: 500, marginTop: 4 }} variant="outlined">
            <CardHeader title={`${partner.firstName} ${partner.lastName}`} />
            <CardContent>
              {partner.photoUrl ? (
                <Avatar src={partner.photoUrl} alt={partner.firstName} />
              ) : (
                <Avatar />
              )}
              <Typography>ID: {partner.uid}</Typography>
              <Typography>Phone: {partner.phone}</Typography>
              <Typography>Email: {partner.email}</Typography>
              <Typography>Partner Type: {partner.partnerType}</Typography>
              <Typography>
                {partner.city}, {partner.state}
              </Typography>
              <Typography>Invitation Code: {partner.invitationCode}</Typography>
            </CardContent>
          </Card>
          <Box marginTop={4}>
            <Divider />
            <PartnerHomeView partner={partner} />
          </Box>
        </>
      )}
    </Box>
  );
}
