import { ArrowBackIos } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { ScreenTitle } from "../Typography";
import PaddedLayout from "./PaddedLayout";

interface Props {
  title: string;
  section?: string;
  onBack?: () => void;
  children?: React.ReactNode;
}

const Header: React.FC<Props> = ({ section, title, onBack, children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.dark,
        borderBottom: 1,
        borderColor: theme.palette.grey[400],
      }}
    >
      <PaddedLayout noYMargin>
        <div>
          <Typography sx={{ marginBottom: 1 }} color="white">
            {section}
          </Typography>
          {onBack ? (
            <Box
              sx={{ cursor: "pointer" }}
              display="flex"
              alignItems="center"
              onClick={onBack}
            >
              <ArrowBackIos sx={{ color: "white" }} />
              <ScreenTitle sx={{ color: "white" }}>{title}</ScreenTitle>
            </Box>
          ) : (
            <ScreenTitle sx={{ color: "white" }}>{title}</ScreenTitle>
          )}
        </div>
      </PaddedLayout>
      <div>{children}</div>
    </Box>
  );
};

export default Header;
