import { SvgIconComponent } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardProps,
  Chip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "src/contexts/AppContext";
import checkVideoAskAnswersExists from "src/firebase/checkVideoAskAnswersExists";
import { trackEvent } from "src/pages/OnboardingScreen/amplitude";
import { VideoAskItem } from "src/pages/OnboardingScreen/videoAskIds.enum";
import { UserAccount } from "src/types/User";
import useErrorHandler from "src/utils/useErrorHandler";

type Props = {
  icon: SvgIconComponent;
  title: string;
  description: string;
  item: VideoAskItem;
  user: UserAccount;
} & CardProps;

export default function VideoAskActionItemCard({
  icon: Icon,
  title,
  item,
  description,
  user,
  ...rest
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hasCompleted, setHasCompleted] = useState(false);
  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();

  useEffect(() => {
    checkVideoAskAnswersExists(user.uid, item.formId, clients)
      .then(setHasCompleted)
      .catch(errorHandler);
  }, [user.uid, item.formId, clients, errorHandler]);

  const handleNavigation = () => {
    trackEvent("Video Ask Card - Clicked on Start", { title });
    navigate(`/interviews/${item.formId}`);
  };

  return (
    <Card sx={{ textAlign: "center" }} {...rest}>
      <CardContent>
        {hasCompleted ? (
          <Chip label={t("Done")} color="success" variant="outlined" />
        ) : (
          <Chip label={t("To-Do")} color="primary" variant="outlined" />
        )}

        <Box
          alignItems="center"
          justifyContent="center"
          display="flex"
          marginTop={2}
        >
          <Icon color="primary" fontSize="large" />

          <Typography variant="h6" gutterBottom marginLeft={2}>
            {title}
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        {hasCompleted ? (
          <Button variant="outlined" color="primary" onClick={handleNavigation}>
            {t("Review")}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNavigation}
          >
            {t("Start")}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
