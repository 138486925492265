import { Box, Button, Grid, Stack } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import FloatingVideo from "src/components/FloatingVideo";
import TextInput from "src/components/Input/TextInput";
import LABEL_MAP from "src/pages/ClientScreen/BackgroundTab/OnboardingSurveyLabels";
import { UserEligibilityData } from "src/types/User";
import { SSN_VIDEO_EXPLANATION } from "src/utils/constants";
import isValidSSN from "src/utils/isValidSSN";
import isValidZipCode from "src/utils/isValidZipcode";

export type EligibilityPersonalIdentificationData = Pick<
  UserEligibilityData,
  "legalFirstName" | "legalLastName" | "legalMiddleName" | "ssn" | "zipcode"
>;

type Props = {
  defaultValues: Partial<EligibilityPersonalIdentificationData>;
  onSubmit: (
    data: EligibilityPersonalIdentificationData
  ) => void | Promise<void>;
};

// TODO: work on journey for person that does not know their driver's license number
// maybe prompt to tell us who can help us in their network
export default function EligibilityPersonalIdentificationForm({
  defaultValues,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const [showVideo, setShowVideo] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<EligibilityPersonalIdentificationData>({
    mode: "onTouched",
    defaultValues,
  });

  return (
    <Box>
      <Button
        sx={{ paddingLeft: 0, marginLeft: 0, marginBottom: 4 }}
        onClick={() => setShowVideo(true)}
      >
        {t("Why do you need this?")}
      </Button>
      {showVideo && (
        <FloatingVideo videoUrl={SSN_VIDEO_EXPLANATION} playVideo={showVideo} />
      )}
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <Stack spacing={4} marginBottom={6}>
          <TextInput
            name="legalFirstName"
            prompt={LABEL_MAP.legalFirstName}
            control={control}
            required
          />
          <TextInput
            name="legalMiddleName"
            prompt={LABEL_MAP.legalMiddleName}
            control={control}
          />
          <TextInput
            name="legalLastName"
            prompt={LABEL_MAP.legalLastName}
            control={control}
            required
          />
          <TextInput
            name="zipcode"
            prompt={t("Your Zipcode")}
            control={control}
            rules={{
              validate: (value) =>
                isValidZipCode(value) || t("Invalid ZIP code"),
            }}
            required
          />
          <TextInput
            control={control}
            name="ssn"
            prompt={LABEL_MAP.ssn}
            rules={{
              validate: (value) =>
                isValidSSN(value) ||
                value === "" ||
                value === undefined ||
                t("Please enter a valid SSN"),
            }}
            helperText="We will need this to confirm your eligibility, but you can keep it blank if you do not remember it."
            required
          />
        </Stack>
        <Grid container justifyContent="space-between">
          <SubmitButton loading={isSubmitting}>{t("Continue")}</SubmitButton>
        </Grid>
      </form>
    </Box>
  );
}
