import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import StudyMaterialCard from "src/components/Card/StudyMaterialCard";
import useSchool from "src/hooks/useSchool";
import { UserAccount } from "src/types/User";

type Props = {
  user: UserAccount;
};

export default function StudyMaterialSection({ user }: Props) {
  const [showExplanations, setShowExplanations] = useState(false);
  const { school, studyMaterials } = useSchool(user);

  if (!school || !studyMaterials || !studyMaterials.length) return <div />;

  return (
    <Card variant="outlined">
      <CardHeader title={t("Learn")} />
      <CardContent>
        <Typography>
          {t(
            "Memorization is the key to passing your Pre-Trip and Air Brakes tests. Use everyday moments—like your commute or chores—to listen and reinforce the script. The more you repeat it, the more confident and natural it will feel on test day."
          )}
        </Typography>

        <Button
          onClick={() => setShowExplanations((prev) => !prev)}
          color="primary"
          startIcon={showExplanations ? <ArrowUpward /> : <ArrowDownward />}
        >
          {showExplanations ? t("Show Less") : t("Learn More")}
        </Button>
        <Collapse in={showExplanations}>
          <List>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    <CheckCircleOutlineIcon
                      sx={{ verticalAlign: "middle", marginRight: 1 }}
                    />
                    {t("Memorization is Your Key to Success")}
                  </>
                }
                secondary={t(
                  "Emerge students learn to drive quickly, but memorizing can be the dealbreakers. Lock in the script to ensure success."
                )}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <>
                    <PlayCircleOutlineIcon
                      sx={{ verticalAlign: "middle", marginRight: 1 }}
                    />
                    {t("Turn Dead Time Into Study Time")}
                  </>
                }
                secondary={t(
                  "Use your commute, lunch break, or chores to listen and learn. Swap out music or podcasts for a few weeks—let this drive you to success."
                )}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <>
                    <LibraryBooksIcon
                      sx={{ verticalAlign: "middle", marginRight: 1 }}
                    />
                    {t("Repetition Builds Confidence")}
                  </>
                }
                secondary={t(
                  "The more you listen, the more natural it feels. Repeat the script until it becomes second nature, so you're fully prepared on test day."
                )}
              />
            </ListItem>
          </List>
        </Collapse>

        {/* Study Material Cards */}
        <Grid container spacing={2} marginTop={2}>
          {studyMaterials.map((material) => (
            <Grid item xs={12} md={6} lg={4} key={material.id}>
              <StudyMaterialCard studyMaterial={material} school={school} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
