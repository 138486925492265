import { doc, Timestamp, updateDoc } from "firebase/firestore";
import Joi from "joi";
import MilestoneType from "src/types/MilestoneType.enum";
import { Reimbursement } from "src/types/Reimbursement";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserAccount } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";
import createUserDefaultEnrollmentDocument from "./createUserDefaultEnrollmentDocument";
import { MilestoneDocument } from "./FirestoreClient";
import uploadFile from "./uploadFile";

type Data = {
  user: UserAccount;
  milestone: StudentPlanMilestone;
  completed: boolean;
  completionProofFile?: File;
  reimbursementRequest?: Reimbursement;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  milestone: Joi.object().required(),
  completed: Joi.boolean().required(),
  completionProofFile: Joi.object().optional(),
  reimbursementRequest: Joi.object().optional(),
});

const updateMilestoneCompletion = serviceFunction(
  schema,
  async (
    { user, milestone, completed, completionProofFile, reimbursementRequest },
    clients
  ): Promise<
    Pick<StudentPlanMilestone, "completedAt" | "completedAtFirestoreTimestamp">
  > => {
    const now = new Date();
    const completedAt = completed ? now.toISOString() : null;
    const completedAtFirestoreTimestamp = completed
      ? Timestamp.fromDate(now)
      : null;

    const update: Partial<StudentPlanMilestone> = {
      completedAt,
      completedAtFirestoreTimestamp,
      completionReimbursementId: reimbursementRequest?.uid,
    };

    if (completionProofFile) {
      const uploadedResult = await uploadFile(
        {
          blob: completionProofFile,
          url: `documents/${user.uid}/milestones/${milestone.type}-${completionProofFile.name}`,
        },
        clients
      );
      update.completionProofFilePath = uploadedResult.ref.fullPath;

      // for backwards compatibility
      let documentMilestoneType: MilestoneDocument | undefined;
      if (milestone.type === MilestoneType.MEDICAL_EXAM) {
        documentMilestoneType = "medical_exam";
      } else if (milestone.type === MilestoneType.THEORY_EXAM) {
        documentMilestoneType = "theory_permit";
      } else if (milestone.type === MilestoneType.LICENSE_EXAM) {
        documentMilestoneType = "license";
      }
      if (documentMilestoneType) {
        try {
          await createUserDefaultEnrollmentDocument(
            {
              user,
              type: documentMilestoneType,
              file: completionProofFile,
              stage: "training",
            },
            clients
          );
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }
    }

    await updateDoc(
      doc(clients.db, `users/${user.uid}/plan/${milestone.uid}`),
      update
    );

    return { completedAt, completedAtFirestoreTimestamp };
  }
);

export default updateMilestoneCompletion;
