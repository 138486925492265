import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "src/contexts/AppContext";
import fetchSchool from "src/firebase/fetchSchool";
import { WEEKDAY_MAP } from "src/pages/DashboardPage/RangeTab/DrivingScheduleSelection";
import SchoolCard from "src/pages/OnboardingScreen/SchoolCard";
import { School } from "src/types/School";
import { UserAccount } from "src/types/User";
import isFullSkillsTrainingPlanGuard from "src/utils/isFullSkillsTrainingPlanGuard";
import TransportationSituation from "src/utils/TransportationSituation";
import useErrorHandler from "src/utils/useErrorHandler";
import MapWithZipcodeMarkers from "../MapWithZipcodeMarkers";

type Props = {
  user: UserAccount;
};

export default function SkillsTrainingPlanCard({ user }: Props) {
  const { t } = useTranslation();
  const { skillsTrainingPlan } = user;
  const [school, setSchool] = useState<School>();
  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();

  useEffect(() => {
    if (user.schoolId) {
      fetchSchool(user.schoolId, clients).then(setSchool).catch(errorHandler);
    }
  }, [user.schoolId, errorHandler, clients]);

  if (!skillsTrainingPlan)
    return (
      <Card>
        <CardContent>
          <CardHeader
            title={t("Skills Training Plan")}
            subheader={t("Incomplete")}
          />
        </CardContent>
      </Card>
    );

  return (
    <Box>
      {school ? (
        <Box>
          <MapWithZipcodeMarkers
            user={user}
            includeSelfLocation
            locations={[
              {
                zipcode: school.zipcode,
                label: school.name,
              },
            ]}
          />

          <SchoolCard school={school} variant="outlined" />
        </Box>
      ) : (
        <Skeleton variant="rectangular" width={100} />
      )}

      {isFullSkillsTrainingPlanGuard(skillsTrainingPlan) && (
        <Card variant="outlined" sx={{ marginTop: 4 }}>
          <CardHeader title={t("Full Plan")} />
          <CardContent>
            <List>
              <ListItem>
                <ListItemText
                  primary={t("Daily stipend:")}
                  secondary={`$${Math.round(
                    skillsTrainingPlan.dailyStipendEstimateCents / 100
                  )}`}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={t("Transportation Plan:")}
                  secondary={
                    TransportationSituation[
                      skillsTrainingPlan.transportationSituation
                    ]
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={t("Available Days:")}
                  secondary={Object.entries(
                    skillsTrainingPlan.availableDays || {}
                  )
                    .map(
                      ([day, { start, end }]) =>
                        `${WEEKDAY_MAP[parseInt(day, 10)]}: ${
                          start.hour
                        }:${start.minute.toString().padStart(2, "0")} - ${
                          end.hour
                        }:${end.minute.toString().padStart(2, "0")}`
                    )
                    .join("\n")}
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      )}
      <Alert severity="info" sx={{ marginTop: 2 }}>
        <Typography variant="body2">
          {t("Current training schedule:")}
        </Typography>
        <Typography fontWeight="bold">
          {skillsTrainingPlan.skillsTrainingSchedule}
        </Typography>
      </Alert>
    </Box>
  );
}
