import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "src/SessionBoundary";
import SkillsTrainingOnboardingWizardView from "./SkillsTrainingOnboardingWizardView";

export default function SkillsTrainingOnboardingWizardScreen() {
  const user = useCurrentUser();
  const navigate = useNavigate();

  return (
    <SkillsTrainingOnboardingWizardView
      user={user}
      onFinish={() => navigate("/")}
    />
  );
}
