import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "src/contexts/AppContext";
import createUserChatMessage from "src/firebase/createUserChatMessage";
import { UserAccount } from "src/types/User";
import { trackEvent } from "src/utils";
import useErrorHandler from "src/utils/useErrorHandler";

type Props = {
  user: UserAccount;
};

export default function OnHoldAlert({ user }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  const handleClick = async () => {
    try {
      trackEvent("On Hold Alert - Clicked on Contact");
      await createUserChatMessage(
        {
          text: `I have resolve the situation that had put my account on hold. Can you help reactivate my account? This was the reason I had been moved to on hold: ${user.applicationStatusDetails}`,
          user,
        },
        clients
      );
      navigate("/coach");
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <Alert color="warning">
      <AlertTitle>{t("Your application is currently on hold.")}</AlertTitle>
      {user.applicationStatusDetails && (
        <Typography variant="body2">
          {t("{{reason}}", { reason: user.applicationStatusDetails })}
        </Typography>
      )}
      <Typography
        marginTop={2}
        marginBottom={1}
        variant="body2"
        fontWeight="bold"
      >
        {t("Has this been resolved?")}
      </Typography>
      <Button
        variant="outlined"
        size="small"
        color="inherit"
        onClick={handleClick}
      >
        {t("GET IN TOUCH")}
      </Button>
    </Alert>
  );
}
