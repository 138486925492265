/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLazyQuery } from "@apollo/client";
import {
  ArticleOutlined,
  KeyboardArrowLeft,
  VideoCallOutlined,
  VideocamOffOutlined,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  LinearProgress,
  MobileStepper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { StructuredText } from "react-datocms";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import ReactJoyride from "react-joyride";
import { OnProgressProps } from "react-player/base";
import BackHomeButton from "src/components/Button/BackHomeButton";
import QuizCard from "src/components/Card/QuizCard";
import { CardSkeleton } from "src/components/Skeleton/closet";
import { useAppContext } from "src/contexts/AppContext";
import { useSession } from "src/contexts/AuthContext";
import createFeedback from "src/firebase/createFeedback";
import useJoyrideTutorial from "src/hooks/useJoyrideTutorial";
import { useCurrentUser } from "src/SessionBoundary";
import hasCompletedVideo from "src/utils/hasCompletedVideo";
import useCompleteQuizGrades from "src/utils/useCompleteQuizGrades";
import useErrorHandler from "src/utils/useErrorHandler";
import useResourceProgress from "src/utils/useResourceProgress";
import AppSkeleton from "../AppSkeleton";
import { GetResourcesByUnitDocument } from "../UnitPage/GetResourcesByUnit.generated";
import { GetResourceQuery } from "./GetResource.generated";
import LessonFeedbackModal from "./LessonFeedbackModal"; // Import the feedback modal component
import ResourceScreenHeader from "./ResourceScreenHeader";
import ResourceScreenTextbooks from "./ResourceScreenTextbooks";
import useResourceNavigation from "./useResourceNavigation";

const VideoPlayer = lazy(() => import("src/components/VideoPlayer"));

type Props = {
  resource: NonNullable<GetResourceQuery["resource"]>;
  onBack: () => void;
};

export default function ResourceView({ resource, onBack }: Props) {
  const [isReadModeOn, setIsReadModeOn] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const errorHandler = useErrorHandler();
  const session = useSession();
  const me = useCurrentUser();
  const { findGradeByQuizId } = useCompleteQuizGrades(me.uid);
  const { clients } = useAppContext();
  const {
    isTutorialRunning,
    joyrideSteps,
    handleJoyrideCallback,
    wasTutorialShown,
  } = useJoyrideTutorial({
    steps: [
      {
        disableBeacon: true,
        target: ".intro-header",
        content: t(
          "Excited to have you here! Welcome to your first lesson with Emerge."
        ),
      },
      {
        target: ".video-player",
        content: t("Every lesson has a video covering a key concept!"),
      },
      {
        target: ".quiz-card",
        content: t(
          "Quizzes are here to make sure you’ve got the key points of the video. You have to finish them before continuing!"
        ),
      },
    ],
  });

  const [getResourcesByUnit, { data: allUnitResourcesData }] = useLazyQuery(
    GetResourcesByUnitDocument,
    { onError: errorHandler }
  );

  const allUnitResources = allUnitResourcesData?.allResources;
  const [start, setStart] = useState<number>();
  const [isQuizEnabled, setIsQuizEnabled] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  // initial load
  const [initialized, setInitialized] = useState(false);

  // In ResourceView.tsx

  const { navigateBack, navigateForward } = useResourceNavigation(
    resource,
    wasTutorialShown
  );

  const grade = resource?.quiz
    ? findGradeByQuizId(resource.quiz.id)
    : undefined;

  const { findResourceProgressById, loading: videoProgressLoading } =
    useResourceProgress();

  const videoProgress = useMemo(
    () => findResourceProgressById(resource.id),
    [resource.id, findResourceProgressById]
  );

  // helper variables
  const videoCompleted = videoProgress
    ? hasCompletedVideo(videoProgress.progressFraction) ||
      videoProgress.hasCompleted
    : false;
  const quizCompleted = !!grade || !resource?.quiz;
  const lessonCompleted = videoCompleted && quizCompleted;

  useEffect(() => {
    getResourcesByUnit({ variables: { unitId: resource.unit.id } });
  }, [resource.unit.id, getResourcesByUnit]);

  // Initial video load

  // The subscription to the resource is still live, but we only use it the first time

  useEffect(() => {
    if (initialized) return;

    if (!initialized && !videoProgressLoading) {
      const videoProgress = findResourceProgressById(resource.id);

      if (videoProgress) {
        setStart(videoProgress.progressSeconds);
        setIsQuizEnabled(
          hasCompletedVideo(videoProgress.progressFraction) ||
            !!videoProgress.hasCompleted
        );
      } else {
        setStart(0);
      }
      // Only do this once
      setInitialized(true);
    }
  }, [
    findResourceProgressById,
    initialized,
    resource.id,
    videoProgressLoading,
  ]);

  const handleVideoProgress = useCallback(
    async (state: OnProgressProps) => {
      await session
        .setUserResourceProgress(resource.id, {
          resourceId: resource.id,
          progressFraction: state.played,
          progressSeconds: Math.round(state.playedSeconds),
          hasCompleted: videoProgress?.hasCompleted // // if the person has already completed it once, don't revert (TODO: revisit this logic to just ignore progress once the video ends)
            ? true
            : hasCompletedVideo(state.played)
            ? true
            : undefined,
        })
        .catch(Sentry.captureException);

      if (hasCompletedVideo(state.played)) {
        setIsQuizEnabled(true);
      }
    },
    [resource.id, session, videoProgress?.hasCompleted]
  );

  const handleVideoEnded = useCallback(async () => {
    await session
      .setUserResourceProgress(resource.id, {
        resourceId: resource.id,
        progressFraction: 1,
        progressSeconds: resource.duration * 60,
        hasCompleted: true,
      })
      .catch(Sentry.captureException);

    setIsQuizEnabled(true);
    // Open the feedback modal when the video ends
    setIsFeedbackModalOpen(true);
  }, [resource, session]);

  const handleFeedbackSubmit = async (rating: number) => {
    // Logic to handle feedback submission (e.g., save to Firestore)
    await createFeedback(
      {
        rating,
        resourceId: resource.id,
        type: "theory",
        user: me,
      },
      clients
    ).catch(errorHandler);

    setIsFeedbackModalOpen(false);
  };

  if (!resource || typeof start === "undefined" || videoProgressLoading)
    return <AppSkeleton />;

  // on iOS, the backblaze video is not loading for many devices
  // fallback URL is a temporary fix to let students study
  const videoUrl = isMobileOnly ? resource.fallbackUrl : resource.url;

  return (
    <Box>
      <ReactJoyride
        steps={joyrideSteps}
        continuous
        run={isTutorialRunning}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: theme.palette.primary.main,
          },
        }}
      />
      <BackHomeButton onBack={onBack} />
      <Box className="intro-header">
        <ResourceScreenHeader unit={resource.unit} />
      </Box>
      <Box>
        {!videoCompleted && (
          <Alert color="info" icon={<VideoCallOutlined />}>
            <AlertTitle>{t("Watch This Video Before Moving On!")}</AlertTitle>

            <Box width="100%" marginTop={2}>
              <LinearProgress
                value={videoProgress ? videoProgress.progressFraction * 100 : 0}
                variant="determinate"
              />
              <Stack direction="row">
                <Typography variant="body2" color="text.secondary">
                  {videoProgress
                    ? Math.round(videoProgress.progressFraction * 100)
                    : 0}
                  % {t("watched")}
                </Typography>
              </Stack>
            </Box>
          </Alert>
        )}
      </Box>

      <Grid container paddingLeft={{ md: 4 }}>
        <Grid item xs={12} md={8} marginY={{ md: 8 }}>
          <Box
            sx={[isReadModeOn && { display: "none" }]}
            className="video-player"
          >
            <Suspense fallback={<CardSkeleton />}>
              <VideoPlayer
                start={start}
                url={videoUrl}
                width="100%"
                playsInline
                onProgress={handleVideoProgress}
                progressInterval={5000}
                onError={errorHandler}
                onEnded={handleVideoEnded}
                fallback={<Typography>{t("Loading")}</Typography>}
                controls
              />
            </Suspense>
          </Box>
          <Card className="quiz-card">
            <CardContent>
              {resource.quiz && (
                <QuizCard
                  quiz={resource.quiz}
                  locked={!isQuizEnabled}
                  onFinishQuiz={navigateForward}
                />
              )}
              {resource.summary ? (
                <Box paddingBottom={4} overflow="auto">
                  <StructuredText data={resource.summary as any} />
                </Box>
              ) : (
                <Typography>{resource.description}</Typography>
              )}
            </CardContent>
            <CardActions>
              {resource.textbookChapters.length > 10000 && (
                <Button
                  onClick={() => setIsReadModeOn((pip) => !pip)}
                  startIcon={
                    isReadModeOn ? <VideocamOffOutlined /> : <ArticleOutlined />
                  }
                >
                  {isReadModeOn ? t("View video") : t("Read notes")}
                </Button>
              )}
            </CardActions>
          </Card>
          {isReadModeOn && (
            <Box>
              <ResourceScreenTextbooks chapters={resource.textbookChapters} />
            </Box>
          )}
          {allUnitResources && (
            <MobileStepper
              sx={{
                borderTop: 1,
                borderColor: theme.palette.grey[300],
                position: "fixed",
                bottom: 0,
                width: "100vw",
              }}
              elevation={1}
              variant="text"
              steps={allUnitResources.length}
              position="bottom"
              activeStep={resource.order}
              nextButton={
                <Button
                  disabled={!lessonCompleted}
                  variant="contained"
                  size="large"
                  onClick={async () => await navigateForward()}
                >
                  {resource.order + 1 >= allUnitResources.length // 0 index
                    ? t("Next Unit")
                    : t("Next Lesson")}
                </Button>
              }
              backButton={
                <Button onClick={async () => await navigateBack()}>
                  <KeyboardArrowLeft />
                  {t("Previous")}
                </Button>
              }
            />
          )}
        </Grid>
      </Grid>

      {/* Lesson Feedback Modal */}
      <LessonFeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
        onSubmit={handleFeedbackSubmit} // Handle feedback submission
      />
    </Box>
  );
}
