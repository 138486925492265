import {
  MonitorHeartTwoTone,
  PeopleTwoTone,
  QuestionAnswerTwoTone,
  RocketTwoTone,
  StarTwoTone,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Avatar from "src/components/Avatar";
import { useAppContext } from "src/contexts/AppContext";
import { useCurrentUser } from "src/SessionBoundary";
import { Admin } from "src/types/Admin";
import useErrorHandler from "src/utils/useErrorHandler";
import LoadingTransitionScreen from "../BackgroundSurveyScreen/LoadingTransitionScreen";
import SuccessScreen from "../SuccessScreen";
import { trackEvent } from "./amplitude";

type ReturnTypeConditionalAcceptance = Pick<
  Admin,
  "uid" | "firstName" | "lastName" | "photoUrl" | "about"
>;

const FIRST_LESSON = "55597868";
export default function AutomatedConditionalAcceptanceScreen() {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { clients } = useAppContext();
  const { t } = useTranslation();
  const updateApplicationStatusToConditionalAcceptance = httpsCallable<
    void,
    {
      coach: ReturnTypeConditionalAcceptance;
    }
  >(
    clients.functions,
    "updateApplicationStatusToConditionalAcceptanceSecondGen"
  );
  const [loading, setLoading] = useState(false);
  const errorHandler = useErrorHandler();

  const [assignedCoach, setAssignedCoach] =
    useState<ReturnTypeConditionalAcceptance>();

  useEffect(() => {
    if (loading) return;
    if (assignedCoach) return;
    if (!user.applicationStatus) {
      setLoading(true);
      updateApplicationStatusToConditionalAcceptance()
        .then((res) => setAssignedCoach(res.data.coach))
        .catch(errorHandler)
        .finally(() => setLoading(false));
    }
  }, [
    user,
    navigate,
    updateApplicationStatusToConditionalAcceptance,
    errorHandler,
    loading,
    assignedCoach,
  ]);

  const handleFinish = () => {
    trackEvent("Success Plan: Completed");
    navigate(`/resources/${FIRST_LESSON}?showTutorial=true`, {
      replace: true,
    });
  };

  if (!assignedCoach || loading)
    return (
      <LoadingTransitionScreen
        messages={[
          t("We're assigning you a success coach!"),
          t("This won't take long!"),
          t("Please wait a moment..."),
        ]}
      />
    );

  return (
    <SuccessScreen
      illustration={{
        type: "react",
        component: (
          <Fade in timeout={1000}>
            <Box>
              <Avatar
                sx={{ height: 64, width: 64 }}
                src={assignedCoach.photoUrl}
              />
            </Box>
          </Fade>
        ),
      }}
      title={t("Introducing your Emerge success coach!")}
      content={
        <Fade in timeout={1500}>
          <Box>
            <Card variant="outlined">
              <CardContent
                sx={{ overflowY: "auto", maxHeight: { xs: 250, md: 350 } }}
              >
                <Trans
                  i18nKey="My name is <strong>{{ coach_name }}</strong> and I will be your biggest ally for the rest of this journey! They will text you soon."
                  values={{ coach_name: assignedCoach.firstName }}
                  components={{ strong: <strong /> }}
                />
                {assignedCoach.about && (
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <RocketTwoTone />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Trans
                            i18nKey="<strong>Background:</strong> {{ data }}."
                            values={{ data: assignedCoach.about.background }}
                            components={{ strong: <strong /> }}
                          />
                        }
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                        <MonitorHeartTwoTone />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Trans
                            i18nKey="<strong>Why I Do This:</strong> {{ data }}."
                            values={{ data: assignedCoach.about.why }}
                            components={{ strong: <strong /> }}
                          />
                        }
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                        <QuestionAnswerTwoTone />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Trans
                            i18nKey="<strong>Ask Me About:</strong> {{ data }}."
                            values={{ data: assignedCoach.about.askMeAbout }}
                            components={{ strong: <strong /> }}
                          />
                        }
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                        <PeopleTwoTone />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Trans
                            i18nKey="<strong>What to Expect:</strong> {{ data }}."
                            values={{ data: assignedCoach.about.whatToExpect }}
                            components={{ strong: <strong /> }}
                          />
                        }
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                        <StarTwoTone />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Trans
                            i18nKey="<strong>Favorite Part of the Job:</strong> {{ data }}."
                            values={{
                              data: assignedCoach.about.favoriteJobPart,
                            }}
                            components={{ strong: <strong /> }}
                          />
                        }
                      />
                    </ListItem>
                  </List>
                )}
              </CardContent>
            </Card>
          </Box>
        </Fade>
      }
      okButtonText={t(`Let's Do This!`)}
      onOkButtonClick={handleFinish}
    />
  );
}
