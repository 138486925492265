import {
  UserSkillsTrainingFullPlan,
  UserSkillsTrainingPlan,
} from "src/types/User";

export default function isFullSkillsTrainingPlanGuard(
  plan: UserSkillsTrainingPlan | undefined
): plan is UserSkillsTrainingFullPlan {
  return (
    !!plan && "type" in plan && plan.type === "full" && "availableDays" in plan
  );
}
