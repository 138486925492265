import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Grid, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import SelectInput from "src/components/Card/SelectInput";
import TextInput from "src/components/Input/TextInput";
import USState from "src/types/states";
import isValidZipCode from "src/utils/isValidZipcode";

/**
 * Type definition for the address form data.
 */
export type PostalAddress = {
  name: string; // e.g. "John Alfred Smith"
  street: string; // e.g. "123 Main Street"
  street2?: string; // e.g. "APT A" (optional)
  city: string; // e.g. "San Francisco"
  state: string; // e.g. "CA"
  zipcode: string; // e.g. "90401"
};

/**
 * Props accepted by AddressForm.
 */
type AddressFormProps = {
  defaultValues?: Partial<PostalAddress>;
  onSubmit: (data: PostalAddress) => void | Promise<void>;
  onBack: () => void;
};

export default function EligibilityPostalAddressForm({
  defaultValues = {},
  onSubmit,
  onBack,
}: AddressFormProps) {
  const { t } = useTranslation();

  // Set up React Hook Form
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<PostalAddress>({
    mode: "onTouched",
    defaultValues,
  });

  return (
    <Box>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <Stack spacing={4} marginBottom={6}>
          <TextInput
            name="name"
            prompt={t("Recipient Full Name")}
            helperText={t(
              "Can be your name or someone else's depending on which address you use."
            )}
            control={control}
            required
          />
          <TextInput
            name="street"
            prompt={t("Street Address")}
            control={control}
            required
          />
          <TextInput
            name="street2"
            prompt={t("Street Address 2 (optional)")}
            control={control}
          />
          <TextInput
            name="city"
            prompt={t("City")}
            control={control}
            required
          />
          <SelectInput
            name="state"
            prompt={t("State")}
            control={control}
            items={Object.entries(USState).map((entry) => ({
              value: entry[0],
              name: entry[1],
            }))}
            required
          />
          <TextInput
            name="zipcode"
            prompt={t("Zipcode")}
            control={control}
            rules={{
              validate: (value) =>
                isValidZipCode(value) || t("Invalid ZIP code"),
            }}
            required
          />
        </Stack>

        <Grid container justifyContent="space-between">
          <Button onClick={onBack}>
            <ArrowBack />
            &nbsp;
            {t("Back")}
          </Button>
          <SubmitButton loading={isSubmitting}>{t("Continue")}</SubmitButton>
        </Grid>
      </form>
    </Box>
  );
}
