import { Box, Grid, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { differenceInDays } from "date-fns";
import { t } from "i18next";
import { useMemo } from "react";
import { Partner } from "src/types/Partner";
import { UserSummary } from "src/types/UserSummary";
import formatOptionalTimestamp from "src/utils/formatOptionalTimestamp";
import StatisticsCard from "../AdminScreen/StatisticsCard";
import StatusSummaryCard from "../AdminScreen/StatusSummaryCard";
import StudentStatisticsCard from "../AdminScreen/StudentStatisticsCard";
import PartnerActionItemCell from "./PartnerActionItemCell";
import ReferralAdditionalInformationCell from "./ReferralAdditionalInformationCell";
import simplifyUserStatus from "./simplifyStatus";
import UserSimplifiedStatusChip from "./UserSimplifiedStatusChip";

type Props = {
  referrals: UserSummary[];
  partner: Partner;
};

export default function PartnerReferralTable({ referrals, partner }: Props) {
  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 180,
      valueFormatter: (value: string) => formatOptionalTimestamp(value),
    },
    {
      field: "lastLoggedIn",
      headerName: "Last Seen",
      width: 180,
      valueFormatter: (value: string) => formatOptionalTimestamp(value),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => (
        <UserSimplifiedStatusChip status={params.value} />
      ),
    },
    {
      field: "additionalInformation",
      headerName: "Additional Information",
      width: 200,
      renderCell: (params) => (
        <ReferralAdditionalInformationCell summary={params.value} />
      ),
    },
    {
      field: "howToHelp",
      flex: 1,
      headerName: "How to Help?",
      renderCell: (params) => <PartnerActionItemCell status={params.value} />,
    },
  ];

  const data = useMemo(
    () =>
      referrals.map((referral) => ({
        ...referral,
        id: referral.uid,
        status: simplifyUserStatus(referral),
        howToHelp: simplifyUserStatus(referral),
        additionalInformation: referral,
        statusDetails:
          referral.employmentStatusDetails ||
          referral.enrollmentStatusDetails ||
          referral.applicationStatusDetails ||
          "",
      })),
    [referrals]
  );

  const activeReferrals = data.filter(
    (item) =>
      item.status === "Employed" ||
      item.status === "Skills Training" ||
      item.status === "Licensed" ||
      item.status === "Theory Training" ||
      item.status === "Incomplete Application"
  );

  const enrollments = data.filter(
    (item) =>
      item.status === "Employed" ||
      item.status === "Skills Training" ||
      item.status === "Licensed"
  );

  const newReferrals = data.filter(
    (referral) =>
      differenceInDays(new Date(), new Date(referral.createdAt)) <= 7
  );

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <StatusSummaryCard
            title="Active Referrals"
            students={activeReferrals}
            groupByCallback={(item) => item.status!}
            variant="outlined"
            disableNavigation
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          {partner.paidBonusAmount ? (
            <StatisticsCard
              value={`$ ${enrollments.length * partner.paidBonusAmount}.00`}
              title={t("Your Earnings")}
              variant="outlined"
            />
          ) : (
            <StudentStatisticsCard
              metric={`${enrollments.length} Enrollments`}
              title={t("Total Enrollments")}
              students={enrollments}
              disableNavigation
              variant="outlined"
            />
          )}
        </Grid>
        <Grid item xs={12} sm={3}>
          <StudentStatisticsCard
            disableNavigation
            title={t("Recently Joined")}
            students={newReferrals}
            metric={t("{{ count }} New", {
              count: newReferrals.length,
            })}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Box marginTop={4}>
        <DataGridPro
          rows={data}
          columns={columns}
          pageSizeOptions={[5, 10, 20]}
          getRowId={(row) => row.uid}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
            },
          }}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
                page: 0,
              },
            },
          }}
        />
        <Typography color="text.secondary" variant="body2">
          {t("Please note that updates to this table happen daily at 5am ET.")}
        </Typography>
      </Box>
    </Box>
  );
}
