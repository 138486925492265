import { differenceInDays } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import createReimbursement from "src/firebase/createReimbursement";
import createStudentMilestonePlan from "src/firebase/createStudentEmploymentMilestonePlan";
import deleteMilestone from "src/firebase/deleteMilestone";
import fetchStudentPlanMilestones from "src/firebase/fetchStudentPlanMilestones";
import updateMilestone from "src/firebase/updateMilestone";
import updateMilestoneCompletion from "src/firebase/updateMilestoneCompletion";
import { DocumentProofForm } from "src/pages/ClientScreen/StudentPlanTab/DocumentProofSection";
import { StudentPlanMilestoneFormData } from "src/pages/ClientScreen/StudentPlanTab/StudentPlanMilestoneForm";
import { useSnackbarContext } from "src/SnackbarProvider";
import MilestoneType from "src/types/MilestoneType.enum";
import { Reimbursement } from "src/types/Reimbursement";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserAccount } from "src/types/User";
import isMilestoneOverdue from "src/utils/isMilestoneOverdue";
import replaceItem from "src/utils/replaceItem";
import sortBy from "src/utils/sortBy";
import useErrorHandler from "src/utils/useErrorHandler";
import isUpcomingMilestone from "./isUpcomingMilestone";

export default function useUserMilestones(
  user: UserAccount,
  cutoffDays?: number
) {
  const [milestones, setMilestones] = useState<StudentPlanMilestone[]>();
  const [loading, setLoading] = useState(true);
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();
  const { alert } = useSnackbarContext();

  useEffect(() => {
    fetchStudentPlanMilestones(user.uid, clients)
      .then((milestones) => {
        const filteredMilestones = cutoffDays
          ? sortBy(
              milestones.filter((milestone) => {
                if (milestone.completedAt) return false;
                const daysUntilDue = differenceInDays(
                  new Date(milestone.date),
                  new Date()
                );
                return daysUntilDue <= cutoffDays || daysUntilDue < 0;
              }),
              (item) => new Date(item.date).getTime()
            )
          : sortBy(milestones, (item) => new Date(item.date).getTime());
        setMilestones(filteredMilestones);
      })
      .catch(errorHandler)
      .finally(() => setLoading(false));
  }, [clients, cutoffDays, errorHandler, user.uid]);

  const findMilestone = useCallback(
    (milestoneType: MilestoneType) =>
      milestones?.find((milestone) => milestone.type === milestoneType),
    [milestones]
  );

  // Create Milestone
  const createMilestone = async (data: StudentPlanMilestoneFormData) => {
    try {
      const milestone = await createStudentMilestonePlan(
        { ...data, user },
        clients
      );
      setMilestones((prev) =>
        sortBy([...(prev || []), milestone], (item) =>
          new Date(item.date).getTime()
        )
      );
      alert("success", "Milestone created successfully"); // Notify success
    } catch (error) {
      errorHandler(error);
    }
  };

  // Update Milestone
  const updateMilestoneById = async (
    milestone: StudentPlanMilestone,
    data: StudentPlanMilestoneFormData
  ) => {
    try {
      await updateMilestone({ milestone, user, ...data }, clients);
      setMilestones((prev) =>
        prev
          ? replaceItem(
              prev,
              milestone,
              { ...milestone, ...data, date: data.date.toISOString() },
              (item) => item.uid === milestone.uid
            )
          : []
      );
      alert("success", "Milestone updated successfully"); // Notify success
    } catch (error) {
      errorHandler(error);
    }
  };

  // Mark milestone as completed
  const completeMilestone = async (
    milestone: StudentPlanMilestone,
    documentation?: DocumentProofForm
  ) => {
    try {
      let reimbursement: Reimbursement | undefined;
      if (documentation) {
        if (documentation.receiptFile) {
          reimbursement = await createReimbursement(
            {
              user,
              file: documentation.receiptFile,
              milestoneId: milestone.uid,
              title: milestone.title,
              amount: documentation.reimbursementAmountRequested || 0,
            },
            clients
          );
        }
      }

      const updated = await updateMilestoneCompletion(
        {
          user,
          milestone,
          completed: true,
          completionProofFile: documentation?.proofFile,
          reimbursementRequest: reimbursement,
        },
        clients
      );
      const { completedAt, completedAtFirestoreTimestamp } = updated;

      setMilestones((prev) =>
        prev
          ? replaceItem(
              prev,
              milestone,
              { ...milestone, completedAt, completedAtFirestoreTimestamp },
              (item) => item.uid === milestone.uid
            )
          : []
      );
      alert("success", "Milestone marked as completed!"); // Notify success
    } catch (error) {
      errorHandler(error);
    }
  };

  const undoMilestoneCompletion = async (milestone: StudentPlanMilestone) => {
    try {
      await updateMilestoneCompletion(
        { user, milestone, completed: false },
        clients
      );
      setMilestones((prev) =>
        prev
          ? replaceItem(
              prev,
              milestone,
              {
                ...milestone,
                completedAt: null,
                completedAtFirestoreTimestamp: null,
              },
              (item) => item.uid === milestone.uid
            )
          : []
      );
      alert("success", "Milestone marked as completed!"); // Notify success
    } catch (error) {
      errorHandler(error);
    }
  };

  // Delete Milestone
  const deleteMilestoneById = async (milestone: StudentPlanMilestone) => {
    try {
      await deleteMilestone({ milestone, user }, clients);
      setMilestones((prev) =>
        prev?.filter((item) => item.uid !== milestone.uid)
      );
      alert("success", "Milestone deleted successfully"); // Notify success
    } catch (error) {
      errorHandler(error);
    }
  };

  const overdueMilestones = useMemo(() => {
    if (!milestones) return [];
    return milestones.filter(isMilestoneOverdue);
  }, [milestones]);

  const upcomingMilestones = useMemo(() => {
    if (!milestones) return [];
    return milestones.filter(isUpcomingMilestone);
  }, [milestones]);

  return {
    loading,
    milestones,
    overdueMilestones,
    upcomingMilestones,
    createMilestone,
    updateMilestoneById,
    undoMilestoneCompletion,
    completeMilestone,
    deleteMilestoneById,
    findMilestone,
  };
}
