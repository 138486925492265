import { Alert, Box, Snackbar } from "@mui/material";
import { AlertProps } from "@mui/material/Alert";
import Lottie from "lottie-react";
import React, { createContext, useContext, useMemo, useState } from "react";
import Confetti from "src/assets/Lotties/Confetti.json";

type SnackbarContextType = {
  alert: (
    severity: AlertProps["severity"],
    message: string,
    disableAutoHide?: boolean
  ) => void;
  close: () => void;
  triggerConfetti: () => void;
};

const SnackbarContext = createContext<SnackbarContextType>(
  {} as SnackbarContextType
);

export function useSnackbarContext(): SnackbarContextType {
  return useContext(SnackbarContext);
}

export default function SnackbarProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [open, setOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentSeverity, setCurrentSeverity] =
    useState<AlertProps["severity"]>("info");
  const [autoHideOn, setAutoHideOn] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);

  function handleClose() {
    setAutoHideOn(true);
    setOpen(false);
  }

  const triggerConfetti = () => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 3000);
  };

  const snackbarContext = useMemo(
    (): SnackbarContextType => ({
      alert: (severity, message, disableAutoHide) => {
        setAutoHideOn(!disableAutoHide);
        setCurrentSeverity(severity);
        setCurrentMessage(message);
        setOpen(true);
      },
      close: () => {
        handleClose();
      },
      triggerConfetti,
    }),
    []
  );

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={autoHideOn ? 6000 : null}
        onClose={() => handleClose()}
      >
        <Alert severity={currentSeverity}>{currentMessage}</Alert>
      </Snackbar>
      {showConfetti && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pointerEvents: "none",
            overflow: "hidden",
          }}
        >
          <Lottie
            animationData={Confetti}
            autoplay
            loop={false}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        </Box>
      )}
      <SnackbarContext.Provider value={snackbarContext}>
        {children}
      </SnackbarContext.Provider>
    </>
  );
}
