import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TransportationSituation from "src/utils/TransportationSituation";

export default function TransportationSituationSelection({
  defaultValue,
  onBack,
  onSave,
}: {
  defaultValue?: keyof typeof TransportationSituation | null;
  onBack: () => void;
  onSave: (value: keyof typeof TransportationSituation) => void;
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<
    keyof typeof TransportationSituation | null
  >(defaultValue ?? null);

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{t("Your Transportation Plan")}</Typography>
      <Typography variant="body1">
        {t("How will travel back and forth to the school?")}
      </Typography>

      <Stack spacing={2}>
        {Object.keys(TransportationSituation).map((situation: string) => (
          <Card
            key={situation}
            // Outline or highlight the selected card
            variant={selected === situation ? "outlined" : "elevation"}
            onClick={() =>
              setSelected(situation as keyof typeof TransportationSituation)
            }
            sx={{
              cursor: "pointer",
              ...(selected === situation && {
                border: "2px solid #1976d2",
              }),
            }}
          >
            <CardContent>
              <Typography>
                {
                  TransportationSituation[
                    situation as keyof typeof TransportationSituation
                  ]
                }
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Stack>

      <Stack direction="row" spacing={1}>
        <Button variant="outlined" onClick={onBack}>
          {t("Back")}
        </Button>
        <Button
          variant="contained"
          disabled={!selected}
          onClick={() => {
            if (selected) onSave(selected);
          }}
        >
          {t("Next")}
        </Button>
      </Stack>
    </Stack>
  );
}
