import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ActionButtonWithConfirmDialog from "src/components/Button/ActionButtonWithConfirmDialog";
import { YesNoToggleInputBase } from "src/components/Input/YesNoToggleInput";
import { Header20 } from "src/components/Typography";
import { School } from "src/types/School";

interface Props {
  selectedSchool: School;
  onConfirm: (trainingSchedule: string) => void;
  onBackButton: () => void;
}

export default function ConfirmSchoolSelection({
  selectedSchool,
  onConfirm,
  onBackButton,
}: Props) {
  const { t } = useTranslation();
  const [selectedTrainingSchedule, setSelectedTrainingSchedule] = useState<
    string | null
  >(null);
  const [requirementsConfirmation, setRequirementsConfirmation] = useState<
    Record<string, boolean>
  >({});
  const [showError, setShowError] = useState(false);

  const handleToggle = (requirement: string, value: boolean) => {
    setRequirementsConfirmation((prev) => ({
      ...prev,
      [requirement]: value,
    }));

    if (!value) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  const handlSubmit = () => onConfirm(selectedTrainingSchedule!);

  const allRequirementsMet =
    Object.keys(requirementsConfirmation).length ===
      selectedSchool.requirements.length &&
    Object.values(requirementsConfirmation).every((val) => val);

  return (
    <Box>
      <Header20>
        {t(
          `Let's review the requirements for the ${selectedSchool.city} school:`
        )}
      </Header20>

      <Typography variant="body2" gutterBottom marginTop={4}>
        <strong>{t("Can you meet these requirements?")}</strong>
      </Typography>
      {selectedSchool.requirements.map((requirement) => (
        <Box key={requirement} mt={2}>
          <YesNoToggleInputBase
            value={requirementsConfirmation[requirement] || false}
            onChange={(value) => handleToggle(requirement, value)}
            prompt={requirement}
            error={!requirementsConfirmation[requirement]}
          />
        </Box>
      ))}
      {showError && (
        <FormHelperText error>
          {t(
            "You must say 'Yes' to all requirements to be eligible. If not, please go back and choose a different school."
          )}
        </FormHelperText>
      )}

      <Typography variant="body2" gutterBottom mt={4}>
        <strong>{t(`Which of the training schedules works for you?`)}</strong>
      </Typography>
      <Select
        fullWidth
        value={selectedTrainingSchedule || ""}
        onChange={(e) => setSelectedTrainingSchedule(e.target.value as string)}
      >
        {selectedSchool.trainingSchedules.map((schedule) => (
          <MenuItem key={schedule} value={schedule}>
            {schedule}
          </MenuItem>
        ))}
      </Select>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginTop={4}
      >
        <Button onClick={onBackButton}>{t("Back")}</Button>
        <ActionButtonWithConfirmDialog
          variant="contained"
          onAction={handlSubmit}
          disabled={!selectedTrainingSchedule || !allRequirementsMet}
          buttonLabel={t("Confirm")}
          title={t("Review and Confirm")}
          buttonColor="primary"
          buttonVariant="contained"
          description={t(
            "You’ll only start at this school AFTER completing your online training! The faster you move, the sooner you’ll hit the driving yard. The ball’s in your court."
          )}
        />
      </Stack>
    </Box>
  );
}
