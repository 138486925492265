const features = [
  "SKILLS_TRAINING_REVAMP",
  "PODCAST",
  "INCOME_VERIFICATION",
] as const;
type Feature = typeof features[number];

export default function featureEnabled(name: Feature): boolean {
  if (process.env.NODE_ENV === "development") return true;
  return process.env[`REACT_APP_ENABLE_${name}`] === "true";
}
