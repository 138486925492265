import { addDays, differenceInDays } from "date-fns";
import { IncomeCalcFormData } from "src/utils/Income";
import { CalculationMethod } from "./EligibilityIncomeMethodForm";

/**
 * Computes the projected 6‑month total income.
 *
 * For YTD:
 *  - Uses ytdTotalGross and ytdFirstPayDate to calculate the average daily wage,
 *    then multiplies that by the number of days in the 6‑month period.
 *
 * For "straight" and "average":
 *  - Assumes all stubs represent a recurring pay schedule (e.g. from one job).
 *  - Computes the weighted average daily rate as (sum(grossPay) / sum(days in each stub))
 *    and then multiplies by the total number of days in the 6‑month period.
 *
 * For "intermittent":
 *  - Integrates day-by-day over the 6‑month window.
 *  - For each day in the window, if the day is covered by a stub, the daily contribution is
 *    (grossPay / total days in that stub’s period).
 *  - This approach naturally sums overlapping incomes.
 *
 * @param calculationMethod - one of "straight", "average", "ytd", or "intermittent"
 * @param data - IncomeCalcFormData with stubs (each with startDate, endDate, grossPay) and for YTD, ytdTotalGross and ytdFirstPayDate.
 * @param minDate - Start date of the 6‑month window.
 * @param maxDate - End date of the 6‑month window.
 * @returns The projected 6‑month total income.
 */
export default function computeSixMonthIncomeTotal(
  calculationMethod: CalculationMethod,
  data: IncomeCalcFormData,
  minDate: Date,
  maxDate: Date
): number {
  const SIX_MONTH_DAYS = differenceInDays(maxDate, minDate) + 1;

  if (calculationMethod === "ytd") {
    if (!data.ytdTotalGross || !data.ytdFirstPayDate) return 0;
    const daysWorked = differenceInDays(maxDate, data.ytdFirstPayDate) + 1;
    const avgDailyWage = data.ytdTotalGross / daysWorked;
    return avgDailyWage * SIX_MONTH_DAYS;
  }
  if (calculationMethod === "straight" || calculationMethod === "average") {
    if (data.stubs.length === 0) return 0;
    // For recurring pay: assume stubs are representative of a regular pay schedule.
    let totalGross = 0;
    let totalStubDays = 0;
    data.stubs.forEach((stub) => {
      if (stub.startDate && stub.endDate) {
        const stubStart = new Date(stub.startDate);
        const stubEnd = new Date(stub.endDate);
        const days = differenceInDays(stubEnd, stubStart) + 1;
        totalGross += stub.grossPay || 0;
        totalStubDays += days;
      }
    });
    const avgDailyRate = totalStubDays ? totalGross / totalStubDays : 0;
    return avgDailyRate * SIX_MONTH_DAYS;
  }
  if (calculationMethod === "intermittent") {
    let totalContribution = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < SIX_MONTH_DAYS; i++) {
      const currentDay = addDays(minDate, i);
      let dailyIncome = 0;
      data.stubs.forEach((stub) => {
        if (stub.startDate && stub.endDate) {
          const stubStart = new Date(stub.startDate);
          const stubEnd = new Date(stub.endDate);
          if (currentDay >= stubStart && currentDay <= stubEnd) {
            const daysInStub = differenceInDays(stubEnd, stubStart) + 1;
            dailyIncome +=
              (stub.incomeStatus === "noIncome" ? 0 : stub.grossPay) /
              daysInStub;
          }
        }
      });
      totalContribution += dailyIncome;
    }
    return totalContribution;
  }
  return 0;
}
