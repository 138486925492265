import { Box, Typography, TypographyProps } from "@mui/material";
import { SimplifiedUserStatus } from "./simplifyStatus";

type Props = {
  status?: SimplifiedUserStatus;
} & TypographyProps;

function statusToActionItem(status: SimplifiedUserStatus): string {
  switch (status) {
    case "No Active Program":
      return "";
    case "Incomplete Application":
      return "Can you help us nudge the participant? We’re sending reminders and texting them, but they have not finished their application. It takes less than 10 minutes to complete.";
    case "Inactive":
      return "Can you help us reach the participant? They haven’t made any progress or responded to our texts or emails in the past three weeks.";
    case "Withdrawn":
      return "Despite our best efforts to retain them, they have decided to drop out from the program. If you can, we'd love your help coaching them up from your side.";
    case "Rejected":
      return "";
    case "Theory Training":
      return "";
    case "Skills Training":
      return "";
    case "Licensed":
      return "Our team is working on finding a job opportunity for the participant. If you have any leads, let us know!";
    case "Employed":
      return "";
    default:
      return "";
  }
}
export default function PartnerActionItemCell({ status, ...rest }: Props) {
  if (!status) return <Typography>-</Typography>;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Typography variant="body2" {...rest}>
        {statusToActionItem(status)}
      </Typography>
    </Box>
  );
}
