import {
  Box,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { QuizQuestionRecord, QuizRecord } from "src/clients/schema";
import { useCurrentUser } from "src/SessionBoundary";
import hasPassedQuiz from "src/utils/hasPassedQuiz";
import useCompleteQuizGrades from "src/utils/useCompleteQuizGrades";
import Button from "../Button";

interface Props {
  exam: Pick<QuizRecord, "id" | "name"> & {
    questions: Pick<QuizQuestionRecord, "id">[];
  };
}

export default function ExamCard({ exam }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useCurrentUser();
  const { findGradeByQuizId } = useCompleteQuizGrades(user.uid);
  const grade = findGradeByQuizId(exam.id);

  const handleNavigate = () => navigate(`/quizzes/${exam.id}`);

  if (grade)
    return (
      <Card onClick={handleNavigate}>
        <CardContent>
          <Stack spacing={2}>
            <Box>
              <Typography variant="body2">{exam.name}</Typography>
              <Stack direction="row" spacing={1}>
                {hasPassedQuiz(grade) ? (
                  <Typography variant="body2" color="green">
                    {t("Passed")}
                  </Typography>
                ) : (
                  <Typography variant="body2" color="red">
                    {t("Failed")}
                  </Typography>
                )}
                <Typography variant="body2">
                  {grade.totalCorrectAnswers} / {grade.totalQuestions} (
                  {Math.round(grade.grade * 100)}%)
                </Typography>
              </Stack>
            </Box>
            <Button variant="outlined">{t("Re-Take")}</Button>
          </Stack>
        </CardContent>
      </Card>
    );

  return (
    <Card onClick={handleNavigate} elevation={1} sx={{ cursor: "pointer" }}>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="subtitle2">{exam.name}</Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography fontWeight="semibold">
                {exam.questions.length}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("questions")}
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight="semibold">
                {Math.round(exam.questions.length * 0.8)} {t("correct answers")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("needed to pass")}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
      <CardActions>
        <Button variant="contained">{t("Start")}</Button>
      </CardActions>
    </Card>
  );
}
