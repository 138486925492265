import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license";
import { PostHogProvider } from "posthog-js/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { AppContextProvider } from "./contexts/AppContext";
import { AuthProvider } from "./contexts/AuthContext";
import "./i18n/i18nConfig";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ServiceWorkerSnackbar from "./ServiceWorkerSnackbar";
import { theme } from "./theme";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

if (process.env.REACT_APP_MUI_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
}

// Get the container where we want to render our app
const container = document.getElementById("root");
// Create the root using React 18's createRoot
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <Router>
        <ServiceWorkerSnackbar />
        <AppContextProvider>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </AuthProvider>
        </AppContextProvider>
      </Router>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
