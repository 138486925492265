import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, Modal, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Empty from "src/components/Empty";
import ModalContainer from "src/components/ModalContainer";
import AppSkeleton from "src/pages/AppSkeleton";
import { useSessionAccountInformation } from "src/SessionBoundary";
import { useSnackbarContext } from "src/SnackbarProvider";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserAccount, UserType } from "src/types/User";
import useUserMilestones from "src/utils/useUserMilestones";
import { DocumentProofForm } from "./DocumentProofSection";
import MilestoneCard from "./MilestoneCard";
import MinifiedMilestoneCard from "./MinifiedMilestoneCard";
import OverdueMilestoneCheckInModal from "./OverdueMilestoneCheckInModal";
import StudentPlanMilestoneForm, {
  REQUIRED_MILESTONES,
  StudentPlanMilestoneFormData,
} from "./StudentPlanMilestoneForm";

interface Props {
  client: UserAccount;
  hideButtons?: boolean;
  onlyShowUpcomingMilestones?: boolean;
  onReviewSuccessPlan?: () => void;
}

export default function StudentPlanTab({
  client,
  onReviewSuccessPlan,
  hideButtons = false,
  onlyShowUpcomingMilestones = false,
}: Props): JSX.Element {
  const { type } = useSessionAccountInformation();

  const {
    milestones,
    loading,
    overdueMilestones,
    upcomingMilestones,
    createMilestone,
    updateMilestoneById,
    completeMilestone,
    undoMilestoneCompletion,
    deleteMilestoneById,
  } = useUserMilestones(client);

  const [isCreating, setIsCreating] = useState(false);
  const [editingMilestone, setEditingMilestone] =
    useState<StudentPlanMilestone | null>(null); // Ensure null is used to reset state
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarContext = useSnackbarContext();

  const handleCreateMilestone = async (data: StudentPlanMilestoneFormData) => {
    await createMilestone(data);
    setIsCreating(false); // Close the modal after milestone creation
  };

  const handleUpdateMilestone = async (
    milestone: StudentPlanMilestone,
    data: StudentPlanMilestoneFormData
  ) => {
    await updateMilestoneById(milestone, data);
    setEditingMilestone(null); // Close the modal after milestone update
  };

  const handleDeletion = async () => {
    if (!editingMilestone) return; // type narrowing
    await deleteMilestoneById(editingMilestone);
    setEditingMilestone(null);
  };

  const handleFormClose = () => setIsCreating(false);

  if (loading || !milestones) return <AppSkeleton />;

  const milestonesToShow = onlyShowUpcomingMilestones
    ? upcomingMilestones
    : milestones;

  const latestOverdueMilestone =
    overdueMilestones.length > 0
      ? overdueMilestones[overdueMilestones.length - 1]
      : undefined;

  const availableMilestoneTypes = REQUIRED_MILESTONES.filter(
    (milestone) => !milestones.some((item) => item.type === milestone.type)
  );
  return (
    <Stack>
      <Stack spacing={2}>
        {milestonesToShow.length > 0 &&
          milestonesToShow.map((milestone) =>
            hideButtons ? (
              <MinifiedMilestoneCard milestone={milestone} />
            ) : (
              <MilestoneCard
                milestone={milestone}
                handleCompletion={(completionProof) =>
                  completeMilestone(milestone, completionProof)
                }
                handleUndoCompletion={() => undoMilestoneCompletion(milestone)}
                handleEdit={() => setEditingMilestone(milestone)}
                key={milestone.uid}
              />
            )
          )}
      </Stack>

      {!milestonesToShow.length &&
        (onlyShowUpcomingMilestones ? (
          <Empty
            description="Nothing is coming up in the next two weeks!"
            cta={{
              label: t("Review Success Plan"),
              onClick: () =>
                onReviewSuccessPlan
                  ? onReviewSuccessPlan()
                  : navigate("/success-plan"),
              variant: "text",
            }}
          />
        ) : (
          <Empty
            cta={{
              onClick: () => setIsCreating(true),
              label: t("Create Milestone"),
            }}
          />
        ))}

      {!hideButtons && (
        <Box marginLeft="auto" sx={{ marginTop: 4 }}>
          <Button
            onClick={() => setIsCreating(true)}
            variant="outlined"
            startIcon={<Add />}
          >
            {t("Create Milestone")}
          </Button>
        </Box>
      )}

      {/* CREATE MODAL */}
      <Modal open={isCreating} onClose={handleFormClose}>
        <div>
          <ModalContainer>
            <Card>
              <CardContent>
                <StudentPlanMilestoneForm
                  onSubmit={handleCreateMilestone} // Call custom handler to close the modal
                  availableMilestoneTypes={availableMilestoneTypes}
                />
              </CardContent>
            </Card>
          </ModalContainer>
        </div>
      </Modal>

      {/* EDIT MODAL */}
      <Modal
        open={!!editingMilestone}
        onClose={() => setEditingMilestone(null)} // Reset to null on close
      >
        <div>
          <ModalContainer>
            <Card>
              <CardContent>
                {editingMilestone && (
                  <StudentPlanMilestoneForm
                    onSubmit={(data) =>
                      handleUpdateMilestone(editingMilestone, data)
                    } // Call custom handler
                    defaultValues={{
                      ...editingMilestone,
                      date: new Date(editingMilestone.date),
                    }}
                    availableMilestoneTypes={
                      REQUIRED_MILESTONES.find(
                        (milestone) => milestone.type === editingMilestone.type
                      )
                        ? [
                            REQUIRED_MILESTONES.find(
                              (milestone) =>
                                milestone.type === editingMilestone.type
                            )!,
                          ]
                        : []
                    }
                    onDelete={handleDeletion}
                  />
                )}
              </CardContent>
            </Card>
          </ModalContainer>
        </div>
      </Modal>

      {/* OVERDUE MODAL */}
      {type === UserType.User && (
        <OverdueMilestoneCheckInModal
          overdueMilestone={latestOverdueMilestone}
          onComplete={(proofFiles?: DocumentProofForm) => {
            snackbarContext.triggerConfetti();
            completeMilestone(latestOverdueMilestone!, proofFiles);
          }}
          onReschedule={() => setEditingMilestone(latestOverdueMilestone!)}
        />
      )}
    </Stack>
  );
}
