import {
  HandshakeTwoTone,
  PeopleTwoTone,
  PrivacyTipTwoTone,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BackHomeButton from "src/components/Button/BackHomeButton";
import ButtonWithDialogExplanation from "src/components/Button/ButtonWithDialogExplanation";
import FloatingVideo from "src/components/FloatingVideo";
import { useAppContext } from "src/contexts/AppContext";
import fetchReferencesCount from "src/firebase/fetchReferencesCount";
import fetchSchool from "src/firebase/fetchSchool";
import updateUserJobPreference from "src/firebase/updateUserJobPreference";
import { useCurrentUser } from "src/SessionBoundary";
import { School } from "src/types/School";
import { CLP_ID, SUCCESS_PLAN_EXPLAINED_URL } from "src/utils/constants";
import useErrorHandler from "src/utils/useErrorHandler";
import ReferenceOnboardingSection from "../Participant/ReferenceOnboardingSection";
import { SliderExplanationStepProp } from "../SliderExplanationScreen";
import { trackEvent } from "./amplitude";
import JobPreferenceForm, { JobPreferenceFormData } from "./JobPreferenceForm";
import { MIN_NUMBER_REFERENCES } from "./OnboardingScreen";
import SchoolSelectionForm from "./SchoolSelectionForm";
import SuccessPlanReview from "./SuccessPlanReview";
import TheoryStudyPlanScreen from "./TheoryStudyPlanScreen";

// Define constants for each step
enum STEPS {
  INTRO_SUCCESS_PLAN,
  THEORY_STUDY_PLAN,
  SCHOOL_SELECTION,
  JOB_PREFERENCES,
  SUPPORT_NETWORK,
  SUCCESS_PLAN_REVIEW,
}

const AMPLITUDE_EVENT_NAMES: Record<STEPS, string> = {
  [STEPS.INTRO_SUCCESS_PLAN]: "Success Plan: Intro Screen Completed",
  [STEPS.THEORY_STUDY_PLAN]: "Success Plan: Theory Study Plan Completed",
  [STEPS.SCHOOL_SELECTION]: "Success Plan: School Selection Completed",
  [STEPS.JOB_PREFERENCES]: "Success Plan: Job Preferences Form Completed",
  [STEPS.SUPPORT_NETWORK]: "Success Plan: Support Network Form Completed",
  [STEPS.SUCCESS_PLAN_REVIEW]: "Success Plan: Plan Review Completed",
};

const LOADING_STEP = -1 as const;

const steps: SliderExplanationStepProp[] = [
  {
    type: "component",
    icon: PeopleTwoTone,
    title: "Your support network is crucial to success",
    titleProps: { fontWeight: "bold" },
    body: "Having friends, family, counselors and mentors backing you through training significantly improves success rates.",
  },
  {
    type: "component",
    icon: HandshakeTwoTone,
    title: "How we use this information",
    titleProps: { fontWeight: "bold" },
    body: "We send occasional updates to your support network, helping them cheer you on during key milestones.",
  },
  {
    type: "component",
    icon: PrivacyTipTwoTone,
    title: "Your privacy matters",
    titleProps: { fontWeight: "bold" },
    body: "We only share necessary updates with your supporters",
  },
];

export default function SuccessPlanOnboardingScreen() {
  const user = useCurrentUser();
  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hasAddedReferences, setHasAddedReferences] = useState<boolean>();
  // State to track the current step
  const [currentStep, setCurrentStep] = useState<number>(LOADING_STEP);
  const [finished, setFinished] = useState<boolean>(false);
  const [playVideo, setPlayVideo] = useState<boolean>(false);
  const [selectedSchool, setSelectedSchool] = useState<School>();

  useEffect(() => {
    fetchReferencesCount(user.uid, clients)
      .then((count) => setHasAddedReferences(count >= MIN_NUMBER_REFERENCES))
      .catch(errorHandler);
  }, [clients, errorHandler, user.uid]);

  useEffect(() => {
    if (!user.schoolId) return;
    if (selectedSchool) return;
    fetchSchool(user.schoolId, clients)
      .then(setSelectedSchool)
      .catch(errorHandler);
  }, [clients, errorHandler, selectedSchool, user.schoolId]);

  // Determine the initial step based on user progress
  useEffect(() => {
    // only set the initial step if it hasn't been set yet
    if (currentStep !== LOADING_STEP) return;

    if (!user.theoryTrainingPlan) {
      setCurrentStep(STEPS.INTRO_SUCCESS_PLAN);
    } else if (!user.schoolId) {
      setCurrentStep(STEPS.SCHOOL_SELECTION);
    } else if (!user.jobPreference) {
      setCurrentStep(STEPS.JOB_PREFERENCES);
    } else if (!hasAddedReferences) {
      setCurrentStep(STEPS.SUPPORT_NETWORK);
    } else {
      setCurrentStep(STEPS.SUCCESS_PLAN_REVIEW);
    }
  }, [currentStep, user, hasAddedReferences]);

  // Handler for going to the next step
  const handleNext = () => {
    trackEvent(AMPLITUDE_EVENT_NAMES[currentStep as STEPS]);
    setCurrentStep((prevStep) => prevStep + 1);
  };

  // Handler for going to the previous step, excluding Video Ask
  const handleBack = () => {
    if (currentStep > STEPS.THEORY_STUDY_PLAN) {
      // Start at INTRO_SUCCESS_PLAN and disable back to VIDEO_ASK
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  // Handler for job preferences submission
  const handleSubmitJobPreferences = (data: JobPreferenceFormData) => {
    updateUserJobPreference({ user, jobPreference: data }, clients)
      .then(handleNext)
      .catch(errorHandler);
  };

  const handleFinish = () => {
    trackEvent(AMPLITUDE_EVENT_NAMES[currentStep as STEPS]);
    setFinished(true);
  };

  if (finished) {
    navigate("/onboarding/finish");
  }

  return (
    <Box
      onClick={() => {
        if (!playVideo) setPlayVideo(true);
      }}
    >
      <Box>
        <LinearProgress
          variant="determinate"
          value={
            (currentStep * 100) / Object.values(AMPLITUDE_EVENT_NAMES).length
          }
        />
      </Box>
      {currentStep === STEPS.INTRO_SUCCESS_PLAN && (
        <Card>
          <CardHeader title={t("Let's build your success plan")} />
          <CardContent>
            <SuccessPlanReview onContinue={handleNext} />
          </CardContent>
        </Card>
      )}

      {currentStep > STEPS.THEORY_STUDY_PLAN && (
        <BackHomeButton
          onBack={handleBack}
          label={t("Back to previous step")}
        />
      )}

      {currentStep === STEPS.THEORY_STUDY_PLAN && (
        <Card>
          <CardHeader title={t("Build Your Online Training Plan")} />
          <CardContent>
            <TheoryStudyPlanScreen courseId={CLP_ID} onSuccess={handleNext} />
          </CardContent>
        </Card>
      )}

      {currentStep === STEPS.SCHOOL_SELECTION && (
        <Card>
          <CardHeader
            title={t("Build Your Skills Training Plan")}
            subheader={t(
              "Once you complete your self-paced theory training, you’ll move on to hands-on truck driving. This phase takes about 120 hours and can be completed in as little as 4 weeks."
            )}
          />
          <CardContent>
            <SchoolSelectionForm
              user={user}
              onSuccess={(school) => {
                setSelectedSchool(school);
                handleNext();
              }}
            />
          </CardContent>
        </Card>
      )}

      {currentStep === STEPS.JOB_PREFERENCES && (
        <Card>
          <CardHeader
            title={t("Understand Your Job Preferences")}
            subheader={t(
              "We want to make sure you can find the right job. Here you will learn how your preference impact your job options."
            )}
          />
          <CardContent>
            <JobPreferenceForm
              defaultValues={user.jobPreference}
              onSubmit={handleSubmitJobPreferences}
            />
          </CardContent>
        </Card>
      )}

      {currentStep === STEPS.SUPPORT_NETWORK && (
        <Card>
          <CardHeader
            title={t("Build Your Support Network")}
            subheader={t(
              "We’ll notify your supporters about your application and keep them updated on your training journey."
            )}
          />
          <CardContent>
            {hasAddedReferences ? (
              <Stack direction="column" spacing={2}>
                <Alert severity="success">
                  {t("You have built your support network successfully!")}
                </Alert>
                <Box>
                  <Button variant="contained" size="large" onClick={handleNext}>
                    {t("Continue")}
                  </Button>
                </Box>
              </Stack>
            ) : (
              <>
                <Box>
                  <ReferenceOnboardingSection
                    onFinish={() => {
                      setHasAddedReferences(true);
                      handleNext();
                    }}
                  />
                </Box>
                <Box marginTop={2}>
                  <ButtonWithDialogExplanation
                    title={t("Why do you need this?")}
                    steps={steps}
                  />
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      )}
      {currentStep === STEPS.SUCCESS_PLAN_REVIEW && (
        <Card>
          <CardHeader title={t("Review Your Success Plan")} />
          <CardContent>
            <SuccessPlanReview onContinue={handleFinish} />
          </CardContent>
        </Card>
      )}
      <FloatingVideo
        videoUrl={SUCCESS_PLAN_EXPLAINED_URL}
        playVideo={playVideo}
      />
    </Box>
  );
}
