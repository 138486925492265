/* eslint-disable jsx-a11y/media-has-caption */
import {
  BookTwoTone,
  CheckCircleOutlineTwoTone,
  DirectionsCarTwoTone,
  HomeOutlined,
  MusicNoteOutlined,
  VideoCameraFrontTwoTone,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Rating,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SliderExplanationStepProp } from "src/pages/SliderExplanationScreen";
import useErrorHandler from "src/utils/useErrorHandler";
import AudioControls from "./AudioControls";
import ButtonWithDialogExplanation from "./Button/ButtonWithDialogExplanation";
import { ScreenTitle } from "./Typography";

type TranscriptLine = {
  time: number;
  text: string;
};

// Helper: Converts a timestamp string in "mm:ss.sss" (or "hh:mm:ss.sss") format into seconds.
const timeStringToSeconds = (timeString: string): number => {
  const parts = timeString.split(":").map(Number);
  if (parts.length === 2) {
    // mm:ss.sss
    return parts[0] * 60 + parts[1];
  }
  if (parts.length === 3) {
    // hh:mm:ss.sss
    return parts[0] * 3600 + parts[1] * 60 + parts[2];
  }
  return 0;
};

// Parses the transcript markdown into an array of transcript segments.
// It expects blocks that begin with a timestamp line like:
//   Emerge Career (00:01.792)
// followed by one or more lines of text.
const parseTranscript = (markdown: string): TranscriptLine[] => {
  const lines = markdown.split("\n");
  const transcript: TranscriptLine[] = [];
  let currentEntry: TranscriptLine | null = null;
  const timestampRegex = /.*\((\d{2}:\d{2}(?::\d{2}\.\d{1,3}|\.\d{1,3}))\)\s*$/;

  lines.forEach((line) => {
    const trimmed = line.trim();
    if (!trimmed) {
      // This ensures that blank lines simply add a newline to the text rather than prematurely ending the transcript entry.
      if (currentEntry) {
        currentEntry.text += "\n";
      }
      return;
    }

    const match = trimmed.match(timestampRegex);
    if (match) {
      // If there is a current entry being built, push it before starting a new one.
      if (currentEntry) {
        transcript.push(currentEntry);
      }
      const timeString = match[1];
      const time = timeStringToSeconds(timeString);
      currentEntry = { time, text: "" };
    } else if (currentEntry) {
      // Append the current line to the transcript text (with a space separator).
      currentEntry.text += (currentEntry.text ? " " : "") + trimmed;
    }
  });
  // Push any remaining entry
  if (currentEntry) transcript.push(currentEntry);

  return transcript;
};

type PodcastPlayerProps = {
  title: string;
  audioUrl: string; // URL to the audio file (or DatoCMS audio player src)
  transcriptUrl: string;
  onFinish: (params: {
    rating?: number;
    action: "restart" | "go_home";
  }) => void;
};

const TranscriptContainer = styled(Box)(({ theme }) => ({
  maxHeight: "calc(100vh - 400px)", // So transcript is scrollable on tall screens
  overflowY: "auto",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(10), // Space so content isn't hidden behind player
  border: "1px solid #ccc",
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

// Floating player container at the bottom
const PlayerContainer = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  zIndex: theme.zIndex.fab, // So it floats on top of other elements
}));

const sliderExplanationSteps: SliderExplanationStepProp[] = [
  {
    type: "component",
    icon: CheckCircleOutlineTwoTone,
    title: "Why It Works",
    titleProps: { fontWeight: "bold" },
    body: "This is a memorization exercise. A lot of our successful graduates have said they listen over and over to the script and practice to truly master the material.",
  },
  {
    type: "component",
    icon: BookTwoTone,
    title: "What It Is",
    titleProps: { fontWeight: "bold" },
    body: "This tool helps you listen repeatedly and build muscle memory so that the inspection process becomes second nature.",
  },
  {
    type: "component",
    icon: DirectionsCarTwoTone,
    title: "Where To Use It",
    titleProps: { fontWeight: "bold" },
    body: "Use it anywhere you can’t actively practice – whether you're commuting, on a lunch break, in the shower, or doing home chores.",
  },
  {
    type: "component",
    icon: VideoCameraFrontTwoTone,
    title: "How To Use It",
    titleProps: { fontWeight: "bold" },
    body: "Listen to this over and over again and visualize yourself around the truck. Let the repetition build your confidence and embed the script into your muscle memory.",
  },
];

export const likelihoodLabels: { [index: number]: string } = {
  1: t("Not likely at all"),
  2: t("Not likely"),
  3: t("Maybe"),
  4: t("Likely"),
  5: t("Very likely"),
};
const PodcastPlayer: React.FC<PodcastPlayerProps> = ({
  title,
  audioUrl,
  transcriptUrl,
  onFinish,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const transcriptContainerRef = useRef<HTMLDivElement>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [transcript, setTranscript] = useState<TranscriptLine[]>([]);
  const [showRatingDialog, setShowRatingDialog] = useState(false);
  const [rating, setRating] = useState<number>();
  const theme = useTheme();
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();

  // Parse the transcript whenever the markdown changes
  useEffect(() => {
    fetch(transcriptUrl)
      .then((response) => response.text())
      .then((text) => setTranscript(parseTranscript(text)))
      .catch(errorHandler);
  }, [transcriptUrl, errorHandler]);

  // Sync currentTime with the audio element
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;
    const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
    audio.addEventListener("timeupdate", handleTimeUpdate);
    // eslint-disable-next-line consistent-return
    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;
    const handleEnded = () => {
      setShowRatingDialog(true);
    };
    audio.addEventListener("ended", handleEnded);
    // eslint-disable-next-line consistent-return
    return () => {
      audio.removeEventListener("ended", handleEnded);
    };
  }, [onFinish]);

  // Figure out which transcript line is active
  const activeIndex = transcript.findIndex((segment, index) => {
    if (index === transcript.length - 1) return currentTime >= segment.time;
    return (
      currentTime >= segment.time && currentTime < transcript[index + 1].time
    );
  });

  // Scroll the active transcript line into view
  useEffect(() => {
    if (activeIndex === -1 || !transcriptContainerRef.current) return;
    const element = document.getElementById(`transcript-line-${activeIndex}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [activeIndex]);

  const handleListenAgain = () => {
    // type narrowing
    setShowRatingDialog(false);
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
    setRating(undefined);
    onFinish({ rating, action: "restart" });
  };

  const handleGoHome = () => {
    onFinish({ rating, action: "go_home" });
    navigate("/");
  };

  return (
    <Box
      sx={{
        position: "relative",
        fontFamily: "'Space Grotesk', sans-serif", // ensures the transcript uses Space Grotesk
      }}
    >
      <ButtonWithDialogExplanation
        title={t("How should I use this?")}
        steps={sliderExplanationSteps}
      />
      <TranscriptContainer ref={transcriptContainerRef}>
        <ScreenTitle>{title}</ScreenTitle>
        {transcript.map((segment, index) => (
          <Typography
            key={segment.time}
            id={`transcript-line-${index}`}
            paragraph
            sx={{
              fontFamily: "'Space Grotesk', sans-serif",
              backgroundColor:
                activeIndex === index
                  ? theme.palette.secondary.light
                  : "transparent",
              transition: "background-color 0.3s ease",
              padding: "6px 0",
            }}
          >
            <strong>
              {new Date(segment.time * 1000).toISOString().substr(14, 5)}
            </strong>{" "}
            {segment.text}
          </Typography>
        ))}
      </TranscriptContainer>

      <audio
        ref={audioRef}
        src={audioUrl}
        controls
        style={{ display: "none" }}
      />

      <PlayerContainer elevation={3}>
        {/* 
          Replace <audio> below with the Dato CMS audio player if desired.
          For example:
          <DatoAudioPlayer src={audioUrl} ... />
        */}
        <Box width="100%" sx={{ padding: 2 }}>
          <AudioControls audioRef={audioRef} trackTitle={title} />
        </Box>
      </PlayerContainer>

      <Dialog open={showRatingDialog}>
        <DialogTitle>
          {t(
            "How likely are you to suggest this listening tool to a friend in training?"
          )}
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Rating
              size="large"
              value={rating}
              precision={1}
              max={5}
              onChange={(_event, newValue) => {
                setRating(newValue === null ? undefined : newValue);
              }}
            />
            {rating !== undefined && (
              <Typography variant="body2" color="text.secondary" marginLeft={2}>
                {likelihoodLabels[rating]}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleListenAgain}
            disabled={typeof rating !== "number"}
            startIcon={<MusicNoteOutlined />}
          >
            {t("Listen Again")}
          </Button>
          <Button
            onClick={handleGoHome}
            disabled={typeof rating !== "number"}
            startIcon={<HomeOutlined />}
          >
            {t("Go Home")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PodcastPlayer;
