// src/components/PartnerHomeUI.tsx
import { Box, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import Empty from "src/components/Empty";
import UpdatedPaddedLayout from "src/components/Layout/UpdatedPaddedLayout";
import { ScreenTitle } from "src/components/Typography";
import { Partner } from "src/types/Partner";
import AppSkeleton from "../AppSkeleton";
import OpportunityCard from "./OpportunityCard";
import PartnerReferralTable from "./PartnerReferralTable";
import ReferralCard from "./ReferralCard";
import ReferralSteps from "./ReferralSteps";
import usePartnerReferrals from "./usePartnerReferrals";

type Props = {
  partner: Partner;
};

export default function PartnerHomeView({ partner }: Props) {
  const { t } = useTranslation();
  const { referrals, loading } = usePartnerReferrals(partner.uid);

  if (loading) return <AppSkeleton />;

  return (
    <Stack
      spacing={6}
      sx={{
        display: "flex",
        flexDirection: "column",
        marginY: 4,
      }}
    >
      <UpdatedPaddedLayout>
        <ScreenTitle marginBottom={2}>
          {t("Welcome back, {{ name }}", { name: partner.firstName })}
        </ScreenTitle>

        <Grid container marginBottom={4}>
          <Grid item xs={12} md={8}>
            <ReferralCard partner={partner} />
          </Grid>
        </Grid>

        {referrals && referrals.length > 0 ? (
          <PartnerReferralTable partner={partner} referrals={referrals} />
        ) : (
          <Empty
            title={t("No Referrals Yet")}
            variant="outlined"
            description={t(
              "Once individuals start signing up using your link, they will be displayed here."
            )}
          />
        )}
      </UpdatedPaddedLayout>
      <Box
        sx={{
          background:
            "linear-gradient(339deg, #F7F1EA 36.57%, #FDFCFA 72.98%, #FBFBFB 86.11%)",
        }}
      >
        <UpdatedPaddedLayout>
          <Box>
            <ReferralSteps partner={partner} />
          </Box>
        </UpdatedPaddedLayout>
      </Box>

      <Box>
        <OpportunityCard
          text={t(
            `Your referrals in {{ state }} can earn $75k/year once they graduate from the program`,
            { state: partner.state }
          )}
          imageUrl="https://images.unsplash.com/photo-1545315003-c5ad6226c272?q=80&w=2400&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />
      </Box>
    </Stack>
  );
}
