import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchLessonProgressCount from "src/firebase/fetchLessonProgressCount";
import MilestoneType from "src/types/MilestoneType.enum";
import { UserAccount, UserOnboardingStatus } from "src/types/User";
import useErrorHandler from "src/utils/useErrorHandler";
import useUserMilestones from "src/utils/useUserMilestones";
import { StudentPlanMilestoneFormData } from "../ClientScreen/StudentPlanTab/StudentPlanMilestoneForm";

export const trainingLabels = [
  "Application",
  "1st Lesson",
  "1st Unit",
  "Online Training",
  "DOT Scheduling",
  "CLP Scheduling",
  "Exam Practice",
  "Enrollment",
  "Skills Training",
  "Job Search",
  "On the Job",
] as const;
export type TrainingLabel = typeof trainingLabels[number];

export interface ActionItem<TData> {
  title: string;
  onCreate: (data: TData) => Promise<void>;
  milestoneType: MilestoneType;
}

type TrainingStepResult =
  | {
      trainingLabel: "CLP Scheduling" | "DOT Scheduling";
      actionItems: ActionItem<StudentPlanMilestoneFormData>[];
    }
  | {
      trainingLabel: Exclude<
        TrainingLabel,
        "CLP Scheduling" | "DOT Scheduling"
      >; // Any label other than "CLP Scheduling"
      actionItems?: never; // Explicitly disallow actionItem
    };

export default function useCurrentTrainingStep(user: UserAccount): {
  trainingStep: TrainingStepResult;
  hasCompletedStep: (label: TrainingLabel) => boolean;
  isBeforeStep: (label: TrainingLabel) => boolean;
} {
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();
  const [videoCount, setVideoCount] = useState<number>(0);
  const [hasScheduledMedicalExam, setHasScheduledMedicalExam] =
    useState<boolean>(false);
  const [hasScheduledTheoryExam, setHasScheduledTheoryExam] =
    useState<boolean>(false);

  const {
    findMilestone,
    loading: milestonesLoading,
    createMilestone,
  } = useUserMilestones(user);

  useEffect(() => {
    fetchLessonProgressCount(user.uid, clients)
      .then(setVideoCount)
      .catch(errorHandler);
  }, [user, clients, errorHandler]);

  useEffect(() => {
    if (!milestonesLoading) {
      const theoryExamMilestoneExists = !!findMilestone(
        MilestoneType.THEORY_EXAM
      );
      setHasScheduledTheoryExam(theoryExamMilestoneExists);

      const medicalExamMilestoneExists = !!findMilestone(
        MilestoneType.MEDICAL_EXAM
      );
      setHasScheduledMedicalExam(medicalExamMilestoneExists);
    }
  }, [milestonesLoading, findMilestone]);

  const trainingStep: TrainingStepResult = useMemo(() => {
    if (
      user.employmentStatus === "employed" ||
      user.employmentStatus === "conditional_offer" ||
      user.onboardingStatus === UserOnboardingStatus.EMPLOYED
    ) {
      return { trainingLabel: t("On the Job") };
    }
    if (
      user.employmentStatus ||
      user.enrollmentStatus === "licensed" ||
      user.onboardingStatus === UserOnboardingStatus.LICENSED
    ) {
      return { trainingLabel: "Job Search" };
    }
    if (
      user.enrollmentStatus === "skills_training" ||
      user.enrollmentStatus === "completed_skills_training" ||
      user.onboardingStatus === UserOnboardingStatus.RANGE ||
      user.onboardingStatus === UserOnboardingStatus.GRADUATED
    ) {
      return { trainingLabel: t("Skills Training") };
    }
    if (user.applicationStatus === "enrolled") {
      return { trainingLabel: t("Enrolled") };
    }
    if (videoCount > 20) {
      // first people need to schedule the exam
      if (!hasScheduledMedicalExam) {
        return {
          trainingLabel: t("DOT Scheduling"),
          actionItems: [
            {
              title: t("Schedule Medical Exam"),
              onCreate: createMilestone,
              milestoneType: MilestoneType.MEDICAL_EXAM,
            },
          ],
        } as TrainingStepResult;
      }

      if (!hasScheduledTheoryExam) {
        return {
          trainingLabel: t("CLP Scheduling"),
          actionItems: [
            {
              title: t("Schedule Theory Exam"),
              onCreate: createMilestone,
              milestoneType: MilestoneType.THEORY_EXAM,
            },
          ],
        } as TrainingStepResult;
      }

      // then exam practice
      return { trainingLabel: t("Exam Practice") };
    }

    if (videoCount > 5) {
      return { trainingLabel: t("Online Training") };
    }
    if (videoCount === 1) {
      return { trainingLabel: t("1st Lesson") };
    }

    if (videoCount <= 5) {
      return { trainingLabel: t("1st Unit") };
    }

    if (user.applicationStatus) {
      return { trainingLabel: t("Application") };
    }
    return { trainingLabel: t("Application") }; // Default case
  }, [
    user.employmentStatus,
    user.onboardingStatus,
    user.enrollmentStatus,
    user.applicationStatus,
    videoCount,
    hasScheduledMedicalExam,
    hasScheduledTheoryExam,
    createMilestone,
  ]);

  const hasCompletedStep = useCallback(
    (label: TrainingLabel): boolean => {
      const labelIndex = trainingLabels.indexOf(label);
      const currentIndex = trainingLabels.indexOf(
        trainingStep.trainingLabel as TrainingLabel
      );

      if (labelIndex === -1 || currentIndex === -1) {
        return false;
      }

      return labelIndex < currentIndex;
    },
    [trainingStep.trainingLabel]
  );

  const isBeforeStep = useCallback(
    (label: TrainingLabel): boolean => {
      const labelIndex = trainingLabels.indexOf(label);
      const currentIndex = trainingLabels.indexOf(
        trainingStep.trainingLabel as TrainingLabel
      );

      if (labelIndex === -1 || currentIndex === -1) {
        return false;
      }

      return currentIndex < labelIndex;
    },
    [trainingStep.trainingLabel]
  );

  return {
    trainingStep,
    hasCompletedStep,
    isBeforeStep,
  };
}
