/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Tooltip } from "@mui/material";
import { useAdmin, useOrganizationStaff } from "src/SessionBoundary";
import Avatar from "../Avatar";
import Link from "../Link";

const MenuTabs = [
  "clients",
  "caseload",
  "team",
  "superadmin",
  "cohorts",
  "partners",
] as const;
export type MenuTab = typeof MenuTabs[number];

export function MenuLink({
  to,
  icon,
  onClick,
  label,
  current,
  disabled,
  variant,
}: {
  to: string;
  onClick?: () => void;
  icon: JSX.Element;
  label: string;
  current?: boolean;
  disabled?: boolean;
  variant?: "outlined" | "contained";
}) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      to={disabled ? "" : to}
      onClick={onClick}
      variant="subtitle2"
      sx={{
        display: "flex",
        color: disabled
          ? "rgba(0 0 0 / .38)"
          : current
          ? undefined
          : "text.primary",
        backgroundColor: current
          ? (theme) => `${theme.palette.primary.main}14`
          : undefined,
        cursor: disabled ? "default" : undefined,
        border: variant === "outlined" ? 1 : undefined,
        borderRadius: 2,
        paddingY: 1.5,
        paddingLeft: 3,
        paddingRight: 1,
        alignItems: "center",
      }}
    >
      {icon}
      <Box component="span" sx={{ marginLeft: 2 }}>
        {label}
      </Box>
    </Link>
  );
}

export function IconLink({
  to,
  icon,
  onClick,
  current,
  disabled,
  variant,
  tooltip,
}: {
  to: string;
  onClick?: () => void;
  icon: JSX.Element;
  current?: boolean;
  disabled?: boolean;
  variant?: "outlined" | "contained";
  tooltip?: string;
}) {
  return (
    <Tooltip title={tooltip} placement="right">
      <div>
        <Link
          to={disabled ? "" : to}
          onClick={onClick}
          variant="subtitle2"
          sx={{
            display: "flex",
            color: disabled
              ? "rgba(0 0 0 / .38)"
              : current
              ? undefined
              : "text.primary",
            backgroundColor: current
              ? (theme) => `${theme.palette.primary.main}14`
              : undefined,
            cursor: disabled ? "default" : undefined,
            border: variant === "outlined" ? 1 : undefined,
            borderRadius: 2,
            paddingY: 1.5,
            marginX: "auto",

            alignItems: "center",
          }}
        >
          <Box display="flex" width="100%" justifyContent="center">
            {icon}
          </Box>
        </Link>
      </div>
    </Tooltip>
  );
}

export function AdminAccountDetails() {
  const admin = useAdmin();

  return (
    <Box display="flex">
      <Avatar
        sx={{ height: 32, width: 32 }}
        src={admin.photoUrl || "/logo512.png"}
      />
      {/* <Box marginLeft={2} textAlign="left" flexGrow={1} minWidth={0}>
        <Typography
          variant="h6"
          color="text.primary"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {admin.firstName}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          Emerge Career
        </Typography>
      </Box> */}
    </Box>
  );
}

export function OrganizationStaffAccountDetails() {
  const staff = useOrganizationStaff();

  return (
    <Box display="flex">
      <Avatar
        sx={{ height: 32, width: 32 }}
        src={staff.organization.logoImageUrl}
      />
      {/* <Box marginLeft={2} textAlign="left" flexGrow={1} minWidth={0}>
        <Typography
          variant="h6"
          color="text.primary"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {staff.firstName}
        </Typography>

        <Typography
          variant="body2"
          color="text.secondary"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {staff.organizationId}
        </Typography>
      </Box> */}
    </Box>
  );
}

export type MenuProps = {
  currentTab: MenuTab;
  onClick?: () => void;
};
