import { LinearProgress, Typography } from "@mui/material";
import { keyframes, styled } from "@mui/material/styles";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import RocketTakeoffLottie from "src/assets/Lotties/RocketTakeoff.json";
import TruckLottie from "src/assets/Lotties/Truck.json";

interface Props {
  onAnimationEnd?: () => void;
  variant?: "truck" | "rocket";
  messages: string[];
}

// Define the keyframes for the shimmer effect
const shimmer = keyframes`
  0% { background-position: -200px 0; }
  100% { background-position: 200px 0; }
`;

// Create a styled Typography with the shimmer effect
const ShimmerTypography = styled(Typography)(() => ({
  background: "linear-gradient(90deg, #333333, #666666, #333333)", // Dark to gray gradient
  backgroundSize: "200px 100%",
  backgroundRepeat: "repeat",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  animation: `${shimmer} 2s linear infinite`,
}));

const LoadingTransitionScreen: React.FC<Props> = ({
  onAnimationEnd,
  messages,
  variant = "truck",
}) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const messageInterval = 3000 / messages.length;
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, messageInterval);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      if (onAnimationEnd) onAnimationEnd();
    }, 4000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [messages.length, onAnimationEnd]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {variant === "truck" && (
        <Lottie animationData={TruckLottie} style={{ height: 400 }} />
      )}
      {variant === "rocket" && (
        <Lottie animationData={RocketTakeoffLottie} style={{ height: 400 }} />
      )}
      <LinearProgress />
      <ShimmerTypography
        variant="subtitle2"
        color="text.secondary"
        textAlign="center"
        marginTop={1}
      >
        {messages[currentMessageIndex]}
      </ShimmerTypography>
    </div>
  );
};

export default LoadingTransitionScreen;
