import { AddOutlined, Edit, Rocket } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  Modal,
  Stack,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionButtonWithConfirmDialog from "src/components/Button/ActionButtonWithConfirmDialog";
import AdminOnlyAlert from "src/components/Button/AdminOnlyAlert";
import AdminOnlyButton from "src/components/Button/AdminOnlyButton";
import DownloadFileButton from "src/components/Button/DownloadFileButton";
import ModalContainer from "src/components/ModalContainer";
import { useAppContext } from "src/contexts/AppContext";
import createMilestoneTask from "src/firebase/createMilestoneTask";
import fetchMilestoneTasks from "src/firebase/fetchMilestoneTasks";
import updateMilestoneTaskCompletion from "src/firebase/updateMilestoneTaskCompletion";
import { MilestoneTask } from "src/types/MilestoneTask";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import getMilestoneCompletionInstructions from "src/utils/getMilestoneCompletionInstructions";
import replaceItem from "src/utils/replaceItem";
import useErrorHandler from "src/utils/useErrorHandler";
import DocumentProofSection, {
  DocumentProofForm,
} from "./DocumentProofSection";
import MilestoneCardHeader from "./MilestoneCardHeader";
import MilestoneTaskForm, { MilestoneTaskFormData } from "./MilestoneTaskForm";
import MilestoneTaskItem from "./MilestoneTaskItem";

type Props = {
  milestone: StudentPlanMilestone;
  handleCompletion: (data?: DocumentProofForm) => void;
  handleUndoCompletion: () => void;
  handleEdit: () => void;
};

export default function MilestoneCard({
  milestone,
  handleCompletion,
  handleUndoCompletion,
  handleEdit,
}: Props) {
  const { t } = useTranslation();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();
  const [tasks, setTasks] = useState<MilestoneTask[]>();
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);
  const [isCompletionModalOpen, setIsCompletionModalOpen] = useState(false);

  // load tasks
  useEffect(() => {
    fetchMilestoneTasks(
      {
        milestoneId: milestone.uid,
        userId: milestone.userId,
      },
      clients
    )
      .then(setTasks)
      .catch(errorHandler);
  }, [clients, errorHandler, milestone.uid, milestone.userId]);

  const handleCheck = (oldTask: MilestoneTask) => {
    updateMilestoneTaskCompletion(
      {
        task: oldTask,
        completed: !oldTask.completedAt,
      },
      clients
    )
      .then((updatedTask) => {
        setTasks((prev) => {
          const updated = replaceItem(
            prev || [],
            oldTask,
            updatedTask,
            (item) => item.uid === oldTask.uid
          );

          return [...updated];
        });
      })
      .catch(errorHandler);
  };

  const handleTaskCreation = (data: MilestoneTaskFormData) => {
    createMilestoneTask({ ...data, milestone }, clients)
      .then((task) => setTasks((prev) => (prev ? [...prev, task] : [task])))
      .catch(errorHandler);

    setIsCreateTaskModalOpen(false);
  };

  if (milestone.completedAt) {
    return (
      <Card>
        <CardContent>
          <MilestoneCardHeader tasks={tasks} milestone={milestone} />

          <AdminOnlyAlert color="info" variant="outlined" sx={{ marginTop: 2 }}>
            {t(
              "The milestone was due on {{ dueDate }} and marked as completed on {{completionDate}}",
              {
                dueDate: format(new Date(milestone.date), "MMM d, yyyy"),
                completionDate: format(
                  new Date(milestone.completedAt),
                  "MMM d, yyyy"
                ),
              }
            )}
          </AdminOnlyAlert>
        </CardContent>
        <CardActions>
          {milestone.completionProofFilePath && (
            <DownloadFileButton
              filePath={milestone.completionProofFilePath}
              label={t("View")}
            />
          )}
          <AdminOnlyButton onClick={handleUndoCompletion}>
            {t("Undo")}
          </AdminOnlyButton>
        </CardActions>
      </Card>
    );
  }

  const completionInstructions = getMilestoneCompletionInstructions(
    milestone.type
  );

  return (
    <>
      <Card>
        <CardContent>
          <MilestoneCardHeader tasks={tasks} milestone={milestone} />

          {tasks && (
            <Stack spacing={1} mt={2}>
              {tasks.map((task) => (
                <Box key={task.uid}>
                  <MilestoneTaskItem
                    task={task}
                    onCheck={() => handleCheck(task)}
                  />
                </Box>
              ))}
            </Stack>
          )}
        </CardContent>
        <CardActions>
          {completionInstructions ? (
            <Button
              color="success"
              onClick={() => setIsCompletionModalOpen(true)}
              startIcon={<Rocket />}
            >
              {t("Complete")}
            </Button>
          ) : (
            <ActionButtonWithConfirmDialog
              onAction={handleCompletion}
              buttonColor="success"
              buttonVariant="contained"
              buttonLabel={t("Mark as complete")}
              startIcon={<Rocket />}
              title="Milestone Achieved?"
              description="Once you confirm, we’ll notify your coach and support network that you’ve completed this milestone so they can celebrate with you! 🎉"
            />
          )}

          <Button onClick={handleEdit} startIcon={<Edit />}>
            {t("Edit")}
          </Button>
          <Button
            onClick={() => setIsCreateTaskModalOpen(true)}
            startIcon={<AddOutlined />}
          >
            {t("Add Task")}
          </Button>
        </CardActions>
        <Modal
          open={isCreateTaskModalOpen}
          onClose={() => setIsCreateTaskModalOpen(false)}
        >
          <div>
            <ModalContainer>
              <Card>
                <CardContent>
                  <MilestoneTaskForm onSubmit={handleTaskCreation} />
                </CardContent>
              </Card>
            </ModalContainer>
          </div>
        </Modal>
      </Card>

      <Dialog
        open={isCompletionModalOpen}
        onClose={() => setIsCompletionModalOpen(false)}
      >
        <DialogContent>
          {completionInstructions && (
            <DocumentProofSection
              completionInstructions={completionInstructions}
              onSubmit={handleCompletion}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
