import { Timestamp } from "firebase/firestore";
import { Attendance } from "../types/Attendance";

export default function isAttendanceGuard(obj: unknown): obj is Attendance {
  if (!obj) return false;
  const attendance = obj as Attendance;
  return (
    typeof attendance.uid === "string" &&
    typeof attendance.userId === "string" &&
    (typeof attendance.path === "string" || !attendance.path) &&
    typeof attendance.createdAt === "string" &&
    attendance.createdAtFirestoreTimestamp instanceof Timestamp &&
    typeof attendance.date === "string" &&
    attendance.dateFirestoreTimestamp instanceof Timestamp
  );
}
