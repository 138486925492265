import { VisibilityOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useAppContext } from "src/contexts/AppContext";
import fetchStorageBlob from "src/firebase/fetchStorageBlob";
import useErrorHandler from "src/utils/useErrorHandler";
import Button from "./Button";

interface Props {
  filePath: string;
  label?: string;
}

export default function DownloadFileButton({ filePath, label }: Props) {
  const { t } = useTranslation();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  const handleDownload = async () => {
    try {
      // Fetch blob from Firebase Storage
      const blob = await fetchStorageBlob({ path: filePath }, clients);

      // Create a Blob URL
      const blobUrl = URL.createObjectURL(blob);

      // Create an invisible anchor tag and trigger a click
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        filePath.split("/").pop() || "downloaded_file"
      ); // Extracts filename
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Button startIcon={<VisibilityOutlined />} onClick={handleDownload}>
      {label || t("Download")}
    </Button>
  );
}
