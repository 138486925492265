import { collection, getDocs, query, where } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { UserSummary } from "src/types/UserSummary";
import isUserSummaryGuard from "src/utils/isUserSummaryGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchReferralsByPartner(
  partnerId: string,
  { db }: Clients
): Promise<UserSummary[]> {
  const res = await getDocs(
    query(
      collection(db, "userSummaries"),
      where("referredByPartnerId", "==", partnerId)
    )
  );

  const referrals = res.docs.map(cleanFirestoreDoc).filter(isUserSummaryGuard);

  return referrals as UserSummary[];
}
