import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "src/contexts/AppContext";
import { useSnackbarContext } from "src/SnackbarProvider";
import useErrorHandler from "src/utils/useErrorHandler";
import { trackEvent } from "../OnboardingScreen/amplitude";

export default function ReactivationDialog() {
  const { t } = useTranslation();
  const [reason, setReason] = useState("");
  const [commitment, setCommitment] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false); // State for checkbox
  const { clients } = useAppContext();
  const reactivateUserAccount = httpsCallable(
    clients.functions,
    "reactivateUserAccountSecondGen"
  );
  const errorHandler = useErrorHandler();
  const snackbarContext = useSnackbarContext();
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
    trackEvent("Reactivation Dialog - Click on Open");
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await reactivateUserAccount({ reason, commitment });
      trackEvent("Reactivation Dialog - Submitted Reactivation", {
        reason,
        commitment,
      });
      setReason("");
      setCommitment("");
      setChecked(false); // Reset checkbox state
      setOpen(false);
      snackbarContext.alert(
        "success",
        t("Your reactivation request was temporarily approved!")
      );
      navigate(0);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => setOpen(false);

  return (
    <Stack spacing={2}>
      <Alert severity="warning" sx={{ maxWidth: 600 }}>
        <AlertTitle>{t("Your Was Account Disabled")}</AlertTitle>
        <Typography>
          {t(
            "This happens when applicants violate our code of conduct by being unresponsive or not staying committed to their studies."
          )}
        </Typography>
      </Alert>
      <Card variant="outlined" sx={{ maxWidth: 600 }}>
        <CardMedia
          image="https://f004.backblazeb2.com/file/emerge-courses/assets/A+Calm%2C+Hyper-Realistic+Scene+of+Truck+Outside+High-Security+Prison.png"
          alt="truck outside high-security prison"
          component="img"
          height={200}
          sx={{
            objectFit: "cover",
            objectPosition: "center",
            display: { xs: "none", md: "block" },
          }}
        />
        <CardHeader title={t("Ready to Commit?")} />
        <CardContent>
          <Typography>
            {t(
              "Because we have limited scholarship seats, we consider re-engagement requests on a case-by-case basis."
            )}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={handleOpen}
            variant="contained"
            color="primary"
            size="large"
          >
            {t("Request Reactivation")}
          </Button>
        </CardActions>
      </Card>

      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t("Reactivation Request")}</DialogTitle>
        <DialogContent>
          <Typography marginBottom={2}>
            {t(
              "Please share why you paused your journey and review the code of conduct."
            )}
          </Typography>
          <Card sx={{ marginTop: 1 }} variant="outlined">
            <CardContent>
              <Typography fontWeight="bold" marginBottom={2}>
                {t("Code of Conduct")}
              </Typography>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography variant="subtitle2">
                      {t("Be Responsive")}
                    </Typography>
                    <Typography variant="body2">
                      {t(
                        "We expect you to reply to the Emerge team within 48 hours."
                      )}
                    </Typography>
                  </ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemText>
                    <Typography variant="subtitle2">
                      {t("Own Your Opportunity")}
                    </Typography>

                    <Typography variant="body2">
                      {t(
                        "We expect you to make daily progress with the coursework."
                      )}
                    </Typography>
                  </ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemText>
                    <Typography variant="subtitle2">
                      {t("Commit to Your Future")}
                    </Typography>
                    <Typography variant="body2">
                      {t(
                        "We cannot reactivate your account more than 2 times."
                      )}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                }
                label={t("I understand and agree to the expectations")}
                sx={{ marginTop: 1 }}
              />
            </CardContent>
          </Card>
          <TextField
            label={t("What happened?")}
            placeholder="What challenges led you to stop training?"
            fullWidth
            multiline
            rows={2}
            maxRows={4}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            margin="dense"
          />

          <TextField
            label={t("What changed?")}
            placeholder="What has changed that will allow you to commit to the program?"
            fullWidth
            multiline
            rows={2}
            value={commitment}
            onChange={(e) => setCommitment(e.target.value)}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t("Cancel")}
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            disabled={
              reason.length < 5 || commitment.length < 5 || !checked || loading
            } // Disable until checkbox is checked
          >
            {t("Submit Request")}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
