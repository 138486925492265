import { SvgIconComponent } from "@mui/icons-material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  Card,
  CardContent,
  MobileStepper,
  Modal,
  Stack,
  SxProps,
} from "@mui/material";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalContainer from "src/components/ModalContainer";
import { Header32 } from "src/components/Typography";

export type SliderExplanationStepProp =
  | {
      type: "image";
      imageUrl: string;
      title: string;
      titleProps?: SxProps;
      body: ReactNode;
    }
  | {
      type: "component";
      icon: SvgIconComponent;
      title: string;
      titleProps?: SxProps;
      body: ReactNode;
    };

type FinishButtonProps = {
  onFinish?: () => void;
  label?: string;
};

type Props = {
  steps: SliderExplanationStepProp[];
  finishButtonProps?: FinishButtonProps;
};

export default function SliderExplanationScreen({
  steps,
  finishButtonProps = {},
}: Props) {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false); // State to control fade-in
  const maxSteps = steps.length;
  const [open, setOpen] = useState(true);

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    setIsImageLoaded(false); // Reset image load state when moving to the next step
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
    setIsImageLoaded(false); // Reset image load state when going back
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true); // Trigger fade-in when the image is loaded
  };

  const { onFinish, label } = finishButtonProps;

  const current = steps[currentStep];

  // zIndex is needed because the slider is being used on the video ask iframe, which hides it otherwise
  return (
    <Modal open={open} sx={{ zIndex: 9999 }}>
      <ModalContainer>
        <Card>
          <CardContent>
            <Stack spacing={6} alignItems="center">
              {current.type === "image" ? (
                <img
                  src={current.imageUrl}
                  alt={`${current.title} illustration`}
                  height={150}
                  width={200}
                  style={{
                    objectFit: "cover",
                    borderRadius: 8,
                    opacity: isImageLoaded ? 1 : 0, // Control opacity for fade-in
                    transition: "opacity 1s ease-in", // Fade-in effect
                  }}
                  onLoad={handleImageLoad} // Image load event
                />
              ) : (
                <current.icon color="primary" fontSize="large" />
              )}
              <Stack spacing={2} alignItems="center">
                <Header32
                  sx={{
                    textAlign: "center",
                    ...(current.titleProps || {}),
                  }}
                  id="modal-title"
                >
                  {current.title}
                </Header32>
                <Box
                  sx={{ textAlign: "center", marginTop: 2, maxWidth: 600 }}
                  id="modal-description"
                >
                  {current.body}
                </Box>
              </Stack>
              <MobileStepper
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={currentStep}
                sx={{ width: "100%", maxWidth: 600 }}
                nextButton={
                  <Button
                    size="large"
                    variant={
                      currentStep === maxSteps - 1 ? "contained" : "text"
                    }
                    onClick={
                      currentStep === maxSteps - 1
                        ? () => {
                            if (onFinish) onFinish();
                            setOpen(false);
                          }
                        : handleNext
                    }
                  >
                    {currentStep === maxSteps - 1
                      ? label || t("Finish")
                      : t("Next")}
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    size="large"
                    onClick={handleBack}
                    disabled={currentStep === 0}
                  >
                    <KeyboardArrowLeft />
                    {t("Back")}
                  </Button>
                }
              />
            </Stack>
          </CardContent>
        </Card>
      </ModalContainer>
    </Modal>
  );
}
