import { useTheme } from "@mui/material";
import {
  GaugeContainer,
  GaugeReferenceArc,
  GaugeValueArc,
  GaugeValueText,
  useGaugeState,
} from "@mui/x-charts";

type Props = {
  value: number | null;
  startAngle: number;
  endAngle: number;
  size: number;
};

function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();
  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };
  return (
    <g>
      <circle cx={cx} cy={cy} r={5} fill="red" />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke="red"
        strokeWidth={3}
      />
    </g>
  );
}

export default function GaugeVelocimeter({
  value,
  startAngle,
  endAngle,
  size,
}: Props) {
  const theme = useTheme();
  return (
    <GaugeContainer
      value={value}
      startAngle={startAngle}
      endAngle={endAngle}
      sx={{
        width: size,
        height: size,
      }}
    >
      <GaugeReferenceArc />
      <GaugeValueArc
        fill={value && value > 80 ? theme.palette.success.main : "red"}
      />
      {value && <GaugePointer />}
      <GaugeValueText
        fontFamily="Space Grotesk, sans-serif"
        fontSize={value ? 40 : 20}
        text={({ value }) => `${value || "No Score"}`}
      />
    </GaugeContainer>
  );
}
