import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Button, Fab, Typography } from "@mui/material";
import { keyframes, styled } from "@mui/material/styles";
import { addDays, format } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "src/contexts/AppContext";
import resetAndCreateTheoryMilestones from "src/firebase/resetAndCreateTheoryMilestones";
import { useCurrentUser } from "src/SessionBoundary";
import calculateMilestoneDates from "src/utils/calculateMilestoneDates";
import useErrorHandler from "src/utils/useErrorHandler";

type Props = {
  onContinue: () => void;
};

// Define a keyframes for fade-in from top
const fadeInDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Create a styled container that applies the animation
const AnimatedContainer = styled("div")(() => ({
  animation: `${fadeInDown} 0.7s ease-out forwards`,
}));

export default function SuccessPlanReview({ onContinue }: Props) {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const { clients } = useAppContext();
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();

  const milestoneDates = calculateMilestoneDates(user);

  const handleFinish = async () => {
    await resetAndCreateTheoryMilestones(user, clients)
      .then(() => (onContinue ? onContinue() : navigate("/")))
      .catch(errorHandler);
  };

  const timelineOppositeContentSx = {
    flex: 0.1, // Adjust flex size for the date section
    textAlign: "left", // Always left-aligned
  };

  const timelineContentSx = {
    flex: 0.9, // Adjust flex size for the content section
  };

  return (
    <Box>
      <AnimatedContainer>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {user.theoryTrainingPlan &&
          milestoneDates.theoryEndDate &&
          milestoneDates.medicalExamDate &&
          milestoneDates.theoryExamDate ? (
            <>
              <TimelineItem>
                <TimelineOppositeContent sx={timelineOppositeContentSx}>
                  <Typography variant="body2" fontFamily="Space Grotesk">
                    {format(new Date(), "MMM d")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="success" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={timelineContentSx}>
                  {t("Start your journey")}
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineOppositeContent sx={timelineOppositeContentSx}>
                  <Typography variant="body2" fontFamily="Space Grotesk">
                    {format(milestoneDates.theoryEndDate, "MMM d")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="success" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={timelineContentSx}>
                  {t("Finish online theory training")}
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineOppositeContent sx={timelineOppositeContentSx}>
                  <Typography variant="body2" fontFamily="Space Grotesk">
                    {format(milestoneDates.medicalExamDate, "MMM d")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={timelineContentSx}>
                  {t("Schedule the DOT physical exam")}
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineOppositeContent sx={timelineOppositeContentSx}>
                  <Typography variant="body2" fontFamily="Space Grotesk">
                    {format(milestoneDates.theoryExamDate, "MMM d")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={timelineContentSx}>
                  {t("Schedule the commercial learner's permit exam")}
                </TimelineContent>
              </TimelineItem>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={timelineContentSx}>
                  <Trans
                    t={t}
                    defaults="<bold>📱 Online Training.</bold> 21 videos. 21 practice exams. Move at your own pace."
                    components={{ bold: <b /> }}
                  />
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={timelineContentSx}>
                  <Trans
                    t={t}
                    defaults="<bold>🩺 DOT Physical.</bold> A quick medical check to confirm you’re fit to drive a truck. You need this before getting your Permit."
                    components={{ bold: <b /> }}
                  />
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={timelineContentSx}>
                  <Trans
                    t={t}
                    defaults="<bold>📜 Commercial Learner’s Permit.</bold> Your first official step toward becoming a truck driver!"
                    components={{ bold: <b /> }}
                  />
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {/* Skills training timeline items */}
          {milestoneDates.skillsTrainingStartDate &&
          milestoneDates.skillsTrainingEndDate &&
          milestoneDates.licenseExamDate ? (
            <>
              <TimelineItem>
                <TimelineOppositeContent sx={timelineOppositeContentSx}>
                  <Typography variant="body2" fontFamily="Space Grotesk">
                    {format(milestoneDates.skillsTrainingStartDate, "MMM d")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={timelineContentSx}>
                  {t("Start the driving training")}
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineOppositeContent sx={timelineOppositeContentSx}>
                  <Typography variant="body2" fontFamily="Space Grotesk">
                    {format(milestoneDates.skillsTrainingEndDate, "MMM d")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={timelineContentSx}>
                  {t("Finish the driving training")}
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineOppositeContent sx={timelineOppositeContentSx}>
                  <Typography variant="body2" fontFamily="Space Grotesk">
                    {format(milestoneDates.licenseExamDate, "MMM d")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={timelineContentSx}>
                  {t("Take road test CDL exam")}
                </TimelineContent>
              </TimelineItem>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={timelineContentSx}>
                  <Trans
                    t={t}
                    defaults="<bold>🚛 Trucking School.</bold> Where the fun begins. 120 hours of on-the-road training. This can take 3 to 10 weeks. "
                    components={{ bold: <b /> }}
                  />
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={timelineContentSx}>
                  <Trans
                    t={t}
                    defaults="<bold>🏁 CDL Road Test.</bold> Your big moment! Pass this, and you're ready for the road "
                    components={{ bold: <b /> }}
                  />
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {/* Job search timeline items */}
          {user.jobPreference && milestoneDates.allDatesPresent ? (
            <TimelineItem>
              <TimelineOppositeContent sx={timelineOppositeContentSx}>
                <Typography variant="body2" fontFamily="Space Grotesk">
                  {format(addDays(milestoneDates.licenseExamDate, 30), "MMM d")}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={timelineContentSx}>
                {t("Get my first job offer")}
              </TimelineContent>
            </TimelineItem>
          ) : (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={timelineContentSx}>
                <Trans
                  t={t}
                  defaults="<bold>💼 Employment.</bold> 90% of our grads land a job within 30 days—we’ll help you get there. "
                  components={{ bold: <b /> }}
                />
              </TimelineContent>
            </TimelineItem>
          )}
        </Timeline>
      </AnimatedContainer>

      {/* Call to actions based on screen size */}
      <Box
        sx={{
          position: "fixed",
          bottom: 24,
          right: 24,
          zIndex: 1300, // Higher to sit above all content
          display: { xs: "flex", md: "none" },
        }}
      >
        <Fab
          variant="extended"
          color="primary"
          onClick={milestoneDates.allDatesPresent ? handleFinish : onContinue}
        >
          <ArrowForwardIcon sx={{ mr: 1 }} />
          {t(milestoneDates.allDatesPresent ? "Finish" : "Continue")}
        </Fab>
      </Box>

      <Box
        sx={{
          display: { xs: "none", md: "block" },
          mt: 3,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={milestoneDates.allDatesPresent ? handleFinish : onContinue}
        >
          {t(milestoneDates.allDatesPresent ? "Finish" : "Continue")}
        </Button>
      </Box>
    </Box>
  );
}
