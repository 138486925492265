import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function PayPeriodInfo() {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Alert severity="info">
        <AlertTitle>{t("What is a pay period?")}</AlertTitle>
        <Typography>
          {t("A pay period is the time you worked before getting paid.")}
        </Typography>
        <Button
          size="small"
          onClick={() => setExpanded(!expanded)}
          sx={{ mt: 1 }}
        >
          {expanded ? t("Hide Details") : t("Learn More")}
        </Button>
        <Collapse in={expanded}>
          <List>
            <ListItem>
              <ListItemText
                primary={t(
                  "Example 1: If you were paid on March 15 for work done from March 1 to March 15, your pay period is March 1–15."
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t(
                  "Example 2: If you haven’t been paid for a certain period, select the dates you worked but didn’t receive payment for."
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t(
                  "Example 3: If you have a bank record that shows your income for several months, select the entire range that the document covers."
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t(
                  "Example 4 (YTD): If you have a pay stub that shows your year-to-date earnings, you can simply upload that stub and mark the entire year."
                )}
              />
            </ListItem>
          </List>
        </Collapse>
      </Alert>
    </Stack>
  );
}
