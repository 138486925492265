import { UserAccount } from "src/types/User";

export default function isJusticeInvolved(
  application: UserAccount["application"]
): boolean {
  const hasBeenIncarcerated = !!(
    application?.hasBeenIncarcerated || application?.isIncarcerated
  );

  const isJusticeInvolved: boolean = !!(
    hasBeenIncarcerated ||
    application?.isProbationOrParole ||
    application?.hasBeenProbationOrParole ||
    application?.hasDoneDiversionProgram
  );

  return isJusticeInvolved;
}
