import {
  Box,
  Card,
  CardContent,
  CardHeader,
  LinearProgressProps,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { QuizRecord } from "src/clients/schema";
import Button from "src/components/Button";
import GaugeVelocimeter from "src/components/GaugeVelocimeter";
import { Header20 } from "src/components/Typography";
import mapQuizCategoryToName from "src/pages/ClientScreen/mapQuizCategoryToName";
import { trackEvent } from "src/pages/OnboardingScreen/amplitude";
import calculateProficiencyScore from "../calculateProficiencyScore";

type Props = {
  category: string;
  exams: (Pick<QuizRecord, "id"> & { grade?: number })[];
};

export default function ExamCategoryCard({ exams, category }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    trackEvent("Exam Category Card - Clicked on Continue", {
      category,
    });
    // Find the next exam that has not yet been attempted
    const nextExam = exams.find((exam) => !exam.grade);
    if (nextExam) navigate(`/quizzes/${nextExam.id}`);
    else navigate(`/quizzes?category=${category}`);
  };

  const handleViewAllExams = () => {
    trackEvent("Exam Category Card - Clicked on View All", {
      category,
    });
    navigate(`/quizzes?category=${category}`);
  };

  const proficiency: {
    progressColor: Extract<
      LinearProgressProps["color"],
      "success" | "warning" | "secondary"
    >;
    proficiencyScore: number;
    label: string;
    description: string;
    buttonLabel: string;
  } | null = useMemo(() => {
    const attemptedExams = exams.filter((exam) => exam.grade !== undefined);

    const proficiencyScore = calculateProficiencyScore(
      attemptedExams as { grade: number }[]
    );

    if (proficiencyScore === null)
      return {
        progressColor: "secondary",
        proficiencyScore: 0,
        label: t("No Score Yet"),
        description: t(
          "Once you finish your 1st exam, you will see your proficiency score here."
        ),
        buttonLabel: t("Get Started"),
      };

    if (proficiencyScore >= 0.8)
      return {
        progressColor: "success",
        proficiencyScore,
        label: t("Test-Ready 🚀"),
        description: t("Ready for the exam! Keep practicing to stay sharp."),
        buttonLabel: t("Practice"),
      };
    return {
      progressColor: "warning",
      proficiencyScore,
      label: t("Keep Practicing 💪"),
      description: t("Practice to boost your score—aim for 80+ to pass."),
      buttonLabel: t("Practice"),
    };
  }, [exams, t]);

  const attemptedExams = exams.filter(
    (exam) => exam.grade !== undefined
  ).length;
  return (
    <Card
      variant="outlined"
      onClick={handleClick}
      sx={{ cursor: "hover", ":hover": { opacity: 0.8 } }}
    >
      <CardHeader
        title={<Header20>{mapQuizCategoryToName(category)}</Header20>}
        subheader={
          attemptedExams === 0
            ? t("No exams done yet")
            : attemptedExams === 1
            ? t("1 exam completed")
            : t("{{attemptedExams}} exams completed", {
                attemptedExams,
              })
        }
      />
      <CardContent>
        <Box flexDirection="column" alignItems="center" display="flex">
          <Box>
            <GaugeVelocimeter
              value={Math.round(proficiency.proficiencyScore * 100)}
              startAngle={-110}
              endAngle={110}
              size={isMobileOnly ? 120 : 160}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginBottom={2}
          >
            <Header20 textAlign="center">{proficiency.label}</Header20>
            <Typography
              variant="body2"
              textAlign="center"
              color="text.secondary"
            >
              {proficiency.description}
            </Typography>
          </Box>
          <Button
            variant={
              proficiency?.progressColor === "success"
                ? "outlined"
                : "contained"
            }
            onClick={handleClick}
          >
            {proficiency.buttonLabel}
          </Button>
          <Button sx={{ marginTop: 1 }} onClick={handleViewAllExams}>
            {t("View All Exams")}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
