/**
 * Utility to extract and return a date string in "YYYY-MM-DD" format.
 *
 * ## When to Use:
 * - When storing or displaying dates **without** time information.
 * - When avoiding timezone-related shifts that occur when saving full timestamps.
 * - When ensuring consistency across different user timezones by stripping out time data.
 *
 * ## How it Works:
 * - Converts the given `Date` object to an ISO string in UTC format.
 * - Splits at `"T"` to remove time information and returns only the date portion.
 *
 * ## Example Usage:
 * ```typescript
 * const date = new Date("2024-02-13T15:30:00.000Z");
 * console.log(getUTCDateOnly(date)); // "2024-02-13"
 * ```
 *
 * @param date - A valid JavaScript `Date` object.
 * @returns The date in `"YYYY-MM-DD"` format.
 */
export function getUTCDateOnly(date: Date): string {
  return date.toISOString().split("T")[0];
}

export function normalizeDateString(dateString: string): string {
  const date = new Date(dateString);
  if (Number.isNaN(date.getTime())) {
    throw new Error(`Invalid date string provided: "${dateString}"`);
  }
  return date.toISOString().split("T")[0];
}
