/* JobSearchTrainingTab.tsx */

import { ViewSidebarTwoTone } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import VideoAskActionItemCard from "src/components/Card/VideoAskActionItemCard";

import { PaddedLayout } from "src/components/Layout";
import PaddedLayoutContainer from "src/components/Layout/PaddedLayoutContainer";
import { Header20 } from "src/components/Typography";
import VideoPlayer from "src/components/VideoPlayer/VideoPlayer";
import { UserAccount } from "src/types/User";
import { trackEvent } from "./OnboardingScreen/amplitude";
import VideoAskSchema from "./OnboardingScreen/videoAskIds.enum";

type Props = { user: UserAccount };

interface Question {
  question: string;
  answer: string;
  video?: string; // optional
}

interface Category {
  label: string;
  questions: Question[];
}

const categories: Category[] = [
  {
    label: "Getting Your First Job",
    questions: [
      {
        question: "How easy will it be?",
        answer:
          "It’s a numbers game. At Emerge, we like to say you should aim to submit 100 applications within your first 30 days after graduation. Keep at it until you find a trucking company willing to take you on—you only need one ‘yes.’",
        video:
          "https://www.youtube.com/watch?v=5xvMKaWmGQU&ab_channel=ETTransport",
      },
      {
        question: "Why is my first job not going to be the best job?",
        answer:
          "Your first trucking job is rarely the best for a few reasons. You’re still proving yourself in the industry, so companies may start you on less desirable routes or with older equipment. You might also see lower pay or fewer benefits than drivers who’ve built up experience. However, that first job is essential—it’s where you learn the ropes, build a safe driving record, and show employers you can handle the demands of the road. Once you’ve proven yourself, you’ll have more leverage to seek out higher-paying positions with better routes, benefits, and work-life balance.",
      },
      {
        question:
          "Why do I need to focus on getting a year of driving experience?",
        answer:
          "After you get your first year under your belt, you can apply to more jobs and have more options. You can also get better pay and benefits. Everyone wants an experienced driver.",
      },
      {
        question: "How do I stay motivated even after I get my first 'no's? ",
        answer:
          "Rejection is part of the process, not the end of the road. Every “no” brings you one step closer to the right opportunity. Stay focused on your goal, keep applying, and remember that persistence is what gets you hired—not instant success.",
        video: "https://www.youtube.com/watch?v=YC3G6NEYXrU&ab_channel=Nell",
      },
    ],
  },
  {
    label: "Understanding Driving Jobs",
    questions: [
      {
        question: "What types of driving jobs are there?",
        answer:
          "There are local, regional, and over-the-road (OTR) positions. Local drivers typically go home every night, while OTR drivers can be on the road for weeks. Specialized driving includes hauling hazmat, tanker, or oversized loads.",
        video:
          "https://www.youtube.com/watch?v=N8dhdZ86_nc&ab_channel=ETTransport",
      },
      {
        question: "What some examples of driving gigs?",
        answer: "",
        video: "https://www.youtube.com/shorts/kYusecktQnM",
      },
    ],
  },
  {
    label: "Life as a Driver",
    questions: [
      {
        question: "What is the life of a rookie driver?",
        answer: "",
        video: "https://www.youtube.com/watch?v=31Wj5TrIdDE",
      },
      {
        question: "What is a day in the life of an OTR driver?",
        answer: "",
        video:
          "https://www.youtube.com/watch?v=spD0Xye34OI&ab_channel=TezTruckTalk",
      },

      {
        question: "How do I stay healthy on the road?",
        answer:
          "Plan meals, exercise when you can, and schedule breaks. Many truck stops have healthier food options and gyms. Regular check-ups are crucial to stay fit for the job.",
        video:
          "https://www.youtube.com/watch?v=tZyL6KYNh5w&ab_channel=DrivenTrucking",
      },
    ],
  },
];

// Simple TabPanel helper component
function TabPanel(props: {
  children?: React.ReactNode;
  value: number;
  index: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function JobSearchTrainingTab({ user }: Props) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <PaddedLayout noXMargin={isMobileOnly}>
      <PaddedLayoutContainer>
        <Box mb={2}>
          <Header20>{t("Applying for your first job")}</Header20>
        </Box>

        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={6}>
            <VideoAskActionItemCard
              title={VideoAskSchema.RESUME_BUILDER.label}
              description={t(
                "What if writing a resume felt like chatting with a friend? Now it does. Tell us about your experience, and we’ll create a standout resume and cover letter for you."
              )}
              item={VideoAskSchema.RESUME_BUILDER}
              icon={ViewSidebarTwoTone}
              user={user}
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          <Header20>{t("Understanding the job search")}</Header20>
        </Box>
        {/* Categories as “pills” (Tabs) */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Job Search Categories"
        >
          {categories.map((category) => (
            <Tab key={category.label} label={category.label} />
          ))}
        </Tabs>

        {/* Each TabPanel contains the list of questions as Accordions */}
        {categories.map((category, index) => (
          <TabPanel key={category.label} value={activeTab} index={index}>
            {category.questions.map((q) => (
              <Accordion
                key={`${category.label}`}
                sx={{ mb: 1 }}
                onChange={() =>
                  trackEvent("Job Search Tab - Clicked on Question", {
                    question: q.question,
                  })
                }
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">{q.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {q.answer}
                  </Typography>
                  {q.video && (
                    <Box>
                      <VideoPlayer
                        onPlay={() =>
                          trackEvent("Job Search Tab - Clicked on Video", {
                            question: q.question,
                            answer: q.answer,
                            video: q.video,
                          })
                        }
                        onEnded={() =>
                          trackEvent("Job Search Tab - Video Ended", {
                            question: q.question,
                            answer: q.answer,
                            video: q.video,
                          })
                        }
                        url={q.video}
                        controls
                        width="100%"
                      />
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </TabPanel>
        ))}
      </PaddedLayoutContainer>
    </PaddedLayout>
  );
}
