import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserSummary } from "src/types/UserSummary";
import simplifyUserStatus from "./simplifyStatus";

type Props = {
  summary: UserSummary;
};

export default function ReferralAdditionalInformationCell({ summary }: Props) {
  const status = simplifyUserStatus(summary);
  const { t } = useTranslation();
  const MAX_COURSE_ITEMS = 42;

  switch (status) {
    case "No Active Program":
      return <div />;
    case "Incomplete Application":
      return <div />;
    case "Inactive":
      return <div />;
    case "Withdrawn":
      return <div />;
    case "Rejected":
      return <div />;
    case "Theory Training":
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Typography
            display="flex"
            margin="auto"
            textAlign="center"
            variant="body2"
          >
            {t("Theory Completion:")}{" "}
            {Math.min(
              Math.round(
                (Math.min(
                  summary.videosStartedCount + summary.totalExamsCompleted || 0,
                  MAX_COURSE_ITEMS
                ) /
                  MAX_COURSE_ITEMS) *
                  100
              ),
              100
            )}
            %
          </Typography>
        </Box>
      );
    case "Skills Training":
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Typography
            display="flex"
            margin="auto"
            textAlign="center"
            variant="body2"
          >
            {t("Training Sessions Attended:")} {summary.attendanceCount}
          </Typography>
        </Box>
      );
    case "Licensed":
      return <div />;
    case "Employed":
      return <div />;
    default:
      return <div />;
  }
}
