import { doc, setDoc } from "firebase/firestore";
import Joi from "joi";
import { Clients } from "src/contexts/AppContext";
import { TrainingStage } from "src/types";
import { UserAccount, UserUploadedDocument } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";
import { StudentDocument } from "./FirestoreClient";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";
import uploadFile from "./uploadFile";

type Data = {
  user: UserAccount;
  file: File;
  type: StudentDocument;
  stage: TrainingStage;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  file: Joi.object().required(),
  type: Joi.string().required(),
  stage: Joi.string().required(),
});

// for backward compatibility
const createUserDefaultEnrollmentDocument = serviceFunction(
  schema,
  async ({ user, type, file, stage }, clients: Clients) => {
    // Generate a unique file URL

    const filePath = `documents/${user.uid}/${type}-${file.name}`;

    // Upload the file to Firebase Storage
    const uploadResult = await uploadFile(
      { url: filePath, blob: file },
      clients
    );

    // Create the document metadata
    const documentData: Omit<UserUploadedDocument, "uid"> = {
      path: uploadResult.ref.fullPath,
      userId: user.uid,
      name: type,
      stage,
      ...generateFirestoreTimestamps(new Date(), "createdAt"),
    };

    // Add the document metadata to Firestore
    await setDoc(
      doc(clients.db, "users", user.uid, "documents", type),
      documentData
    );
  }
);

export default createUserDefaultEnrollmentDocument;
