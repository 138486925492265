import { Folder } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSession } from "src/contexts/AuthContext";
import {
  MilestoneDocument,
  StudentDocument,
} from "src/firebase/FirestoreClient";
import AppSkeleton from "src/pages/AppSkeleton";
import { useSnackbarContext } from "src/SnackbarProvider";
import { UserAccount } from "src/types/User";
import ApplicationDocumentList from "./ApplicationDocumentList";
import DocumentCard, { DocumentDescription } from "./DocumentCard";
import useUploadedDocuments from "./useUploadedDocuments";

type Props = {
  client: UserAccount;
  initialExpanded?: DocumentSection;
  showMilestones?: boolean;
};

const MILESTONE_DOCUMENTS: Record<MilestoneDocument, DocumentDescription> = {
  background_check: { label: "Background Check", key: "background_check" },
  driver_record: { label: "Driver's Record", key: "driver_record" },
  theory_permit: { label: "Permit (CLP)", key: "theory_permit" },
  license: { label: "CDL License", key: "license" },
  medical_exam: { label: "DOT Medical Exam", key: "medical_exam" },
  updated_resume: {
    label: "Updated Resume",
    key: "updated_resume",
  },
  completion_certificate: {
    label: "Completion Certificate",
    key: "completion_certificate",
  },
  cover_letter: {
    label: "Cover Letter",
    key: "cover_letter",
  },
  job_offer: {
    label: "Job Offer",
    key: "job_offer",
  },
};

type DocumentSection = "milestones" | "basic_documents";

const DocumentList = ({ client, initialExpanded, showMilestones }: Props) => {
  const session = useSession();
  const { documents, refetch, findDocument } = useUploadedDocuments(client);

  const [expanded, setExpanded] = React.useState<DocumentSection | undefined>(
    initialExpanded
  );

  const handleChange =
    (panel: DocumentSection) =>
    (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : undefined);
    };

  const snackbarContext = useSnackbarContext();
  const { t } = useTranslation();
  const theme = useTheme();

  const handleSubmission = useCallback(
    async (file: File, type: StudentDocument) => {
      await session.uploadStudentDocument({ file, type }, client?.uid);
      snackbarContext.alert("success", t("Successfully uploaded the document"));
      await refetch();
    },
    [session, client?.uid, snackbarContext, t, refetch]
  );

  if (!documents) return <AppSkeleton />;

  return (
    <Stack spacing={theme.spacing(2)}>
      <Accordion
        expanded={expanded === "basic_documents"}
        onChange={handleChange("basic_documents")}
      >
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          sx={{
            backgroundColor: theme.palette.primary.light,
            border: 1,
            borderColor: theme.palette.grey[50],
          }}
        >
          <Stack spacing={theme.spacing(2)}>
            <Stack
              direction="row"
              spacing={theme.spacing(1)}
              alignItems="center"
            >
              <Folder color="info" />
              <Typography>{t("Application")}</Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%">
            <ApplicationDocumentList client={client} />
          </Box>
        </AccordionDetails>
      </Accordion>

      {showMilestones && (
        <Accordion
          expanded={expanded === "milestones"}
          onChange={handleChange("milestones")}
        >
          <AccordionSummary
            expandIcon={<GridExpandMoreIcon />}
            sx={{
              backgroundColor: theme.palette.primary.light,
              border: 1,
              borderColor: theme.palette.grey[50],
            }}
          >
            <Stack spacing={theme.spacing(2)}>
              <Stack
                direction="row"
                spacing={theme.spacing(1)}
                alignItems="center"
              >
                <Folder color="info" />
                <Typography>{t("Program")}</Typography>
              </Stack>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container gap={theme.spacing(4)}>
              {Object.values(MILESTONE_DOCUMENTS).map((doc) => (
                <Grid item xs={12} key={doc.key}>
                  <DocumentCard
                    documentDescription={doc}
                    submittedDocument={findDocument(doc.key)}
                    handleSubmission={handleSubmission}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </Stack>
  );
};

export default DocumentList;
