import { Card, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchMilestoneTasks from "src/firebase/fetchMilestoneTasks";
import { MilestoneTask } from "src/types/MilestoneTask";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import useErrorHandler from "src/utils/useErrorHandler";
import MilestoneCardHeader from "./MilestoneCardHeader";

type Props = {
  milestone: StudentPlanMilestone;
};
export default function MinifiedMilestoneCard({ milestone }: Props) {
  const [tasks, setTasks] = useState<MilestoneTask[]>();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    fetchMilestoneTasks(
      {
        milestoneId: milestone.uid,
        userId: milestone.userId,
      },
      clients
    )
      .then(setTasks)
      .catch(errorHandler);
  }, [clients, errorHandler, milestone.uid, milestone.userId]);

  return (
    <Card>
      <CardContent>
        <MilestoneCardHeader tasks={tasks} milestone={milestone} />
      </CardContent>
    </Card>
  );
}
