import { Button, ButtonProps } from "@mui/material";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../ConfirmDialog";

type ActionButtonWithConfirmDialogProps = {
  onAction: () => void; // Callback function that triggers the action
  title?: string; // Title for the confirm dialog
  description?: string; // Description for the confirm dialog
  buttonLabel?: string; // Label for the button
  buttonVariant?: ButtonProps["variant"]; // Button variant (text, contained, outlined, etc.)
  buttonColor?: ButtonProps["color"]; // Button color
  startIcon?: ReactNode; // Icon to display before the button label
} & ButtonProps;

const ActionButtonWithConfirmDialog = ({
  onAction,
  title,
  description,
  buttonLabel,
  buttonVariant = "text",
  buttonColor = "primary",
  startIcon,
  ...rest
}: ActionButtonWithConfirmDialogProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleConfirmAction = () => {
    onAction(); // Trigger the action
    handleCloseDialog(); // Close the dialog after confirming
  };

  return (
    <>
      <Button
        variant={buttonVariant}
        color={buttonColor}
        startIcon={startIcon}
        onClick={handleOpenDialog}
        {...rest}
      >
        {buttonLabel || t("Action")}
      </Button>

      <ConfirmDialog
        open={open}
        title={title || t("Confirm Action")}
        description={
          description ||
          t(
            "Are you sure you want to perform this action? This cannot be undone."
          )
        }
        onConfirm={handleConfirmAction}
        onCancel={handleCloseDialog}
        buttonColor={buttonColor}
      />
    </>
  );
};

export default ActionButtonWithConfirmDialog;
