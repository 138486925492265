import { Stack, Typography, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import SelectInput from "src/components/Card/SelectInput";
import TextInput from "src/components/Input/TextInput";
import { ScreenTitle } from "src/components/Typography";
import { SEED_REFERENCE_DATA } from "src/seed";
import {
  ReferenceStatus,
  StudentReference,
  StudentReferenceType,
} from "src/types/StudentReference";
import isValidUSPhoneNumber from "src/utils/isValidUSPhoneNumber";
import useIsAdmin from "src/utils/useIsAdmin";

type Props = {
  onSubmit: (data: ReferenceFormData) => Promise<void>;
  defaultValues?: Partial<ReferenceFormData>;
};

export type ReferenceFormData = Omit<
  StudentReference,
  | "uid"
  | "userId"
  | "createdAt"
  | "createdAtFirestoreTimestamp"
  | "verifiedAt"
  | "verifiedAtFirestoreTimestamp"
  | "subscribedAt"
  | "subscribedAtFirestoreTimestamp"
  | "lastUpdatedAt"
  | "lastUpdatedAtFirestoreTimestamp"
> & {
  verified: boolean;
  subscribed: boolean;
};

export default function ReferenceForm({ onSubmit, defaultValues }: Props) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ReferenceFormData>({
    mode: "onTouched",
    defaultValues:
      defaultValues ||
      (process.env.NODE_ENV === "development" ? SEED_REFERENCE_DATA : {}),
  });
  const { t } = useTranslation();
  const theme = useTheme();
  const isAdmin = useIsAdmin();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={theme.spacing(2)}>
        <Stack spacing={theme.spacing(1)}>
          <ScreenTitle>{t("Add Reference")}</ScreenTitle>
          <Typography variant="body1">
            {t(
              "References are individuals that can vouch and support you throughout the program."
            )}
          </Typography>
        </Stack>
        <SelectInput
          name="type"
          label="What type of reference is this?"
          control={control}
          items={Object.entries(StudentReferenceType).map((entry) => ({
            value: entry[1],
            name: entry[1],
          }))}
          rules={{
            required: {
              value: true,
              message: t("Type is required"),
            },
          }}
        />
        <TextInput
          control={control}
          name="relationship"
          helperText="e.g. Parent, Family, Friend, Mentor"
          label={t("Relationship")}
          rules={{
            required: { value: true, message: t("Field is required") },
          }}
        />
        <TextInput
          control={control}
          name="firstName"
          label={t("First name")}
          rules={{
            required: { value: true, message: t("First name is required") },
          }}
        />
        <TextInput
          control={control}
          name="lastName"
          label={t("Last name")}
          rules={{
            required: { value: true, message: t("Last name is required") },
          }}
        />
        <TextInput
          control={control}
          name="phone"
          label={t("Phone number")}
          rules={{
            required: { value: true, message: t("Phone number is required") },
            validate: (value) =>
              isValidUSPhoneNumber(value) ||
              t("Please enter a valid phone number"),
          }}
        />
        <TextInput
          control={control}
          name="email"
          label={t("Email")}
          helperText={t("Leave blank if unknown")}
        />

        {isAdmin && (
          <>
            <SelectInput
              control={control}
              name="status"
              label={t("Status")}
              items={Object.entries(ReferenceStatus).map((entry) => ({
                value: entry[0],
                name: entry[1],
              }))}
              disabled={!isAdmin}
            />
            <TextInput
              control={control}
              name="statusDetails"
              label={t("Public Notes")}
              disabled={!isAdmin}
            />
            <TextInput
              control={control}
              name="internalStatusDetails"
              label={t("Internal Notes")}
              disabled={!isAdmin}
            />
          </>
        )}
        <SubmitButton loading={isSubmitting} disabled={isSubmitting}>
          {defaultValues ? t("Update") : t("Create")}
        </SubmitButton>
      </Stack>
    </form>
  );
}
