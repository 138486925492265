import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import {
  Box,
  Card,
  CardContent,
  Stack,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  StepperProps,
} from "@mui/material";

import { CircleTwoTone } from "@mui/icons-material";
import { useLayoutEffect, useRef } from "react";
import CreateBackgroundCheckCard from "src/components/Card/CreateBackgroundCheckCard";
import CreateSkillsTrainingPlanCard from "src/components/Card/CreateSkillsTrainingPlanCard";
import WebinarCard, { WEBINAR_SERIES } from "src/components/Card/WebinarCard";
import { UserAccount } from "src/types/User";
import MilestoneScheduleCard from "../ClientScreen/StudentPlanTab/MilestoneScheduleCard";
import UploadDocumentsCard from "./DocumentsTab/UploadDocumentsCard";
import OnHoldAlert from "./OnHoldAlert";
import useCurrentTrainingStep, {
  trainingLabels,
} from "./useCurrentTrainingStep";

const StepIcon = ({
  completed,
  active,
}: {
  completed: boolean;
  active: boolean;
}) => {
  if (completed) {
    return <CheckCircleIcon color="success" />;
  }
  if (active) {
    return <CircleTwoTone style={{ color: "#ffffff" }} />;
  }
  return <CircleOutlinedIcon style={{ color: "#9cbcd4" }} />;
};

type Props = { user: UserAccount };
export default function TrainingProgressStepper({ user }: Props) {
  const currentStep = useCurrentTrainingStep(user);
  const stepIndex = trainingLabels.indexOf(
    currentStep.trainingStep.trainingLabel
  );

  const stepRefs = useRef<HTMLDivElement[]>([]);

  const stepperProps: StepperProps = {
    alternativeLabel: false,
    orientation: "horizontal",
    connector: <StepConnector sx={{ borderColor: "#ffffff" }} />,
  };

  useLayoutEffect(() => {
    if (stepRefs.current[stepIndex]) {
      stepRefs.current[stepIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [stepIndex]);

  if (user.applicationStatus === "on_hold") {
    return <OnHoldAlert user={user} />;
  }

  return (
    <Box>
      {/* Stepper Card */}
      <Card
        sx={{
          background: "linear-gradient(37deg, #002D5A 28.63%, #134D89 106.9%)",
          md: { padding: 4 },
          borderRadius: 2,
        }}
      >
        <CardContent sx={{ overflowX: "auto" }}>
          <Stepper activeStep={stepIndex} {...stepperProps}>
            {trainingLabels.map((label, index) => (
              <Step key={label}>
                <div
                  ref={(el) => {
                    if (el) stepRefs.current[index] = el;
                  }}
                >
                  <StepLabel
                    StepIconComponent={(props) =>
                      StepIcon({
                        ...props,
                        completed: index < stepIndex,
                        active: index === stepIndex,
                      })
                    }
                    sx={{
                      "& .MuiStepLabel-label": {
                        color: "#ffffff",
                      },
                      "& .MuiStepLabel-label.Mui-active": {
                        color: "#ffffff !important",
                        fontWeight: "bold",
                      },
                      "& .MuiStepLabel-label.Mui-completed": {
                        color: "#ffffff !important", // Overwrite MUI’s default
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </div>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>

      {/* Area with immediate to-do based on current steps */}
      <Box marginTop={2}>
        <Stack spacing={2}>
          {currentStep.isBeforeStep("DOT Scheduling") && (
            <Box>
              <WebinarCard webinar={WEBINAR_SERIES.kickoff} />
            </Box>
          )}

          {currentStep.hasCompletedStep("Online Training") &&
            currentStep.isBeforeStep("Exam Practice") && (
              <WebinarCard webinar={WEBINAR_SERIES.scheduling_appointments} />
            )}

          {currentStep.trainingStep.actionItems?.map((actionItem) => (
            <Box key={actionItem.milestoneType}>
              <MilestoneScheduleCard
                user={user}
                milestoneType={actionItem.milestoneType}
                onCreate={actionItem.onCreate}
              />
            </Box>
          ))}

          {currentStep.hasCompletedStep("CLP Scheduling") &&
            currentStep.isBeforeStep("Skills Training") && (
              <Stack spacing={1}>
                <CreateBackgroundCheckCard user={user} />
                <UploadDocumentsCard user={user} />
              </Stack>
            )}

          {currentStep.hasCompletedStep("Enrollment") &&
          currentStep.isBeforeStep("Job Search") ? (
            <Box>
              <CreateSkillsTrainingPlanCard user={user} />
            </Box>
          ) : (
            <div />
          )}
        </Stack>
      </Box>
    </Box>
  );
}
