import { Alert, AlertTitle, Grid, Stack, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, subMonths } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import SubmitButton from "src/components/Button/SubmitButton";
import ImageInput from "src/components/ImageInput";
import DateInput from "src/components/Input/DateInput";
import RadioInput from "src/components/Input/RadioInput";
import TextInput from "src/components/Input/TextInput";
import PayPeriodInfo from "./PayPeriodInfo";

export type PayStub = {
  startDate: Date;
  endDate: Date;
} & (
  | {
      incomeStatus: "noIncome";
      grossPay?: never;
      file?: never;
    }
  | {
      incomeStatus: "withProof";
      grossPay: number;
      file: File;
    }
  | {
      incomeStatus: "withoutProof";
      grossPay: number;
      file?: never;
    }
);

interface MissingPeriod {
  start: Date;
  end: Date;
}

interface PayStubFormProps {
  onAddPayStub: (payStub: PayStubFormData) => void;
  missingPeriods: MissingPeriod[];
  onClose?: () => void;
}

type PayStubFormData = {
  startDate: Date;
  endDate: Date;
  incomeStatus: "withProof" | "withoutProof" | "noIncome";
  grossPay?: number;
  file?: File;
};

export default function PayStubForm({
  onAddPayStub,
  missingPeriods,
  onClose,
}: PayStubFormProps) {
  const { t } = useTranslation();
  const MIN_DATE = subMonths(new Date(), 6);
  const MAX_DATE = new Date();

  const {
    control,
    handleSubmit,
    trigger,
    formState: { isSubmitting },
    watch,
  } = useForm<PayStubFormData>({
    mode: "onTouched",
    defaultValues: {
      incomeStatus: "noIncome",
      grossPay: undefined,
      file: undefined,
    },
  });

  // Step wizard state (0: Pay Period, 1: Income Status, 2: Income Details)
  const [currentStep, setCurrentStep] = useState(0);

  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const incomeStatus = watch("incomeStatus");

  // Handler to move back one step.
  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const onSubmit = (data: PayStubFormData) => {
    onAddPayStub(data);
    if (onClose) onClose();
  };

  // Handler to move to the next step after validating current step fields.
  const handleNext = async () => {
    if (currentStep === 0) {
      // Validate startDate and endDate
      const valid = await trigger(["startDate", "endDate"]);
      if (valid) {
        setCurrentStep(1);
      }
    } else if (currentStep === 1) {
      // Validate incomeStatus (it's always valid since we have a default)
      // If incomeStatus is "noIncome", we can submit immediately.
      if (incomeStatus === "noIncome") {
        handleSubmit(onSubmit)();
      } else {
        setCurrentStep(2);
      }
    } else if (currentStep === 2) {
      // For income details: always require grossPay; if withProof, require file too.
      const fieldsToValidate: Array<keyof PayStubFormData> =
        incomeStatus === "withProof" ? ["grossPay", "file"] : ["grossPay"];
      const valid = await trigger(fieldsToValidate);
      if (valid) {
        handleSubmit(onSubmit)();
      }
    }
  };

  return (
    <form>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={4} mb={4}>
          {/* Step 1: Pay Period */}
          {currentStep === 0 && (
            <Stack spacing={2}>
              <Typography variant="h6">
                {t("Step 1: Select Your Pay Period")}
              </Typography>
              <PayPeriodInfo />
              {missingPeriods.length > 0 && (
                <Alert severity="warning">
                  <AlertTitle>
                    {t(
                      "We don’t have income records for the following date ranges:"
                    )}
                  </AlertTitle>
                  {missingPeriods.map((range) => (
                    <Typography key={JSON.stringify(range)}>
                      {format(range.start, "MMM dd, yyyy")} –{" "}
                      {format(range.end, "MMM dd, yyyy")}
                    </Typography>
                  ))}
                </Alert>
              )}
              <DateInput
                name="startDate"
                label={t("Start Date")}
                control={control}
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
              />
              <DateInput
                name="endDate"
                label={t("End Date")}
                control={control}
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
              />
            </Stack>
          )}

          {/* Step 2: Income Status */}
          {currentStep === 1 && startDate && endDate && (
            <Stack spacing={2}>
              <Typography variant="h6">
                {t("Step 2: Tell Us About Your Income")}
              </Typography>
              <Alert severity="info">
                <AlertTitle>
                  {t("For the period {{start}} to {{end}}", {
                    start: format(startDate, "MMM dd, yyyy"),
                    end: format(endDate, "MMM dd, yyyy"),
                  })}
                </AlertTitle>
                <Typography>
                  {t("Did you have income during this time?")}
                </Typography>
              </Alert>
              <RadioInput
                name="incomeStatus"
                label={t("Did you have income during this time?")}
                control={control}
                items={[
                  {
                    value: "withProof",
                    label: t("I had income and I have a pay stub to prove it"),
                  },
                  {
                    value: "withoutProof",
                    label: t(
                      "I had income but I don't have a pay stub to prove it"
                    ),
                  },
                  { value: "noIncome", label: t("I didn’t have income") },
                ]}
              />
            </Stack>
          )}

          {/* Step 3: Income Details (if applicable) */}
          {currentStep === 2 &&
            (incomeStatus === "withProof" ||
              incomeStatus === "withoutProof") && (
              <Stack spacing={2}>
                <Typography variant="h6">
                  {t("Step 3: Enter Your Income Details")}
                </Typography>
                {incomeStatus === "withProof" && (
                  <ImageInput
                    label={t("Upload Pay Stub")}
                    name="file"
                    control={control}
                    rules={{
                      required: t("Pay stub file is required"),
                      validate: (value) =>
                        value instanceof File || t("Pay stub file is required"),
                    }}
                  />
                )}
                <TextInput
                  control={control}
                  name="grossPay"
                  label={t("Gross Pay")}
                  type="number"
                  rules={{
                    required: t("Gross pay is required"),
                    min: { value: 1, message: t("Must be at least $1") },
                  }}
                />
              </Stack>
            )}
        </Stack>

        {/* Navigation Buttons */}
        <Grid container justifyContent="space-between">
          {currentStep > 0 && (
            <Grid item>
              <Button variant="outlined" onClick={handleBack}>
                {t("Back")}
              </Button>
            </Grid>
          )}
          <Grid item>
            {currentStep < 1 ||
            (currentStep === 1 && incomeStatus !== "noIncome") ? (
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={isSubmitting}
              >
                {t("Next")}
              </Button>
            ) : (
              // When on step 1 with incomeStatus "noIncome" or on step 2 (final step), show Submit.
              <SubmitButton
                loading={isSubmitting}
                onClick={handleNext}
                disabled={isSubmitting}
              >
                {t("Submit")}
              </SubmitButton>
            )}
          </Grid>
        </Grid>
      </LocalizationProvider>
    </form>
  );
}
