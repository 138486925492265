import {
  BuildTwoTone,
  DocumentScannerTwoTone,
  FlagTwoTone,
  PsychologyTwoTone,
  QuestionAnswerTwoTone,
  RocketTwoTone,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import ButtonWithDialogExplanation from "src/components/Button/ButtonWithDialogExplanation";
import { Header32 } from "src/components/Typography";
import mapQuizCategoryToName from "../ClientScreen/mapQuizCategoryToName";
import { SliderExplanationStepProp } from "../SliderExplanationScreen";

type Props = {
  onStart: () => void;
  numQuestions: number;
  category: string;
};

const alertMessages: SliderExplanationStepProp[] = [
  {
    type: "component",
    icon: BuildTwoTone,
    title: t("Progress Over Perfection"),
    body: t("Anything above 80% is a win. Perfection is not the goal."),
  },
  {
    type: "component",
    icon: PsychologyTwoTone,
    title: t("Understand, Don't Just Memorize"),
    body: t("Focus on why they’re right, not just the answer."),
  },
  {
    type: "component",
    icon: RocketTwoTone,
    title: t("No Train, No Gain"),
    body: t(
      "Standardized tests are all about practice. The more you do it, the better you get."
    ),
  },
  {
    type: "component",
    icon: DocumentScannerTwoTone,
    title: t("Gather Your Documents Early"),
    body: t(
      "You will need your driver's license, birth certificate, SSN, and a utility bill."
    ),
  },
  {
    type: "component",
    icon: FlagTwoTone,
    title: t("If You Try, You Succeed"),
    body: t(
      "Most students pass after 2 tries. Most who don’t, never take the first one."
    ),
  },
];
const IntroSection = ({ onStart, numQuestions, category }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const selectedAlert = React.useMemo(
    () => alertMessages[Math.floor(Math.random() * alertMessages.length)],
    []
  );

  const minQuestionsToPass = Math.ceil(0.8 * numQuestions);

  return (
    <Stack
      alignItems="center"
      spacing={theme.spacing(2)}
      padding={theme.spacing(4)}
      sx={{
        backgroundColor: theme.palette.primary.dark,
        minHeight: "100vh",
      }}
    >
      <Card>
        <CardContent>
          {/* Top Icons Section */}
          <List>
            <ListItem>
              <ListItemIcon>
                <DocumentScannerTwoTone color="primary" fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary={<Header32>{numQuestions}</Header32>}
                secondary={t("Multiple-Choice Questions")}
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <QuestionAnswerTwoTone color="primary" fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary={<Header32>{minQuestionsToPass}</Header32>}
                secondary={t("Min Correct Answers to Pass")}
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <RocketTwoTone color="primary" fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary={<Header32>{mapQuizCategoryToName(category)}</Header32>}
                secondary={t("Category")}
              />
            </ListItem>
          </List>

          <Divider />

          <Alert variant="outlined" icon={false} severity="info" sx={{ my: 2 }}>
            <AlertTitle>
              {t("Tip: ")}
              {selectedAlert.title}
            </AlertTitle>
            <Stack spacing={1}>
              <Typography variant="body2">{selectedAlert.body}</Typography>
              <Box display="flex" width="100%" justifyContent="flex-end">
                <ButtonWithDialogExplanation
                  title={t("Review Tips")}
                  steps={alertMessages}
                  trackingEventIdentifier="quiz_tips"
                />
              </Box>
            </Stack>
          </Alert>

          {/* Start Button */}
          <Button
            onClick={onStart}
            variant="contained"
            size="large"
            fullWidth={isMobileOnly}
          >
            {t("Start")}
          </Button>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default IntroSection;
