import { CheckOutlined, Delete } from "@mui/icons-material";
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { PayStub } from "./IncomeVerificationScreen/PayStubForm";

interface PayStubTableProps {
  stubs: PayStub[];
  onRemove: (index: number) => void;
}

export default function PayStubTable({ stubs, onRemove }: PayStubTableProps) {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t("Your Pay Stubs")}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("Pay Period")}</TableCell>
            <TableCell>{t("Gross Pay")}</TableCell>
            <TableCell>{t("Proof Uploaded?")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stubs.map((stub, index) => (
            <TableRow key={JSON.stringify(stub)}>
              <TableCell>
                {`${format(
                  new Date(stub.startDate!),
                  "MMM dd, yyyy"
                )} - ${format(new Date(stub.endDate!), "MMM dd, yyyy")}`}
              </TableCell>
              <TableCell>
                {stub.grossPay ? (
                  <Chip
                    label={`$${stub.grossPay}`}
                    color="success"
                    variant="outlined"
                  />
                ) : (
                  <Typography>{t("No income")}</Typography>
                )}
              </TableCell>
              <TableCell>
                {!stub.grossPay ? (
                  "N/A"
                ) : stub.file ? (
                  <Box display="flex" alignItems="center">
                    <CheckOutlined sx={{ color: "green" }} />
                    <Typography>{t("Proof Uploaded")}</Typography>
                  </Box>
                ) : (
                  <Typography>{t("No Proof")}</Typography>
                )}
              </TableCell>
              <TableCell>
                <IconButton color="error" onClick={() => onRemove(index)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
