import { Alert, AlertTitle, Box, Button } from "@mui/material";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { PaddedLayout } from "src/components/Layout";
import PaddedLayoutContainer from "src/components/Layout/PaddedLayoutContainer";
import { UserAccount } from "src/types/User";
import PracticeMaterialsSection from "./PracticeMaterialsSection";
import SkillsTrainingDashboard from "./SkillsTrainingDashboard";
import StudyMaterialSection from "./StudyMaterialSection";

type Props = { user: UserAccount };

export default function SkillsTrainingTab({ user }: Props) {
  const { t } = useTranslation();
  const handleAlertClick = () => {
    const header = document.getElementById("ace-your-cdl-exam-header");
    if (header) {
      header.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <PaddedLayout noXMargin={isMobileOnly} noYMargin>
      <PaddedLayoutContainer>
        <Alert
          onClick={handleAlertClick}
          severity="info"
          sx={{ cursor: "pointer", marginBottom: 2 }}
          action={<Button>{t("Learn More")}</Button>}
        >
          <AlertTitle>
            {t("Pre-trip and air brakes practice is available")}
          </AlertTitle>
          {t(
            "People that used these resources are 2x more likely to pass their CDL exam."
          )}
        </Alert>
        <SkillsTrainingDashboard user={user} />
        <Box my={4}>
          <StudyMaterialSection user={user} />
        </Box>
        <Box marginTop={4}>
          <PracticeMaterialsSection user={user} />
        </Box>
      </PaddedLayoutContainer>
    </PaddedLayout>
  );
}
