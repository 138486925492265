// src/components/DocumentProofSection.tsx

import { RocketTwoTone } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ImageInput from "src/components/ImageInput";
import NumberInput from "src/components/Input/NumberInput";
import { Header20 } from "src/components/Typography";
import { REQUIRED_MILESTONES } from "./StudentPlanMilestoneForm";

export type DocumentProofForm = {
  proofFile?: File;
  receiptFile?: File;
  reimbursementAmountRequested?: number;
};

interface DocumentProofSectionProps {
  completionInstructions: NonNullable<
    typeof REQUIRED_MILESTONES[number]["completeInstructions"]
  >;
  onSubmit: (data: DocumentProofForm) => void;
  /** Optional: If you want to disable the form while uploading */
  disabled?: boolean;
}

/**
 * Renders a small form that allows the user to upload:
 *   1) A required “proof of completion” file
 *   2) Optionally a receipt (if canBeReimbursed = true)
 *
 * If isDocumentProofRequired AND canBeReimbursed are false, returns an empty div.
 */
export default function DocumentProofSection({
  completionInstructions: {
    isDocumentProofRequired,
    canBeReimbursed,
    title,
    description,
  },
  onSubmit,
  disabled,
}: DocumentProofSectionProps) {
  const { t } = useTranslation();

  // Setup a local form
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<DocumentProofForm>({});

  // This function will call the parent's onSubmit
  const handleFormSubmit = (data: DocumentProofForm) => {
    onSubmit(data);
  };

  // If no proof required, return nothing
  if (!isDocumentProofRequired && !canBeReimbursed) {
    return <div />;
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Header20>{title}</Header20>
              <Typography variant="body2">{description}</Typography>
              <ImageInput
                label={t("Your Document")}
                name="proofFile"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("Please upload a file"),
                  },
                }}
                disabled={disabled || isSubmitting}
              />
            </Stack>

            {/* If reimbursable, show an optional receipt file input */}
            {canBeReimbursed && (
              <Stack spacing={1}>
                <Header20>{t("Reimbursement Request")}</Header20>
                <Typography variant="body2">
                  {t(
                    "Emerge can reimburse you for expenses related to this milestone. Please upload a single photo with the applicable receipt."
                  )}
                </Typography>
                <NumberInput
                  label={t("Amount Requested")}
                  name="reimbursementAmountRequested"
                  control={control}
                  placeholder="$"
                />
                <ImageInput
                  label={t("Receipt")}
                  name="receiptFile"
                  control={control}
                  disabled={disabled || isSubmitting}
                />
              </Stack>
            )}
          </Stack>

          <Box mt={3}>
            <Button
              type="submit"
              variant="contained"
              color="success"
              disabled={disabled || isSubmitting}
              startIcon={<RocketTwoTone />}
            >
              {t("Submit")}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}
