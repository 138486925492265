import { ArrowRightAltRounded } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AdminOnlyAlert from "src/components/Button/AdminOnlyAlert";
import DownloadFileButton from "src/components/Button/DownloadFileButton";
import SubmitButton from "src/components/Button/SubmitButton";
import ImageInput from "src/components/ImageInput";
import { CardSkeleton } from "src/components/Skeleton/closet";
import { useAppContext } from "src/contexts/AppContext";
import { useSession } from "src/contexts/AuthContext";
import { StudentDocument } from "src/firebase/FirestoreClient";
import updateDocumentVerification from "src/firebase/updateDocumentVerification";
import { useSessionAccountInformation } from "src/SessionBoundary";
import { useSnackbarContext } from "src/SnackbarProvider";
import { UserType, UserUploadedDocument } from "src/types/User";
import useErrorHandler from "src/utils/useErrorHandler";
import ID from "./OrgID.svg";

export type DocumentDescription = {
  label: string;
  key: StudentDocument;
  description?: string;
  optional?: boolean;
};

type Props = {
  documentDescription: DocumentDescription;
  submittedDocument?: UserUploadedDocument;
  handleSubmission: (file: File, key: StudentDocument) => Promise<void>;
};

type DocumentCardData = {
  uploadedDocument: File;
};

export default function DocumentCard({
  documentDescription,
  submittedDocument,
  handleSubmission,
}: Props): JSX.Element {
  const session = useSession();
  const { t } = useTranslation();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [loadingSubmittedDocument, setLoadingSubmittedDocument] =
    useState(false);
  const [isVerified, setIsVerified] = useState(!!submittedDocument?.verifiedAt);
  const snackbarContext = useSnackbarContext();
  const account = useSessionAccountInformation();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    watch,
  } = useForm<DocumentCardData>();

  const handleOpen = async () => {
    setLoadingSubmittedDocument(true);
    if (submittedDocument) {
      snackbarContext.alert(
        "info",
        t("Fetching the document from our secure storage.")
      );
      const file = await session.getStorageBlob(
        submittedDocument.name,
        submittedDocument.userId
      );
      if (file) setValue("uploadedDocument", file);
    }
    setLoadingSubmittedDocument(false);
    setIsOpen(true);
  };

  const handleVerification = () => {
    if (account.type !== UserType.Admin)
      throw new Error("Only admins can verify");

    if (!submittedDocument)
      throw new Error("Must have submitted the document to verify it");
    updateDocumentVerification(
      { verified: !isVerified, document: submittedDocument },
      clients
    )
      .then(() => setIsVerified(!isVerified))
      .catch(errorHandler);
  };

  const file = watch("uploadedDocument");

  return (
    <div>
      {submittedDocument ? (
        <Card variant="outlined">
          <CardActionArea onClick={handleOpen}>
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack spacing={1}>
                  <Box>
                    <Chip
                      label={t("Submitted {{date}}", {
                        date: submittedDocument.createdAt
                          ? `on ${format(
                              new Date(submittedDocument.createdAt),
                              "MMMM d, yyyy"
                            )}`
                          : "",
                      })}
                      variant="outlined"
                      size="small"
                      color="success"
                    />
                  </Box>
                  <Typography fontWeight="bold" mt={theme.spacing(1)}>
                    {documentDescription.label}
                  </Typography>
                </Stack>
              </Box>

              <AdminOnlyAlert>
                <AlertTitle>
                  {isVerified ? t("Verified 🎉") : t("Not Verified")}
                </AlertTitle>
              </AdminOnlyAlert>
            </CardContent>
          </CardActionArea>
        </Card>
      ) : (
        <Card>
          <CardActionArea onClick={handleOpen}>
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack spacing={1}>
                  <Box>
                    {documentDescription.optional ? (
                      <Chip
                        label={t("If Applicable")}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      <Chip
                        label={t("Required")}
                        color="warning"
                        size="small"
                      />
                    )}
                  </Box>
                  <Typography fontWeight="bold" mt={theme.spacing(1)}>
                    {documentDescription.label}
                  </Typography>
                </Stack>

                <ArrowRightAltRounded />
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      )}

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogContent>
          {documentDescription.description && (
            <Alert color="info">{documentDescription.description}</Alert>
          )}
          <form
            onSubmit={handleSubmit(async (data) => {
              await handleSubmission(
                data.uploadedDocument,
                documentDescription.key
              );
              setIsOpen(false);
            })}
          >
            {loadingSubmittedDocument ? (
              <CardSkeleton />
            ) : (
              <ImageInput
                label={documentDescription.label}
                name="uploadedDocument"
                control={control}
                rules={{ validate: (v) => !!v }}
                placeholderImage={ID}
                placeholderImageWidth={176}
              />
            )}

            <Stack direction="row" sx={{ marginTop: 4 }} alignItems="center">
              {!submittedDocument ? (
                <SubmitButton
                  size="large"
                  disabled={isSubmitting || !file}
                  loading={isSubmitting}
                  sx={{ marginRight: 4 }}
                >
                  {t("Upload")}
                </SubmitButton>
              ) : submittedDocument.path !== file?.name ? (
                <SubmitButton
                  size="large"
                  disabled={isSubmitting}
                  variant="outlined"
                  loading={isSubmitting}
                  sx={{ marginRight: 4 }}
                >
                  {t("Re-upload")}
                </SubmitButton>
              ) : (
                <div />
              )}
            </Stack>
          </form>
          {submittedDocument?.path && (
            <DownloadFileButton filePath={submittedDocument.path} />
          )}
          {file && account.type === UserType.Admin && (
            <Stack direction="row" alignItems="center">
              <Checkbox
                onClick={handleVerification}
                color="success"
                size="small"
                checked={isVerified}
              />
              <Typography variant="body2" color="text.secondary">
                {isVerified ? t("Verified 🎉") : t("Not Verified")}
              </Typography>
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
