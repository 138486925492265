import { doc, updateDoc } from "firebase/firestore";
import Joi from "joi";
import { School } from "src/types/School";
import { UserAccount } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";

type Data = {
  user: UserAccount;
  school: School;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  school: Joi.object().required(),
});

const updateUserSchool = serviceFunction(
  schema,
  async ({ user, school }, { db }): Promise<void> => {
    await updateDoc(doc(db, `users/${user.uid}`), {
      schoolId: school.uid,
    });
  }
);

export default updateUserSchool;
