import {
  DocumentScannerOutlined,
  RocketLaunchOutlined,
  RocketLaunchTwoTone,
  SportsScoreOutlined,
  SportsScoreTwoTone,
  TrendingUpTwoTone,
  VideocamOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Drawer,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { addWeeks, format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonWithDialogExplanation from "src/components/Button/ButtonWithDialogExplanation";
import { CardSkeleton } from "src/components/Skeleton/closet";
import { Header20, Header32 } from "src/components/Typography";
import { UserAccount } from "src/types/User";
import { CLP_ID } from "src/utils/constants";
import getRandomThumbnailImage from "src/utils/getRandomThumbnailImage";
import useCurrentTheoryTrainingModule from "src/utils/useCurrentTheoryTrainingModule";
import { trackEvent } from "../OnboardingScreen/amplitude";
import StudyPlanWeekCardList from "../OnboardingScreen/StudyPlanWeekCardList";
import ExamsSection from "./ExamsSection";

type Props = {
  user: UserAccount;
};

export default function DashboardTrainingModuleCard({ user }: Props) {
  const { t } = useTranslation();

  const [viewPlan, setViewPlan] = useState(false);
  const navigate = useNavigate();

  const { currentModuleData, loading } = useCurrentTheoryTrainingModule(user);

  if (!user.theoryTrainingPlan) return <div />;
  if (loading) return <CardSkeleton />;
  if (!currentModuleData) return <div />;

  const { currentModule, trainingStatus, overview, nextItemToDo } =
    currentModuleData;

  const handleClick = () => {
    trackEvent("Dashboard Screen - Clicked on Continue Course");

    // type narrowing
    if (!nextItemToDo) return;
    navigate(nextItemToDo.link);
  };

  const handleReviewClick = () => {
    trackEvent("Dashboard Screen - Clicked on Review Course");
    navigate(`courses/${CLP_ID}`);
  };

  const hasFinishedAllVideos =
    overview.totalVideosInModule === 0 ||
    (overview.completedVideosInModule === overview.totalVideosInModule &&
      overview.totalExamsInModule > 0);

  if (trainingStatus === "completed" || hasFinishedAllVideos)
    return (
      <Box>
        <Card variant="outlined">
          <CardHeader title={t("Exam Practice")} />
          <CardContent>
            <Stack gap={2}>
              <Alert
                action={
                  <Button
                    onClick={handleReviewClick}
                    color="inherit"
                    startIcon={<VideocamOutlined />}
                  >
                    {t("REVIEW")}
                  </Button>
                }
              >
                <AlertTitle>
                  {t("You’ve Watched ALL the Video Lessons")}
                </AlertTitle>
                {t("Time to focus on practicing for the exam.")}
              </Alert>

              <ExamsSection user={user} courseId={CLP_ID} />
              <ButtonWithDialogExplanation
                title={t("How is the proficiency score calculated?")}
                steps={[
                  {
                    type: "component",
                    icon: DocumentScannerOutlined,
                    title: t("What is this?"),
                    body: t(
                      "A proficiency score shows you how ready you are for the exam based on your mock exams results."
                    ),
                  },
                  {
                    type: "component",
                    icon: RocketLaunchTwoTone,
                    title: t("What is a good proficiency score?"),
                    body: t(
                      "A good score is 80% or higher. It means you're ready for the exam."
                    ),
                  },
                  {
                    type: "component",
                    icon: SportsScoreTwoTone,
                    title: t("Don't strive for perfection"),
                    body: t(
                      "An 80% score is enough to pass the exam. You don't need to aim for 100%."
                    ),
                  },
                  {
                    type: "component",
                    icon: TrendingUpTwoTone,
                    title: t("How can I improve it?"),
                    body: t(
                      "Keep practicing consistently, and take more exams to build confidence and boost your score."
                    ),
                  },
                ]}
              />
            </Stack>
          </CardContent>
        </Card>
      </Box>
    );

  // new UX experience where to start representing

  return (
    <Box>
      <Card
        sx={{
          cursor: "pointer",
          "&:hover": {
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <CardActionArea onClick={handleClick}>
          <Box display={{ md: "flex" }} justifyContent="space-between">
            <CardContent>
              {/* wrapper for all components */}
              <Stack spacing={2} flex={1}>
                {/* header */}
                <Stack spacing={1}>
                  <Box>
                    <Chip
                      size="small"
                      icon={
                        trainingStatus === "on_time" ? (
                          <SportsScoreOutlined />
                        ) : trainingStatus === "ahead_of_schedule" ? (
                          <RocketLaunchOutlined />
                        ) : (
                          <WarningOutlined />
                        )
                      }
                      label={
                        trainingStatus === "on_time" ||
                        trainingStatus === "ahead_of_schedule"
                          ? t("Due on {{ dueDate }} ", {
                              dueDate: user.theoryTrainingPlan.startDate
                                ? format(
                                    addWeeks(
                                      new Date(
                                        user.theoryTrainingPlan.startDate
                                      ),
                                      currentModule + 1
                                    ),
                                    "MMM d"
                                  )
                                : "this week",
                            })
                          : t("Overdue")
                      }
                      color={
                        trainingStatus === "on_time"
                          ? "info"
                          : trainingStatus === "ahead_of_schedule"
                          ? "success"
                          : "warning"
                      }
                      variant="outlined"
                    />
                  </Box>

                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Header32>
                      {t(`Week {{ moduleNumber }}`, {
                        moduleNumber: currentModule + 1,
                      })}
                    </Header32>
                    <Header20>
                      {t("out of {{ week }}", {
                        week: user.theoryTrainingPlan.totalWeeks,
                      })}
                    </Header20>
                  </Stack>
                </Stack>
                {/* progress */}
                <Stack spacing={0.5}>
                  <LinearProgress
                    variant="determinate"
                    value={
                      ((overview.completedVideosInModule +
                        overview.completedExamsInModule) /
                        (overview.totalExamsInModule +
                          overview.totalVideosInModule)) *
                      100
                    }
                  />
                  {/* module videos */}
                  <Box>
                    {overview.totalVideosInModule > 0 && (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <VideocamOutlined />
                        <Typography variant="body2" color="text.secondary">
                          {overview.completedVideosInModule}/
                          {overview.totalVideosInModule} {t("lessons")}
                        </Typography>
                      </Stack>
                    )}

                    {overview.totalExamsInModule > 0 && (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <DocumentScannerOutlined />
                        <Typography variant="body2" color="text.secondary">
                          {overview.completedExamsInModule}/
                          {overview.totalExamsInModule} {t("exams")}
                        </Typography>
                      </Stack>
                    )}
                  </Box>
                </Stack>
              </Stack>

              {nextItemToDo && nextItemToDo.resource && (
                <Stack marginTop={4}>
                  <Typography variant="body2" color="text.secondary">
                    {t("Next Up:")}
                  </Typography>
                  <Header20>{nextItemToDo.resource?.name}</Header20>
                </Stack>
              )}
            </CardContent>
            <CardMedia
              component="img"
              image={getRandomThumbnailImage(currentModule)}
              sx={{
                maxWidth: { xs: "100%", md: 250 }, // Shrink to a max width depending on the device
                height: { xs: 100, md: "auto" }, // Adjust the height based on the device
                objectFit: "cover",
              }}
              alt="Illustration of a week module"
            />
          </Box>
        </CardActionArea>
        <CardActions sx={{ marginTop: 2 }}>
          <Button variant="contained" onClick={handleClick}>
            {t("Continue")}
          </Button>
          <Button onClick={() => setViewPlan(true)}>{t("Review Plan")}</Button>
        </CardActions>
      </Card>
      {/* If late */}
      <Drawer
        open={viewPlan}
        anchor="bottom"
        onClose={() => setViewPlan(false)}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 800, // Default for small screens
            height: "75vh",
            maxHeight: "75vh",
            overflowY: "auto",
            marginY: 4,
            marginX: "auto",
          }}
        >
          <StudyPlanWeekCardList
            studyPlanByWeek={user.theoryTrainingPlan.weeklyPlan}
          />
        </Box>
      </Drawer>
    </Box>
  );
}
