import { useEffect, useState } from "react";
import { trackEvent } from "src/pages/OnboardingScreen/amplitude";

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export default function useAddToHomescreenPrompt(): [
  IBeforeInstallPromptEvent | null,
  () => Promise<void>
] {
  const [prompt, setState] = useState<IBeforeInstallPromptEvent | null>(null);

  const promptToInstall = () => {
    if (prompt) {
      // Trigger the installation prompt
      prompt.prompt();
      // Check the user's choice and log the outcome for tracking
      prompt.userChoice.then((choice) => {
        trackEvent("App Installation - Clicked on Installation Choice", {
          outcome: choice.outcome,
        });
      });
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  };

  useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      setState(e);
    };

    window.addEventListener(
      "beforeinstallprompt",
      ready as EventListenerOrEventListenerObject
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        ready as EventListenerOrEventListenerObject
      );
    };
  }, []);

  // Listen for the PWA installation event
  useEffect(() => {
    const handleAppInstalled = () => {
      trackEvent("App Installation - Success");
    };

    window.addEventListener("appinstalled", handleAppInstalled);

    return () => {
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  }, []);

  return [prompt, promptToInstall];
}
