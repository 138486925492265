import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReplayIcon from "@mui/icons-material/Replay";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { Box, IconButton, Slider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Header20 } from "./Typography";

type AudioControlsProps = {
  audioRef: React.RefObject<HTMLAudioElement>;
  trackTitle: string;
};

const AudioControls: React.FC<AudioControlsProps> = ({
  audioRef,
  trackTitle,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);

  // Update duration when metadata loads
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;
    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
    };
    audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    // eslint-disable-next-line consistent-return
    return () =>
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
  }, [audioRef]);

  // Update progress as audio plays
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;
    const handleTimeUpdate = () => {
      setProgress(audio.currentTime);
    };
    audio.addEventListener("timeupdate", handleTimeUpdate);
    // eslint-disable-next-line consistent-return
    return () => audio.removeEventListener("timeupdate", handleTimeUpdate);
  }, [audioRef]);

  const togglePlay = () => {
    const audio = audioRef.current;
    if (!audio) return;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgressChange = (event: Event, newValue: number | number[]) => {
    const audio = audioRef.current;
    if (!audio) return;
    const newTime = Array.isArray(newValue) ? newValue[0] : newValue;
    audio.currentTime = newTime;
    setProgress(newTime);
  };

  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    const audio = audioRef.current;
    if (!audio) return;
    const newVolume = Array.isArray(newValue) ? newValue[0] : newValue;
    audio.volume = newVolume;
    setVolume(newVolume);
  };

  return (
    <Box>
      {/* TrackInfo */}
      <Box mb={1}>
        <Header20>{trackTitle}</Header20>
      </Box>

      {/* ProgressBar */}
      <Box>
        <Slider
          aria-label="progress"
          value={progress}
          min={0}
          max={duration || 100}
          onChange={handleProgressChange}
        />
        <Box display="flex" justifyContent="space-between">
          <Typography variant="caption">
            {new Date(progress * 1000).toISOString().substr(14, 5)}
          </Typography>
          <Typography variant="caption">
            {new Date(duration * 1000).toISOString().substr(14, 5)}
          </Typography>
        </Box>
      </Box>

      {/* Controls and Volume */}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <IconButton
            onClick={() => {
              const audio = audioRef.current;
              if (audio) {
                audio.currentTime = Math.max(0, audio.currentTime - 15);
              }
            }}
          >
            <ReplayIcon />
          </IconButton>
          <IconButton onClick={togglePlay}>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <IconButton
            onClick={() => {
              const audio = audioRef.current;
              if (audio) {
                audio.currentTime += 15;
              }
            }}
          >
            <SkipNextIcon />
          </IconButton>
        </Box>
        <Box width={150}>
          <Typography variant="caption">Volume</Typography>
          <Slider
            aria-label="volume"
            value={volume}
            min={0}
            max={1}
            step={0.01}
            onChange={handleVolumeChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AudioControls;
