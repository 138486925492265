import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchBackground from "src/firebase/fetchBackgroundProfile";
import { BackgroundFormData } from "src/pages/BackgroundSurveyScreen/BackgroundSurveyView";
import { UserAccount } from "src/types/User";
import isApplicationEligible from "./isApplicationEligible";
import retryWithBackoff from "./retryWithBackoff";
import useErrorHandler from "./useErrorHandler";

const useBackgroundProfile = (user: UserAccount) => {
  const [background, setBackground] = useState<BackgroundFormData>();
  const [loading, setLoading] = useState(true);
  const [applicationScore, setApplicationScore] = useState<
    number | null | undefined
  >();
  const [hasEligibleApplicationScore, setHasEligibleApplicationScore] =
    useState<boolean | undefined>();

  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();

  const loadBackground = useCallback(() => {
    setLoading(true);
    setBackground(undefined);
    setApplicationScore(undefined);
    setHasEligibleApplicationScore(undefined);

    retryWithBackoff(() => fetchBackground(user.uid, clients), 3, 1000)
      .then((backgroundData) => {
        setBackground(backgroundData);

        // Calculate application score after background fetch
        if (backgroundData) {
          const isEligible = isApplicationEligible(backgroundData);
          setHasEligibleApplicationScore(isEligible);
        } else {
          setApplicationScore(null);
          setHasEligibleApplicationScore(false);
        }
      })
      .catch(errorHandler)
      .finally(() => setLoading(false));
  }, [clients, user.uid, errorHandler]);

  useEffect(() => {
    loadBackground();
  }, [loadBackground]);

  const hasBeenIncarcerated = !!(
    background?.hasBeenIncarcerated || background?.isIncarcerated
  );

  const isJusticeInvolved: boolean = !!(
    hasBeenIncarcerated ||
    background?.isProbationOrParole ||
    background?.hasBeenProbationOrParole ||
    background?.hasDoneDiversionProgram
  );

  return {
    background,
    loading,
    refetch: loadBackground,
    setBackground,
    applicationScore,
    hasEligibleApplicationScore,
    isUnderSupervision: background?.isProbationOrParole,
    hasBeenIncarcerated,
    isJusticeInvolved,
    isJusticeImpacted: !!(isJusticeInvolved || background?.isJusticeImpacted),
  };
};

export default useBackgroundProfile;
