import { getBlob, ref } from "firebase/storage";
import { Clients } from "src/contexts/AppContext";

export default async function fetchStorageBlob(
  { path }: { path: string },
  { storage }: Clients
): Promise<File> {
  const storageRef = ref(storage, path);
  const blob = await getBlob(ref(storage, path));
  const file = new File([blob], storageRef.fullPath, { type: blob.type });

  return file;
}
