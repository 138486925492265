import {
  AirSharp,
  ArrowDownward,
  ArrowUpward,
  BookTwoTone,
  DriveEtaTwoTone,
  LibraryBooksOutlined,
  RecordVoiceOverOutlined,
  RepeatOutlined,
  ShieldTwoTone,
  ViewSidebarTwoTone,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import VideoAskActionItemCard from "src/components/Card/VideoAskActionItemCard";
import VideoAskSchema from "src/pages/OnboardingScreen/videoAskIds.enum";
import { UserAccount } from "src/types/User";

type Props = {
  user: UserAccount;
};

export default function PracticeMaterialsSection({ user }: Props) {
  const [showPracticeExplanation, setShowPracticeExplanation] = useState(false);

  return (
    <Card variant="outlined">
      <CardHeader title={t("Practice")} />
      <CardContent>
        <Typography>
          {t(
            "Most people who don’t pass the CDL test stumble on the air brakes or pre-trip sections. The secret to passing? Repeated practice."
          )}
        </Typography>

        <Button
          onClick={() => setShowPracticeExplanation((prev) => !prev)}
          color="primary"
          startIcon={
            showPracticeExplanation ? <ArrowUpward /> : <ArrowDownward />
          }
          sx={{ mt: 2 }}
        >
          {showPracticeExplanation ? t("Show Less") : t("How to Use This")}
        </Button>

        <Collapse in={showPracticeExplanation}>
          <List>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    <RecordVoiceOverOutlined
                      sx={{ verticalAlign: "middle", marginRight: 1 }}
                    />
                    {t("Record Yourself")}
                  </>
                }
                secondary={t(
                  "Practice by recording yourself just like you would on the real exam. Don't worry about perfection - mistakes are part of learning"
                )}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <>
                    <RepeatOutlined
                      sx={{ verticalAlign: "middle", marginRight: 1 }}
                    />
                    {t("Flexible, Unlimited Practice")}
                  </>
                }
                secondary={t(
                  "Practice fits your schedule. Whether it’s a few minutes or a full session, come back as many times as you need."
                )}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <>
                    <LibraryBooksOutlined
                      sx={{ verticalAlign: "middle", marginRight: 1 }}
                    />
                    {t("Review and Repeat")}
                  </>
                }
                secondary={t(
                  "We’ll send you feedback by text so you know how you did. Repetition is the key to mastering the material."
                )}
              />
            </ListItem>
          </List>
        </Collapse>

        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={6}>
            <VideoAskActionItemCard
              title={VideoAskSchema.PRE_TRIP1.label}
              description={t(
                "We’re here to help you master your pre-trip inspection with a step-by-step, interactive guide. Let’s build your confidence before you hit the road!"
              )}
              item={VideoAskSchema.PRE_TRIP1}
              icon={ViewSidebarTwoTone}
              user={user}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <VideoAskActionItemCard
              title={VideoAskSchema.PRE_TRIP2.label}
              description={t(
                "The pre-trip for the front of the vehicle checks critical components such as headlights, the bumper, and the engine compartment to ensure safe operation."
              )}
              item={VideoAskSchema.PRE_TRIP2}
              icon={DriveEtaTwoTone}
              user={user}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <VideoAskActionItemCard
              title={VideoAskSchema.PRE_TRIP3.label}
              description={t(
                "The side of the vehicle supports essential components and provides lateral protection."
              )}
              item={VideoAskSchema.PRE_TRIP3}
              icon={BookTwoTone}
              user={user}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <VideoAskActionItemCard
              title={VideoAskSchema.PRE_TRIP4.label}
              description={t(
                "The rear cover shields the cargo area from weather, debris, and potential damage."
              )}
              item={VideoAskSchema.PRE_TRIP4}
              icon={ShieldTwoTone}
              user={user}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <VideoAskActionItemCard
              title={t("Air Brakes Practice")}
              description={t(
                "Use this interactive guide to practice for the air brakes section of your CDL test. We'll email you feedback after you finish."
              )}
              item={VideoAskSchema.AIR_BRAKES}
              icon={AirSharp}
              user={user}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
