// src/utils/getDistanceBetweenZips.ts
const ORS_API_KEY = process.env.REACT_APP_ORS_API_KEY; // Replace with your API key
const BASE_URL = "https://api.openrouteservice.org/v2/matrix/driving-car";

/**
 * Get latitude/longitude from a ZIP code using OpenStreetMap's Nominatim API.
 */
export async function getCoordinatesFromZip(
  zip: string
): Promise<{ lat: number; lon: number } | null> {
  const url = `https://nominatim.openstreetmap.org/search?format=json&q=${zip},USA`;

  const response = await fetch(url);
  const data = await response.json();

  if (data.length === 0) return null;
  return { lon: parseFloat(data[0].lon), lat: parseFloat(data[0].lat) };
}

/**
 * Get the driving distance (in MILES) between two ZIP codes.
 * Uses OpenRouteService API to calculate real distances.
 */
export default async function getDistanceBetweenZips(
  startZip: string,
  endZip: string
) {
  // Convert ZIP codes to latitude/longitude using OpenStreetMap Nominatim API
  const [startCoords, endCoords] = await Promise.all([
    getCoordinatesFromZip(startZip),
    getCoordinatesFromZip(endZip),
  ]);

  if (!ORS_API_KEY) {
    throw new Error("OpenRouteService API key is missing.");
  }
  if (!startCoords || !endCoords) {
    throw new Error(
      "Failed to retrieve coordinates for one or both ZIP codes."
    );
  }

  // Request distance from ORS API
  const response = await fetch(BASE_URL, {
    method: "POST",
    body: JSON.stringify({
      locations: [
        [startCoords.lon, startCoords.lat],
        [endCoords.lon, endCoords.lat],
      ],
      metrics: ["distance"],
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: ORS_API_KEY,
    },
  });

  const data = await response.json();
  if (!data.distances || data.distances.length === 0) {
    throw new Error("No distance data received.");
  }

  const distanceKm = data.distances[0][1] / 1000; // Convert meters to kilometers
  return { distanceInMiles: distanceKm * 0.621371, startCoords, endCoords }; // Convert to miles
}
