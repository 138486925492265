import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import SelectInput from "src/components/Card/SelectInput";
import NumberInput from "src/components/Input/NumberInput";
import TextInput from "src/components/Input/TextInput";
import YesNoToggleInput from "src/components/Input/YesNoToggleInput";
import USState from "src/types/states";
import EducationalAttainment from "src/utils/EducationalAttainment";
import isBoolean from "src/utils/isBoolean";
import isValidZipCode from "src/utils/isValidZipcode";
import MaritalStatus from "src/utils/MaritalStatus.enum";
import LABEL_MAP from "../ClientScreen/BackgroundTab/OnboardingSurveyLabels";
import DriverLicenseInactivityReason from "./DriverLicenseInactivityReason.enum";

export type IntroDemographicData = {
  hasProficientEnglish: boolean;
  education?: keyof typeof EducationalAttainment;
  maritalStatus: keyof typeof MaritalStatus;
  zipcode: string;
  hasDriverLicense: boolean;
  hasEverHadDriverLicense?: boolean;
  driverLicenseState?: USState;
  driverLicenseReinstatementCost?: number;
  driverLicenseInactiveReason?: keyof typeof DriverLicenseInactivityReason;
  hasToTakeMandatoryCourseToReinstateLicense?: boolean;
  hasMajorTrafficViolationOverPast3Years?: boolean;
};

type Props = {
  defaultValues: Partial<IntroDemographicData>;
  onSubmit: (data: IntroDemographicData) => void | Promise<void>;
};

export default function IntroDemographicForm({
  defaultValues,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<IntroDemographicData>({
    mode: "onTouched",
    defaultValues,
  });

  const noActiveDriverLicense = watch("hasDriverLicense") === false;
  const needsToReinstateLicense =
    noActiveDriverLicense && watch("hasEverHadDriverLicense") === true;

  const hasHadLicense =
    watch("hasDriverLicense") === true ||
    watch("hasEverHadDriverLicense") === true;

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Stack spacing={4} marginBottom={6}>
        <SelectInput
          name="education"
          prompt={LABEL_MAP.education}
          control={control}
          items={Object.entries(EducationalAttainment).map((entry) => ({
            value: entry[0],
            name: entry[1],
          }))}
          rules={{
            required: {
              value: true,
              message: t("Educational level information is required"),
            },
          }}
        />
        <SelectInput
          name="maritalStatus"
          prompt={LABEL_MAP.maritalStatus}
          control={control}
          items={Object.entries(MaritalStatus).map((entry) => ({
            value: entry[0],
            name: entry[1],
          }))}
          rules={{
            required: { value: true, message: t("This field is required") },
          }}
        />
        <TextInput
          name="zipcode"
          prompt={LABEL_MAP.zipcode}
          control={control}
          rules={{
            required: true,
            validate: (value) => isValidZipCode(value) || t("Invalid ZIP code"),
          }}
        />
        <YesNoToggleInput
          name="hasDriverLicense"
          prompt={LABEL_MAP.hasDriverLicense}
          control={control}
          rules={{
            validate: (value) =>
              isBoolean(value) || t("This field is required"),
          }}
        />

        {noActiveDriverLicense && (
          <YesNoToggleInput
            name="hasEverHadDriverLicense"
            prompt={LABEL_MAP.hasEverHadDriverLicense}
            control={control}
            rules={{
              validate: (value) =>
                isBoolean(value) || t("This field is required"),
            }}
          />
        )}

        {hasHadLicense && (
          <>
            <SelectInput
              name="driverLicenseState"
              prompt={LABEL_MAP.driverLicenseState}
              control={control}
              items={Object.entries(USState).map((entry) => ({
                value: entry[0],
                name: entry[1],
              }))}
              rules={{
                required: hasHadLicense,
                validate: (value) => !!value || t("The state is required"),
              }}
            />
            <YesNoToggleInput
              name="hasMajorTrafficViolationOverPast3Years"
              prompt={LABEL_MAP.hasMajorTrafficViolationOverPast3Years}
              helperText="e.g DUIs, fleeing law enforcement, reckless driving, hit and run, driving with a suspended license, etc."
              control={control}
              rules={{
                validate: (value) =>
                  isBoolean(value) || t("This field is required"),
              }}
            />
          </>
        )}
        {needsToReinstateLicense && (
          <>
            <SelectInput
              name="driverLicenseInactiveReason"
              prompt={LABEL_MAP.driverLicenseInactiveReason}
              control={control}
              items={Object.entries(DriverLicenseInactivityReason).map(
                (entry) => ({
                  value: entry[0],
                  name: entry[1],
                })
              )}
              rules={{
                required: needsToReinstateLicense,
              }}
            />
            <YesNoToggleInput
              name="hasToTakeMandatoryCourseToReinstateLicense"
              prompt={LABEL_MAP.hasToTakeMandatoryCourseToReinstateLicense}
              control={control}
            />
            <NumberInput
              name="driverLicenseReinstatementCost"
              prompt={LABEL_MAP.driverLicenseReinstatementCost}
              control={control}
              rules={{
                min: 0,
              }}
              helperText="Enter '0' if unknown or not applicable."
            />
          </>
        )}
      </Stack>
      <SubmitButton loading={isSubmitting}>{t("Next")}</SubmitButton>
    </form>
  );
}
