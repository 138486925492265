// src/hooks/useReferrals.ts
import { useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchReferralsByPartner from "src/firebase/fetchReferralsByPartner";
import { UserSummary } from "src/types/UserSummary";
import useErrorHandler from "src/utils/useErrorHandler";

export default function usePartnerReferrals(partnerId: string): {
  referrals: UserSummary[];
  loading: boolean;
} {
  const [referrals, setReferrals] = useState<UserSummary[]>([]);
  const [loading, setLoading] = useState(true);
  const handleError = useErrorHandler();
  const { clients } = useAppContext();

  useEffect(() => {
    fetchReferralsByPartner(partnerId, clients)
      .then(setReferrals)
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [partnerId, clients, handleError]);

  return { referrals, loading };
}
