import {
  CalendarMonthOutlined,
  CheckCircleOutline,
  FlagOutlined,
  InfoOutlined,
  RocketOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Header20 } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import fetchAdmin from "src/firebase/fetchAdmin";
import { trackEvent } from "src/pages/OnboardingScreen/amplitude";
import { useSnackbarContext } from "src/SnackbarProvider";
import { Admin } from "src/types/Admin";
import MilestoneType from "src/types/MilestoneType.enum";
import { UserAccount } from "src/types/User";
import useErrorHandler from "src/utils/useErrorHandler";
import SingleMilestoneForm from "./SingleMilestoneForm";
import {
  REQUIRED_MILESTONES,
  StudentPlanMilestoneFormData,
} from "./StudentPlanMilestoneForm";

type Props = {
  user: UserAccount;
  milestoneType: MilestoneType;
  onCreate: (data: StudentPlanMilestoneFormData) => Promise<void>;
};

export default function MilestoneScheduleCard({
  user,
  milestoneType,
  onCreate,
}: Props) {
  const { t } = useTranslation();
  const [isCreateMilestoneOPen, setIsCreateMilestoneOpen] = useState(false);
  const [isProofDialogOpen, setIsProofDialogOpen] = useState(false); // New state for proof dialog
  const template = REQUIRED_MILESTONES.find((m) => m.type === milestoneType);
  const errorHandler = useErrorHandler();
  const snackbarContext = useSnackbarContext();
  const cta = t("Get Started");
  const { clients } = useAppContext();
  const [admin, setAdmin] = useState<Admin>();

  const handleOpen = () => {
    trackEvent("Milestone Schedule Card - Clicked on Get Started", {
      call_to_action: cta,
      milestone_type: milestoneType,
    });
    setIsCreateMilestoneOpen(true);
  };

  const handleCreate = async (data: StudentPlanMilestoneFormData) => {
    try {
      trackEvent("Milestone Schedule Card - Created Milestone", {
        milestone_type: milestoneType,
        date: data.date.toISOString(),
      });
      await onCreate(data);
      snackbarContext.triggerConfetti();
      snackbarContext.alert(
        "success",
        t("One step scheduled, one step closer to your goal!")
      );
      setIsCreateMilestoneOpen(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (!user.emergeCoachId) return;
    if (!isProofDialogOpen) return;

    // only load this data if the user has a coach and the dialog is open
    fetchAdmin(user.emergeCoachId, clients).then(setAdmin).catch(errorHandler);
  }, [user.emergeCoachId, clients, errorHandler, isProofDialogOpen]);

  if (!template) return <div />;

  const handleLearnMore = () => {
    if (!template.createInstructions) return;
    trackEvent("Milestone Schedule Card - Clicked on Learn More", {
      milestone_type: milestoneType,
    });
    window.open(template.createInstructions.blogLink, "_blank");
  };

  const handleAlreadyCompleted = () => {
    trackEvent("Milestone Schedule Card - Clicked on Already Completed", {
      milestone_type: milestoneType,
    });
    // Modified function
    setIsProofDialogOpen(true);
  };

  return (
    <Card>
      <CardActionArea onClick={() => setIsCreateMilestoneOpen(true)}>
        <CardContent>
          <Chip variant="outlined" color="info" label={t("Milestone")} />
          <Header20 marginTop={1}>
            {template.createInstructions?.title || template.name}
          </Header20>
          {template.createInstructions && (
            <List disablePadding>
              {template.type === "medical_exam" && (
                <>
                  <ListItemText
                    primary={
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <CheckCircleOutline
                          fontSize="small"
                          style={{ marginRight: 4 }}
                        />
                        <strong>{t("NO cost")}</strong>
                      </span>
                    }
                    secondary={t(
                      "Scheduling is free and after your appointment, we’ll reimburse you."
                    )}
                  />
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <CheckCircleOutline
                            fontSize="small"
                            style={{ marginRight: 4 }}
                          />
                          <strong>{t("You’re Ready")}</strong>
                        </span>
                      }
                      secondary={t(
                        "No studying required. The physical is just a quick check-up, nothing else."
                      )}
                    />
                  </ListItem>
                </>
              )}

              {template.type === "theory_test" && (
                <>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <CalendarMonthOutlined
                            fontSize="small"
                            style={{ marginRight: 4 }}
                          />
                          <strong>{t("Make Your Commitment REAL")}</strong>
                        </span>
                      }
                      secondary={t(
                        "Schedule 1-2 weeks ahead and keep practicing for the big day."
                      )}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <FlagOutlined
                            fontSize="small"
                            style={{ marginRight: 4 }}
                          />
                          <strong>{t("Don’t shoot for perfection")}</strong>
                        </span>
                      }
                      secondary={t(
                        "Most Emerge students need more than one try—and that’s totally normal."
                      )}
                    />
                  </ListItem>
                </>
              )}
            </List>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Stack spacing={2}>
          <Box display="flex" alignItems="center" gap={2}>
            <Button
              variant="contained"
              onClick={handleOpen}
              startIcon={<RocketOutlined />}
            >
              {cta}
            </Button>
            {!!template.createInstructions && (
              <Button
                startIcon={<InfoOutlined />}
                variant="outlined"
                onClick={handleLearnMore}
              >
                {t("Learn More")}
              </Button>
            )}
          </Box>
          {user.emergeCoachId && (
            <Box display="flex" flexDirection="column" marginTop={2}>
              <Typography variant="body2">
                {t("Already completed this?")}
              </Typography>
              <Box>
                <Button
                  onClick={handleAlreadyCompleted}
                  sx={{ paddingLeft: 0 }}
                >
                  {t("Let your coach know")}
                </Button>
              </Box>
            </Box>
          )}
        </Stack>
      </CardActions>
      <Dialog
        open={isCreateMilestoneOPen}
        onClose={() => setIsCreateMilestoneOpen(false)}
        fullScreen={isMobileOnly}
      >
        <DialogContent>
          <SingleMilestoneForm
            user={user}
            milestoneType={milestoneType}
            formType="create"
            onSubmit={handleCreate}
          />
        </DialogContent>
      </Dialog>
      <Dialog // New dialog for proof of completion
        open={!!admin && isProofDialogOpen}
        onClose={() => setIsProofDialogOpen(false)}
        fullWidth
      >
        {admin && (
          <DialogContent>
            <Stack spacing={4}>
              <Header20>
                {t("Text a photo of your {{ proofItem }} to your coach", {
                  firstName: admin?.firstName,
                  proofItem:
                    template.type === MilestoneType.MEDICAL_EXAM
                      ? "DOT Physical"
                      : template.type === MilestoneType.THEORY_EXAM
                      ? "Learner's Permit"
                      : "milestone",
                })}
              </Header20>
              <TextField
                fullWidth
                variant="outlined"
                label={t(`{{ coach }}'s Number`, { coach: admin.firstName })}
                value={admin!.phone}
                InputProps={{ readOnly: true }}
                margin="normal"
              />
              {template.type === MilestoneType.MEDICAL_EXAM ? (
                <Typography variant="body1" gutterBottom>
                  {t(
                    "Make sure your physical hasn’t expired, AND double-check that the CDL/CLP Applicant box is marked."
                  )}
                </Typography>
              ) : (
                <Typography variant="body1" gutterBottom>
                  {t("{{ coachName }} will help you keep moving.", {
                    coachName: admin.firstName,
                  })}
                </Typography>
              )}

              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  onClick={() => setIsProofDialogOpen(false)}
                >
                  {t("Done")}
                </Button>
              </Box>
            </Stack>
          </DialogContent>
        )}
      </Dialog>
    </Card>
  );
}
