import { doc, updateDoc } from "firebase/firestore";
import Joi from "joi";
import { UserAccount, UserSkillsTrainingPlan } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";

type Data = {
  user: UserAccount;
  skillsTrainingPlan: UserSkillsTrainingPlan;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  skillsTrainingPlan: Joi.object().required(),
});

const updateUserSkillsTrainingPlan = serviceFunction(
  schema,
  async ({ user, skillsTrainingPlan }, { db }): Promise<void> => {
    await updateDoc(doc(db, `users/${user.uid}`), {
      skillsTrainingPlan,
    });
  }
);

export default updateUserSkillsTrainingPlan;
